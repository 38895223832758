import { NgModule } from '@angular/core';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolButtonComponent } from './components/asol-button.component';
import { AsolDropDownButtonComponent } from './components/asol-dropdown-button.component';
import { AsolIconButtonComponent } from './components/asol-icon-button.component';
import { AsolSplitButtonComponent } from './components/asol-split-button.component';

@NgModule({
  declarations: [
    AsolButtonComponent,
    AsolIconButtonComponent,
    AsolDropDownButtonComponent,
    AsolSplitButtonComponent,
  ],
  exports: [
    AsolButtonComponent,
    AsolIconButtonComponent,
    AsolDropDownButtonComponent,
    AsolSplitButtonComponent,
  ],
  imports: [SharedModule, DropDownButtonModule],
  providers: [],
})
export class AsolButtonModule {}

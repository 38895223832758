import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { GENERAL_TRANSLATION, TranslationService } from '@asol/core';
import {
  ItemModel,
  MenuEventArgs,
  OpenCloseMenuEventArgs,
} from '@syncfusion/ej2-angular-splitbuttons';
import { ICONS } from '../../../../shared/constants/icon.constant';
import { Color } from '../../../../shared/types/color.type';
import { ButtonTextType } from '../models/button-text.type';
import { ButtonType } from '../models/button.type';

@Component({
  selector: 'asol-split-button',
  templateUrl: './asol-split-button.component.html',
})
export class AsolSplitButtonComponent {
  ICONS = ICONS;
  GENERAL_TRANSLATION = GENERAL_TRANSLATION;

  @Input() type: ButtonType = 'default';
  @Input() textType: ButtonTextType | null = null;
  @Input() color: Color = 'primary';
  @Input() disabled = false;
  @Input() iconSvgUrl = '';
  @Input() chevronSvgUrl = ICONS.CHEVRON_DOWN;
  @Input() items: ItemModel[] = [];
  @Input() showOutline = false;
  @Input() cssClass = '';

  @Output()
  primaryClick = new EventEmitter<void>();

  @Output()
  select = new EventEmitter<MenuEventArgs>();

  @ViewChild('primaryButton')
  primaryButton!: ElementRef;

  constructor(public trans: TranslationService) {}

  onSelect(event: MenuEventArgs) {
    this.select.emit(event);
  }

  onPrimaryClick() {
    this.primaryClick.emit();
  }

  /**
   * Calculates new position of popup menu
   * @param args Menu args
   */
  public onOpen(args: OpenCloseMenuEventArgs) {
    args.element.parentElement.style.left =
      args.element.parentElement.getBoundingClientRect().left -
      args.element.parentElement.offsetWidth +
      34 +
      'px';
  }
}

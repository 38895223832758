import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolLabelFieldModule } from '../label-field/asol-label-field.module';
import { AsolInputFieldComponent } from './components/input-field.component';

@NgModule({
  declarations: [AsolInputFieldComponent],
  exports: [AsolInputFieldComponent],
  imports: [SharedModule, AsolLabelFieldModule],
})
export class AsolInputFieldModule {}

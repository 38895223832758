import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinObservable = new BehaviorSubject<boolean>(false);
  public spin$ = this.spinObservable.asObservable();

  /**
   * Counter of total active spinners
   *
   * @private
   * @type {number}
   */
  private totalSpinners = 0;

  /**
   * Invoke spinner to show
   *
   * @public
   */
  public show(): void {
    if (this.totalSpinners === 0) {
      this.spinObservable.next(true);
    }
    this.totalSpinners++;
  }

  /**
   * Invoke spinner to hide
   *
   * @public
   */
  public hide(): void {
    // if method resetAndHide() was called this will prevend to decrement totalSpinners into negative numbers
    if (this.totalSpinners > 0) {
      this.totalSpinners--;
    }
    if (this.totalSpinners === 0) {
      this.spinObservable.next(false);
    }
  }

  /**
   * Reset total number of active spinners to zero and invoke to hide spinner
   *
   * @public
   */
  public resetAndHide(): void {
    this.totalSpinners = 0;
    this.spinObservable.next(false);
  }

  /**
   * Get total number of active spinners
   *
   * @public
   * @returns {number}
   */
  public getTotalActiveSpinners(): number {
    return this.totalSpinners;
  }
}

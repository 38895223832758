import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessagePanelService } from '../../modules/controls/message-panel/services/message-panel.service';
import { CheckResponse } from '../models/check-response.interface';

/**
 * Service that checks if all required fields are filled
 */
@Injectable({
  providedIn: 'root',
})
export class RequiredCheckService {
  /** Observable that check is required, mostly used in components */
  private requireObservable = new Subject<string>();
  public requireCheck$ = this.requireObservable.asObservable();

  /** Observable that emits result of check in one component */
  private responseObservable = new Subject<CheckResponse>();
  public response$ = this.responseObservable.asObservable();

  /** Observable that emits result of check in components */
  private resultObservable = new Subject<boolean>();
  public result$ = this.resultObservable.asObservable();

  /** Unique sesstion identifier */
  private sessionId: string | null = null;

  /** Collection of all registered components */
  private registeredComponents: string[] = [];

  /** Collection of all components that are valid */
  private validComponents: string[] = [];

  /** Translation key for message when forms are invalid */
  private transKey: string;
  /** Translation key for message when forms are invalid */
  private textKey: string;
  /**
   * Flag if result should be sent,
   * used when recheck is needed without sending result to listeners
   * @default true
   */
  private shouldSendResult = true;
  /**
   * Flag if at least once was check requested
   * @default false
   */
  private checkRequested = false;

  constructor(private messageService: MessagePanelService) {}

  /**
   * Initializes service with translation keys for message panel text
   * @param transKey Module translation key
   * @param textKey Text translation key
   */
  public initialize(transKey: string, textKey: string): void {
    this.transKey = transKey;
    this.textKey = textKey;
  }

  /**
   * Emits to all registered components to check if all required fields are filled
   */
  public check(sendResult = true): void {
    if (sendResult && !this.checkRequested) {
      // if sendResult is true, then check was requested
      this.checkRequested = true;
    }

    // If no component requested for check, then no action is needed
    // this prevents from checking when form is not dirty
    if (!this.checkRequested) {
      return;
    }
    this.sessionId = new Date().getTime().toString();
    this.shouldSendResult = sendResult;
    this.validComponents = [];
    this.requireObservable.next(this.sessionId);
  }

  /**
   * Response from component if all required fields are filled
   * If all components have all required fields filled in emits true
   * If any component does not have all required fields filled in emits false
   * @param sessionId Session identifier
   * @param componentId Component identifier
   * @param response Response from component, true if all required fields are filled in
   */
  public response(
    sessionId: string,
    componentId: string,
    response: boolean
  ): void {
    this.responseObservable.next({
      componentId,
      response,
    });

    if (sessionId !== this.sessionId) {
      return;
    }

    // If any component does not have all required fields, then no action allowed
    if (!response) {
      this.resultObservable.next(false);
      if (this.transKey && this.textKey) {
        this.messageService.danger({
          id: 'required-fields',
          transKey: this.transKey,
          textKey: this.textKey,
          showCloseButton: true,
        });
      }
      this.sessionId = null;
    } else {
      this.validComponents.push(componentId);
    }

    // all component where checked and are valid
    if (this.registeredComponents.length === this.validComponents.length) {
      this.clearMessage();
      if (this.shouldSendResult) {
        this.resultObservable.next(true);
      }
    }
  }

  /**
   * Register component to service
   * @param componentId Component identifier
   */
  public register(componentId: string): void {
    if (this.registeredComponents.includes(componentId)) {
      return;
    }

    this.registeredComponents.push(componentId);
  }

  /**
   * Unregister component from service
   * @param componentId Component identifier
   */
  public unregister(componentId: string): void {
    const index = this.registeredComponents.indexOf(componentId);
    if (index > -1) {
      this.registeredComponents.splice(index, 1);
    }
  }

  /**
   * Clear all components
   */
  public clear(): void {
    this.registeredComponents = [];
    this.checkRequested = false;
    this.validComponents = [];
    this.sessionId = null;
    this.clearMessage();
  }

  /**
   * Clear message from message panel
   */
  private clearMessage(): void {
    this.messageService.remove({
      id: 'required-fields',
    });
  }
}

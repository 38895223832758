import { Component, Input } from '@angular/core';

@Component({
  selector: 'asol-app-background',
  templateUrl: './app-background.component.html',
})
export class AppBackgroundComponent {
  @Input()
  /** Color of the gradient */
  color: string | null = null;

  /** Unique id of the component for the fill ID */
  id = '';

  constructor() {
    this.id = Math.round(Math.random() * 100).toString();
  }
}

import { ASSETS_BASE } from './assets-base.constant';

export const ICONS = {
  CIRCLE_CLOSE: `${ASSETS_BASE.ICON_POPULAR}/circle-close.svg`,
  CIRCLE_INFO: `${ASSETS_BASE.ICON_POPULAR}/circle-info.svg`,
  COG_WHEEL: `${ASSETS_BASE.ICON_POPULAR}/cog-wheel.svg`,
  //PLUS: `${ASSETS_BASE.ICON_POPULAR}/plus.svg`,
  //CHECK: `${ASSETS_BASE.ICON_POPULAR}/check.svg`,
  CLOSE: `${ASSETS_BASE.ICON_POPULAR}/close.svg`,
  CLOSE_SMALL: `${ASSETS_BASE.ICON_POPULAR}/close-small.svg`,
  //CHEVRON_DOWN: `${ASSETS_BASE.ICON_CHEVRON}/chevron-down.svg`,
  //CHEVRON_RIGHT: `${ASSETS_BASE.ICON_CHEVRON}/chevron-right.svg`,
  //CHEVRON_LEFT: `${ASSETS_BASE.ICON_CHEVRON}/chevron-left.svg`,
  //CHEVRON_UP: `${ASSETS_BASE.ICON_CHEVRON}/chevron-up.svg`,
  // ARROW_UP: `${ASSETS_BASE.ICONS}/arrow_up.svg`,
  // ARROW_DOWN: `${ASSETS_BASE.ICONS}/arrow_down.svg`,
  ERROR: `${ASSETS_BASE.ICONS}/error.svg`,
  SEARCH: `${ASSETS_BASE.ICONS}/search.svg`,
  TRASH: `${ASSETS_BASE.ICON_POPULAR}/trash-01.svg`,
  EDIT: `${ASSETS_BASE.ICON_POPULAR}/edit.svg`,
  EDIT2: `${ASSETS_BASE.ICON_POPULAR}/edit2.svg`,
  //DIT_05: `${ASSETS_BASE.ICON_POPULAR}/edit-05.svg`,
  FILTER: `${ASSETS_BASE.ICON_POPULAR}/filter.svg`,
  CARD: `${ASSETS_BASE.ICONS}/card.svg`,
  DUPLICATE: `${ASSETS_BASE.ICONS}/duplicate.svg`,
  //FILE_SEARCH_02: `${ASSETS_BASE.ICON_POPULAR}/file-search-02.svg`,
  //GRID: `${ASSETS_BASE.ICONS}/grid.svg`,
  DANGER: `${ASSETS_BASE.ICONS}/danger.svg`,
  SUCCESS: `${ASSETS_BASE.ICONS}/success.svg`,
  WARNING: `${ASSETS_BASE.ICONS}/warning.svg`,
  INFO: `${ASSETS_BASE.ICONS}/info.svg`,
  //LINK_04: `${ASSETS_BASE.ICON_GENERAL}/link-04.svg`,
  //UPLOAD_01: `${ASSETS_BASE.ICON_GENERAL}/upload-01.svg`,
  //MARKER_PIN_04: `${ASSETS_BASE.ICON_GENERAL}/marker-pin-04.svg`,
  // REPEAT_04: `${ASSETS_BASE.ICON_GENERAL}/repeat-04.svg`,
  //FILE_PLUS_02: `${ASSETS_BASE.ICON_GENERAL}/file-plus-02.svg`,
  // CALENDAR: `${ASSETS_BASE.ICON_GENERAL}/calendar.svg`,
  MORE_VERTICAL: `${ASSETS_BASE.ICONS}/more-vertical.svg`,
  //REFRESH_CW_02: `${ASSETS_BASE.ICON_GENERAL}/refresh-cw-02.svg`,
  //COINS_STACKED_01: `${ASSETS_BASE.ICON_GENERAL}/coins-stacked-01.svg`,
  //EYE: `${ASSETS_BASE.ICON_POPULAR}/eye.svg`,
  DOWNLOAD: `${ASSETS_BASE.ICON_GENERAL}/download.svg`,
  XLS_UPLOAD: `${ASSETS_BASE.ICON_POPULAR}/xls-upload.svg`,
  XLS_UPLOAD_ERROR: `${ASSETS_BASE.ICON_POPULAR}/xls-upload-error.svg`,
  FILE_UPLOAD: `${ASSETS_BASE.ICON_POPULAR}/file-02.svg`,
  REMOVE_FILE: `${ASSETS_BASE.ICON_POPULAR}/remove-file.svg`,

  ACTIVITY_HEART: `${ASSETS_BASE.URL}/activity-heart.svg`,
  ACTIVITY: `${ASSETS_BASE.URL}/activity.svg`,
  AIRPLAY: `${ASSETS_BASE.URL}/airplay.svg`,
  AIRPODS: `${ASSETS_BASE.URL}/airpods.svg`,
  ALARM_CLOCK_CHECK: `${ASSETS_BASE.URL}/alarm-clock-check.svg`,
  ALARM_CLOCK_MINUS: `${ASSETS_BASE.URL}/alarm-clock-minus.svg`,
  ALARM_CLOCK_OFF: `${ASSETS_BASE.URL}/alarm-clock-off.svg`,
  ALARM_CLOCK_PLUS: `${ASSETS_BASE.URL}/alarm-clock-plus.svg`,
  ALARM_CLOCK: `${ASSETS_BASE.URL}/alarm-clock.svg`,
  ALERT_CIRCLE: `${ASSETS_BASE.URL}/alert-circle.svg`,
  ALERT_HEXAGON: `${ASSETS_BASE.URL}/alert-hexagon.svg`,
  ALERT_OCTAGON: `${ASSETS_BASE.URL}/alert-octagon.svg`,
  ALERT_SQUARE: `${ASSETS_BASE.URL}/alert-square.svg`,
  ALERT_TRIANGLE: `${ASSETS_BASE.URL}/alert-triangle.svg`,
  ALIGN_BOTTOM_01: `${ASSETS_BASE.URL}/align-bottom-01.svg`,
  ALIGN_BOTTOM_02: `${ASSETS_BASE.URL}/align-bottom-02.svg`,
  ALIGN_CENTER: `${ASSETS_BASE.URL}/align-center.svg`,
  ALIGN_HORIZONTAL_CENTRE_01: `${ASSETS_BASE.URL}/align-horizontal-centre-01.svg`,
  ALIGN_HORIZONTAL_CENTRE_02: `${ASSETS_BASE.URL}/align-horizontal-centre-02.svg`,
  ALIGN_JUSTIFY: `${ASSETS_BASE.URL}/align-justify.svg`,
  ALIGN_LEFT_01: `${ASSETS_BASE.URL}/align-left-01.svg`,
  ALIGN_LEFT_02: `${ASSETS_BASE.URL}/align-left-02.svg`,
  ALIGN_LEFT: `${ASSETS_BASE.URL}/align-left.svg`,
  ALIGN_RIGHT_01: `${ASSETS_BASE.URL}/align-right-01.svg`,
  ALIGN_RIGHT_02: `${ASSETS_BASE.URL}/align-right-02.svg`,
  ALIGN_RIGHT: `${ASSETS_BASE.URL}/align-right.svg`,
  ALIGN_TOP_ARROW_01: `${ASSETS_BASE.URL}/align-top-arrow-01.svg`,
  ALIGN_TOP_ARROW_02: `${ASSETS_BASE.URL}/align-top-arrow-02.svg`,
  ALIGN_VERTICAL_CENTER_01: `${ASSETS_BASE.URL}/align-vertical-center-01.svg`,
  ALIGN_VERTICAL_CENTER_02: `${ASSETS_BASE.URL}/align-vertical-center-02.svg`,
  ANCHOR: `${ASSETS_BASE.URL}/anchor.svg`,
  ANNOTATION_ALERT: `${ASSETS_BASE.URL}/annotation-alert.svg`,
  ANNOTATION_CHECK: `${ASSETS_BASE.URL}/annotation-check.svg`,
  ANNOTATION_DOTS: `${ASSETS_BASE.URL}/annotation-dots.svg`,
  ANNOTATION_HEART: `${ASSETS_BASE.URL}/annotation-heart.svg`,
  ANNOTATION_INFO: `${ASSETS_BASE.URL}/annotation-info.svg`,
  ANNOTATION_PLUS: `${ASSETS_BASE.URL}/annotation-plus.svg`,
  ANNOTATION_QUESTION: `${ASSETS_BASE.URL}/annotation-question.svg`,
  ANNOTATION_X: `${ASSETS_BASE.URL}/annotation-x.svg`,
  ANNOTATION: `${ASSETS_BASE.URL}/annotation.svg`,
  ANNOUNCEMENT_01: `${ASSETS_BASE.URL}/announcement-01.svg`,
  ANNOUNCEMENT_02: `${ASSETS_BASE.URL}/announcement-02.svg`,
  ANNOUNCEMENT_03: `${ASSETS_BASE.URL}/announcement-03.svg`,
  ARCHIVE: `${ASSETS_BASE.URL}/archive.svg`,
  ARROW_BLOCK_DOWN: `${ASSETS_BASE.URL}/arrow-block-down.svg`,
  ARROW_BLOCK_LEFT: `${ASSETS_BASE.URL}/arrow-block-left.svg`,
  ARROW_BLOCK_RIGHT: `${ASSETS_BASE.URL}/arrow-block-right.svg`,
  ARROW_BLOCK_UP: `${ASSETS_BASE.URL}/arrow-block-up.svg`,
  ARROW_CIRCLE_BROKEN_DOWN_LEFT: `${ASSETS_BASE.URL}/arrow-circle-broken-down-left.svg`,
  ARROW_CIRCLE_BROKEN_DOWN_RIGHT: `${ASSETS_BASE.URL}/arrow-circle-broken-down-right.svg`,
  ARROW_CIRCLE_BROKEN_DOWN: `${ASSETS_BASE.URL}/arrow-circle-broken-down.svg`,
  ARROW_CIRCLE_BROKEN_LEFT: `${ASSETS_BASE.URL}/arrow-circle-broken-left.svg`,
  ARROW_CIRCLE_BROKEN_RIGHT: `${ASSETS_BASE.URL}/arrow-circle-broken-right.svg`,
  ARROW_CIRCLE_BROKEN_UP_LEFT: `${ASSETS_BASE.URL}/arrow-circle-broken-up-left.svg`,
  ARROW_CIRCLE_BROKEN_UP_RIGHT: `${ASSETS_BASE.URL}/arrow-circle-broken-up-right.svg`,
  ARROW_CIRCLE_BROKEN_UP: `${ASSETS_BASE.URL}/arrow-circle-broken-up.svg`,
  ARROW_CIRCLE_DOWN_LEFT: `${ASSETS_BASE.URL}/arrow-circle-down-left.svg`,
  ARROW_CIRCLE_DOWN_RIGHT: `${ASSETS_BASE.URL}/arrow-circle-down-right.svg`,
  ARROW_CIRCLE_DOWN: `${ASSETS_BASE.URL}/arrow-circle-down.svg`,
  ARROW_CIRCLE_LEFT: `${ASSETS_BASE.URL}/arrow-circle-left.svg`,
  ARROW_CIRCLE_RIGHT: `${ASSETS_BASE.URL}/arrow-circle-right.svg`,
  ARROW_CIRCLE_UP_LEFT: `${ASSETS_BASE.URL}/arrow-circle-up-left.svg`,
  ARROW_CIRCLE_UP_RIGHT: `${ASSETS_BASE.URL}/arrow-circle-up-right.svg`,
  ARROW_CIRCLE_UP: `${ASSETS_BASE.URL}/arrow-circle-up.svg`,
  ARROW_DOWN_LEFT: `${ASSETS_BASE.URL}/arrow-down-left.svg`,
  ARROW_DOWN_RIGHT: `${ASSETS_BASE.URL}/arrow-down-right.svg`,
  ARROW_DOWN: `${ASSETS_BASE.URL}/arrow-down.svg`,
  ARROW_LEFT: `${ASSETS_BASE.URL}/arrow-left.svg`,
  ARROW_NARROW_DOWN_LEFT: `${ASSETS_BASE.URL}/arrow-narrow-down-left.svg`,
  ARROW_NARROW_DOWN_RIGHT: `${ASSETS_BASE.URL}/arrow-narrow-down-right.svg`,
  ARROW_NARROW_DOWN: `${ASSETS_BASE.URL}/arrow-narrow-down.svg`,
  ARROW_NARROW_LEFT: `${ASSETS_BASE.URL}/arrow-narrow-left.svg`,
  ARROW_NARROW_RIGHT: `${ASSETS_BASE.URL}/arrow-narrow-right.svg`,
  ARROW_NARROW_UP_LEFT: `${ASSETS_BASE.URL}/arrow-narrow-up-left.svg`,
  ARROW_NARROW_UP_RIGHT: `${ASSETS_BASE.URL}/arrow-narrow-up-right.svg`,
  ARROW_NARROW_UP: `${ASSETS_BASE.URL}/arrow-narrow-up.svg`,
  ARROW_RIGHT: `${ASSETS_BASE.URL}/arrow-right.svg`,
  ARROW_SQUARE_DOWN_LEFT: `${ASSETS_BASE.URL}/arrow-square-down-left.svg`,
  ARROW_SQUARE_DOWN_RIGHT: `${ASSETS_BASE.URL}/arrow-square-down-right.svg`,
  ARROW_SQUARE_DOWN: `${ASSETS_BASE.URL}/arrow-square-down.svg`,
  ARROW_SQUARE_LEFT: `${ASSETS_BASE.URL}/arrow-square-left.svg`,
  ARROW_SQUARE_RIGHT: `${ASSETS_BASE.URL}/arrow-square-right.svg`,
  ARROW_SQUARE_UP_LEFT: `${ASSETS_BASE.URL}/arrow-square-up-left.svg`,
  ARROW_SQUARE_UP_RIGHT: `${ASSETS_BASE.URL}/arrow-square-up-right.svg`,
  ARROW_SQUARE_UP: `${ASSETS_BASE.URL}/arrow-square-up.svg`,
  ARROW_UP_LEFT: `${ASSETS_BASE.URL}/arrow-up-left.svg`,
  ARROW_UP_RIGHT: `${ASSETS_BASE.URL}/arrow-up-right.svg`,
  ARROW_UP: `${ASSETS_BASE.URL}/arrow-up.svg`,
  ARROWS_DOWN: `${ASSETS_BASE.URL}/arrows-down.svg`,
  ARROWS_LEFT: `${ASSETS_BASE.URL}/arrows-left.svg`,
  ARROWS_RIGHT: `${ASSETS_BASE.URL}/arrows-right.svg`,
  ARROWS_TRIANGLE: `${ASSETS_BASE.URL}/arrows-triangle.svg`,
  ARROWS_UP: `${ASSETS_BASE.URL}/arrows-up.svg`,
  ASTERISK_01: `${ASSETS_BASE.URL}/asterisk-01.svg`,
  ASTERISK_02: `${ASSETS_BASE.URL}/asterisk-02.svg`,
  AT_SIGN: `${ASSETS_BASE.URL}/at-sign.svg`,
  ATOM_01: `${ASSETS_BASE.URL}/atom-01.svg`,
  ATOM_02: `${ASSETS_BASE.URL}/atom-02.svg`,
  ATTACHMENT_01: `${ASSETS_BASE.URL}/attachment-01.svg`,
  ATTACHMENT_02: `${ASSETS_BASE.URL}/attachment-02.svg`,
  AWARD_01: `${ASSETS_BASE.URL}/award-01.svg`,
  AWARD_02: `${ASSETS_BASE.URL}/award-02.svg`,
  AWARD_03: `${ASSETS_BASE.URL}/award-03.svg`,
  AWARD_04: `${ASSETS_BASE.URL}/award-04.svg`,
  AWARD_05: `${ASSETS_BASE.URL}/award-05.svg`,
  BACKPACK: `${ASSETS_BASE.URL}/backpack.svg`,
  BANK_NOTE_01: `${ASSETS_BASE.URL}/bank-note-01.svg`,
  BANK_NOTE_02: `${ASSETS_BASE.URL}/bank-note-02.svg`,
  BANK_NOTE_03: `${ASSETS_BASE.URL}/bank-note-03.svg`,
  BANK: `${ASSETS_BASE.URL}/bank.svg`,
  BAR_CHART_01: `${ASSETS_BASE.URL}/bar-chart-01.svg`,
  BAR_CHART_02: `${ASSETS_BASE.URL}/bar-chart-02.svg`,
  BAR_CHART_03: `${ASSETS_BASE.URL}/bar-chart-03.svg`,
  BAR_CHART_04: `${ASSETS_BASE.URL}/bar-chart-04.svg`,
  BAR_CHART_05: `${ASSETS_BASE.URL}/bar-chart-05.svg`,
  BAR_CHART_06: `${ASSETS_BASE.URL}/bar-chart-06.svg`,
  BAR_CHART_07: `${ASSETS_BASE.URL}/bar-chart-07.svg`,
  BAR_CHART_08: `${ASSETS_BASE.URL}/bar-chart-08.svg`,
  BAR_CHART_09: `${ASSETS_BASE.URL}/bar-chart-09.svg`,
  BAR_CHART_10: `${ASSETS_BASE.URL}/bar-chart-10.svg`,
  BAR_CHART_11: `${ASSETS_BASE.URL}/bar-chart-11.svg`,
  BAR_CHART_12: `${ASSETS_BASE.URL}/bar-chart-12.svg`,
  BAR_CHART_CIRCLE_01: `${ASSETS_BASE.URL}/bar-chart-circle-01.svg`,
  BAR_CHART_CIRCLE_02: `${ASSETS_BASE.URL}/bar-chart-circle-02.svg`,
  BAR_CHART_CIRCLE_03: `${ASSETS_BASE.URL}/bar-chart-circle-03.svg`,
  BAR_CHART_SQUARE_01: `${ASSETS_BASE.URL}/bar-chart-square-01.svg`,
  BAR_CHART_SQUARE_02: `${ASSETS_BASE.URL}/bar-chart-square-02.svg`,
  BAR_CHART_SQUARE_03: `${ASSETS_BASE.URL}/bar-chart-square-03.svg`,
  BAR_CHART_SQUARE_DOWN: `${ASSETS_BASE.URL}/bar-chart-square-down.svg`,
  BAR_CHART_SQUARE_MINUS: `${ASSETS_BASE.URL}/bar-chart-square-minus.svg`,
  BAR_CHART_SQUARE_PLUS: `${ASSETS_BASE.URL}/bar-chart-square-plus.svg`,
  BAR_CHART_SQUARE_UP: `${ASSETS_BASE.URL}/bar-chart-square-up.svg`,
  BAR_LINE_CHART: `${ASSETS_BASE.URL}/bar-line-chart.svg`,
  BATTERY_CHARGING_01: `${ASSETS_BASE.URL}/battery-charging-01.svg`,
  BATTERY_CHARGING_02: `${ASSETS_BASE.URL}/battery-charging-02.svg`,
  BATTERY_EMPTY: `${ASSETS_BASE.URL}/battery-empty.svg`,
  BATTERY_FULL: `${ASSETS_BASE.URL}/battery-full.svg`,
  BATTERY_LOW: `${ASSETS_BASE.URL}/battery-low.svg`,
  BATTERY_MID: `${ASSETS_BASE.URL}/battery-mid.svg`,
  BEAKER_01: `${ASSETS_BASE.URL}/beaker-01.svg`,
  BEAKER_02: `${ASSETS_BASE.URL}/beaker-02.svg`,
  BELL_01: `${ASSETS_BASE.URL}/bell-01.svg`,
  BELL_02: `${ASSETS_BASE.URL}/bell-02.svg`,
  BELL_03: `${ASSETS_BASE.URL}/bell-03.svg`,
  BELL_04: `${ASSETS_BASE.URL}/bell-04.svg`,
  BELL_MINUS: `${ASSETS_BASE.URL}/bell-minus.svg`,
  BELL_OFF_01: `${ASSETS_BASE.URL}/bell-off-01.svg`,
  BELL_OFF_02: `${ASSETS_BASE.URL}/bell-off-02.svg`,
  BELL_OFF_03: `${ASSETS_BASE.URL}/bell-off-03.svg`,
  BELL_PLUS: `${ASSETS_BASE.URL}/bell-plus.svg`,
  BELL_RINGING_01: `${ASSETS_BASE.URL}/bell-ringing-01.svg`,
  BELL_RINGING_02: `${ASSETS_BASE.URL}/bell-ringing-02.svg`,
  BELL_RINGING_03: `${ASSETS_BASE.URL}/bell-ringing-03.svg`,
  BELL_RINGING_04: `${ASSETS_BASE.URL}/bell-ringing-04.svg`,
  BEZIER_CURVE_01: `${ASSETS_BASE.URL}/bezier-curve-01.svg`,
  BEZIER_CURVE_02: `${ASSETS_BASE.URL}/bezier-curve-02.svg`,
  BEZIER_CURVE_03: `${ASSETS_BASE.URL}/bezier-curve-03.svg`,
  BLUETOOTH_CONNECT: `${ASSETS_BASE.URL}/bluetooth-connect.svg`,
  BLUETOOTH_ON: `${ASSETS_BASE.URL}/bluetooth-on.svg`,
  BLUETOOTH_SIGNAL: `${ASSETS_BASE.URL}/bluetooth-signal.svg`,
  BLUETOOTH: `${ASSETS_BASE.URL}/bluetooth.svg`,
  BOLD_01: `${ASSETS_BASE.URL}/bold-01.svg`,
  BOLD_02: `${ASSETS_BASE.URL}/bold-02.svg`,
  BOLD_SQUARE: `${ASSETS_BASE.URL}/bold-square.svg`,
  BOOK_CLOSED: `${ASSETS_BASE.URL}/book-closed.svg`,
  BOOK_OPEN_01: `${ASSETS_BASE.URL}/book-open-01.svg`,
  BOOK_OPEN_02: `${ASSETS_BASE.URL}/book-open-02.svg`,
  BOOKMARK_ADD: `${ASSETS_BASE.URL}/bookmark-add.svg`,
  BOOKMARK_CHECK: `${ASSETS_BASE.URL}/bookmark-check.svg`,
  BOOKMARK_MINUS: `${ASSETS_BASE.URL}/bookmark-minus.svg`,
  BOOKMARK_X: `${ASSETS_BASE.URL}/bookmark-x.svg`,
  BOOKMARK: `${ASSETS_BASE.URL}/bookmark.svg`,
  BOX: `${ASSETS_BASE.URL}/box.svg`,
  BRACKETS_CHECK: `${ASSETS_BASE.URL}/brackets-check.svg`,
  BRACKETS_ELLIPSES: `${ASSETS_BASE.URL}/brackets-ellipses.svg`,
  BRACKETS_MINUS: `${ASSETS_BASE.URL}/brackets-minus.svg`,
  BRACKETS_PLUS: `${ASSETS_BASE.URL}/brackets-plus.svg`,
  BRACKETS_SLASH: `${ASSETS_BASE.URL}/brackets-slash.svg`,
  BRACKETS_X: `${ASSETS_BASE.URL}/brackets-x.svg`,
  BRACKETS: `${ASSETS_BASE.URL}/brackets.svg`,
  BRIEFCASE_01: `${ASSETS_BASE.URL}/briefcase-01.svg`,
  BRIEFCASE_02: `${ASSETS_BASE.URL}/briefcase-02.svg`,
  BROWSER: `${ASSETS_BASE.URL}/browser.svg`,
  BRUSH_01: `${ASSETS_BASE.URL}/brush-01.svg`,
  BRUSH_02: `${ASSETS_BASE.URL}/brush-02.svg`,
  BRUSH_03: `${ASSETS_BASE.URL}/brush-03.svg`,
  BUILDING_01: `${ASSETS_BASE.URL}/building-01.svg`,
  BUILDING_02: `${ASSETS_BASE.URL}/building-02.svg`,
  BUILDING_03: `${ASSETS_BASE.URL}/building-03.svg`,
  BUILDING_04: `${ASSETS_BASE.URL}/building-04.svg`,
  BUILDING_05: `${ASSETS_BASE.URL}/building-05.svg`,
  BUILDING_06: `${ASSETS_BASE.URL}/building-06.svg`,
  BUILDING_07: `${ASSETS_BASE.URL}/building-07.svg`,
  BUILDING_08: `${ASSETS_BASE.URL}/building-08.svg`,
  BUS: `${ASSETS_BASE.URL}/bus.svg`,
  CALCULATOR: `${ASSETS_BASE.URL}/calculator.svg`,
  CALENDAR_CHECK_01: `${ASSETS_BASE.URL}/calendar-check-01.svg`,
  CALENDAR_CHECK_02: `${ASSETS_BASE.URL}/calendar-check-02.svg`,
  CALENDAR_DATE: `${ASSETS_BASE.URL}/calendar-date.svg`,
  CALENDAR_HEART_01: `${ASSETS_BASE.URL}/calendar-heart-01.svg`,
  CALENDAR_HEART_02: `${ASSETS_BASE.URL}/calendar-heart-02.svg`,
  CALENDAR_MINUS_01: `${ASSETS_BASE.URL}/calendar-minus-01.svg`,
  CALENDAR_MINUS_02: `${ASSETS_BASE.URL}/calendar-minus-02.svg`,
  CALENDAR_PLUS_01: `${ASSETS_BASE.URL}/calendar-plus-01.svg`,
  CALENDAR_PLUS_02: `${ASSETS_BASE.URL}/calendar-plus-02.svg`,
  CALENDAR: `${ASSETS_BASE.URL}/calendar.svg`,
  CAMERA_01: `${ASSETS_BASE.URL}/camera-01.svg`,
  CAMERA_02: `${ASSETS_BASE.URL}/camera-02.svg`,
  CAMERA_03: `${ASSETS_BASE.URL}/camera-03.svg`,
  CAMERA_LENS: `${ASSETS_BASE.URL}/camera-lens.svg`,
  CAMERA_OFF: `${ASSETS_BASE.URL}/camera-off.svg`,
  CAMERA_PLUS: `${ASSETS_BASE.URL}/camera-plus.svg`,
  CERTIFICATE_01: `${ASSETS_BASE.URL}/certificate-01.svg`,
  CERTIFICATE_02: `${ASSETS_BASE.URL}/certificate-02.svg`,
  CHART_BREAKOUT_CIRCLE: `${ASSETS_BASE.URL}/chart-breakout-circle.svg`,
  CHART_BREAKOUT_SQUARE: `${ASSETS_BASE.URL}/chart-breakout-square.svg`,
  CHECK_CIRCLE_BROKEN: `${ASSETS_BASE.URL}/check-circle-broken.svg`,
  CHECK_CIRCLE: `${ASSETS_BASE.URL}/check-circle.svg`,
  CHECK_DONE_01: `${ASSETS_BASE.URL}/check-done-01.svg`,
  CHECK_DONE_02: `${ASSETS_BASE.URL}/check-done-02.svg`,
  CHECK_HEART: `${ASSETS_BASE.URL}/check-heart.svg`,
  CHECK_SQUARE_BROKEN: `${ASSETS_BASE.URL}/check-square-broken.svg`,
  CHECK_SQUARE: `${ASSETS_BASE.URL}/check-square.svg`,
  CHECK_VERIFIED_01: `${ASSETS_BASE.URL}/check-verified-01.svg`,
  CHECK_VERIFIED_02: `${ASSETS_BASE.URL}/check-verified-02.svg`,
  CHECK_VERIFIED_03: `${ASSETS_BASE.URL}/check-verified-03.svg`,
  CHECK: `${ASSETS_BASE.URL}/check.svg`,
  CHEVRON_DOWN_DOUBLE: `${ASSETS_BASE.URL}/chevron-down-double.svg`,
  CHEVRON_DOWN: `${ASSETS_BASE.URL}/chevron-down.svg`,
  CHEVRON_LEFT_DOUBLE: `${ASSETS_BASE.URL}/chevron-left-double.svg`,
  CHEVRON_LEFT: `${ASSETS_BASE.URL}/chevron-left.svg`,
  CHEVRON_RIGHT_DOUBLE: `${ASSETS_BASE.URL}/chevron-right-double.svg`,
  CHEVRON_RIGHT: `${ASSETS_BASE.URL}/chevron-right.svg`,
  CHEVRON_SELECTOR_HORIZONTAL: `${ASSETS_BASE.URL}/chevron-selector-horizontal.svg`,
  CHEVRON_SELECTOR_VERTICAL: `${ASSETS_BASE.URL}/chevron-selector-vertical.svg`,
  CHEVRON_UP_DOUBLE: `${ASSETS_BASE.URL}/chevron-up-double.svg`,
  CHEVRON_UP: `${ASSETS_BASE.URL}/chevron-up.svg`,
  CHROME_CAST: `${ASSETS_BASE.URL}/chrome-cast.svg`,
  CIRCLE_CUT: `${ASSETS_BASE.URL}/circle-cut.svg`,
  CIRCLE: `${ASSETS_BASE.URL}/circle.svg`,
  CLAPPERBOARD: `${ASSETS_BASE.URL}/clapperboard.svg`,
  CLIPBOARD_ATTACHMENT: `${ASSETS_BASE.URL}/clipboard-attachment.svg`,
  CLIPBOARD_CHECK: `${ASSETS_BASE.URL}/clipboard-check.svg`,
  CLIPBOARD_DOWNLOAD: `${ASSETS_BASE.URL}/clipboard-download.svg`,
  CLIPBOARD_MINUS: `${ASSETS_BASE.URL}/clipboard-minus.svg`,
  CLIPBOARD_PLUS: `${ASSETS_BASE.URL}/clipboard-plus.svg`,
  CLIPBOARD_X: `${ASSETS_BASE.URL}/clipboard-x.svg`,
  CLIPBOARD: `${ASSETS_BASE.URL}/clipboard.svg`,
  CLOCK_CHECK: `${ASSETS_BASE.URL}/clock-check.svg`,
  CLOCK_FAST_FORWARD: `${ASSETS_BASE.URL}/clock-fast-forward.svg`,
  CLOCK_PLUS: `${ASSETS_BASE.URL}/clock-plus.svg`,
  CLOCK_REFRESH: `${ASSETS_BASE.URL}/clock-refresh.svg`,
  CLOCK_REWIND: `${ASSETS_BASE.URL}/clock-rewind.svg`,
  CLOCK_SNOOZE: `${ASSETS_BASE.URL}/clock-snooze.svg`,
  CLOCK_STOPWATCH: `${ASSETS_BASE.URL}/clock-stopwatch.svg`,
  CLOCK: `${ASSETS_BASE.URL}/clock.svg`,
  CLOUD_01: `${ASSETS_BASE.URL}/cloud-01.svg`,
  CLOUD_02: `${ASSETS_BASE.URL}/cloud-02.svg`,
  CLOUD_03: `${ASSETS_BASE.URL}/cloud-03.svg`,
  CLOUD_BLANK_01: `${ASSETS_BASE.URL}/cloud-blank-01.svg`,
  CLOUD_BLANK_02: `${ASSETS_BASE.URL}/cloud-blank-02.svg`,
  CLOUD_LIGHTNING: `${ASSETS_BASE.URL}/cloud-lightning.svg`,
  CLOUD_MOON: `${ASSETS_BASE.URL}/cloud-moon.svg`,
  CLOUD_OFF: `${ASSETS_BASE.URL}/cloud-off.svg`,
  CLOUD_RAINING_01: `${ASSETS_BASE.URL}/cloud-raining-01.svg`,
  CLOUD_RAINING_02: `${ASSETS_BASE.URL}/cloud-raining-02.svg`,
  CLOUD_RAINING_03: `${ASSETS_BASE.URL}/cloud-raining-03.svg`,
  CLOUD_RAINING_04: `${ASSETS_BASE.URL}/cloud-raining-04.svg`,
  CLOUD_RAINING_05: `${ASSETS_BASE.URL}/cloud-raining-05.svg`,
  CLOUD_RAINING_06: `${ASSETS_BASE.URL}/cloud-raining-06.svg`,
  CLOUD_SNOWING_01: `${ASSETS_BASE.URL}/cloud-snowing-01.svg`,
  CLOUD_SNOWING_02: `${ASSETS_BASE.URL}/cloud-snowing-02.svg`,
  CLOUD_SUN_01: `${ASSETS_BASE.URL}/cloud-sun-01.svg`,
  CLOUD_SUN_02: `${ASSETS_BASE.URL}/cloud-sun-02.svg`,
  CLOUD_SUN_03: `${ASSETS_BASE.URL}/cloud-sun-03.svg`,
  CODE_01: `${ASSETS_BASE.URL}/code-01.svg`,
  CODE_02: `${ASSETS_BASE.URL}/code-02.svg`,
  CODE_BROWSER: `${ASSETS_BASE.URL}/code-browser.svg`,
  CODE_CIRCLE_01: `${ASSETS_BASE.URL}/code-circle-01.svg`,
  CODE_CIRCLE_02: `${ASSETS_BASE.URL}/code-circle-02.svg`,
  CODE_CIRCLE_03: `${ASSETS_BASE.URL}/code-circle-03.svg`,
  CODE_SNIPPET_01: `${ASSETS_BASE.URL}/code-snippet-01.svg`,
  CODE_SNIPPET_02: `${ASSETS_BASE.URL}/code-snippet-02.svg`,
  CODE_SQUARE_01: `${ASSETS_BASE.URL}/code-square-01.svg`,
  CODE_SQUARE_02: `${ASSETS_BASE.URL}/code-square-02.svg`,
  CODEPEN: `${ASSETS_BASE.URL}/codepen.svg`,
  COINS_01: `${ASSETS_BASE.URL}/coins-01.svg`,
  COINS_02: `${ASSETS_BASE.URL}/coins-02.svg`,
  COINS_03: `${ASSETS_BASE.URL}/coins-03.svg`,
  COINS_04: `${ASSETS_BASE.URL}/coins-04.svg`,
  COINS_HAND: `${ASSETS_BASE.URL}/coins-hand.svg`,
  COINS_STACKED_01: `${ASSETS_BASE.URL}/coins-stacked-01.svg`,
  COINS_STACKED_02: `${ASSETS_BASE.URL}/coins-stacked-02.svg`,
  COINS_STACKED_03: `${ASSETS_BASE.URL}/coins-stacked-03.svg`,
  COINS_STACKED_04: `${ASSETS_BASE.URL}/coins-stacked-04.svg`,
  COINS_SWAP_01: `${ASSETS_BASE.URL}/coins-swap-01.svg`,
  COINS_SWAP_02: `${ASSETS_BASE.URL}/coins-swap-02.svg`,
  COLORS: `${ASSETS_BASE.URL}/colors.svg`,
  COLUMNS_02: `${ASSETS_BASE.URL}/columns-02.svg`,
  COLUMNS_03: `${ASSETS_BASE.URL}/columns-03.svg`,
  COMMAND: `${ASSETS_BASE.URL}/command.svg`,
  COMPASS_01: `${ASSETS_BASE.URL}/compass-01.svg`,
  COMPASS_02: `${ASSETS_BASE.URL}/compass-02.svg`,
  COMPASS_03: `${ASSETS_BASE.URL}/compass-03.svg`,
  COMPASS: `${ASSETS_BASE.URL}/compass.svg`,
  CONTAINER: `${ASSETS_BASE.URL}/container.svg`,
  CONTRAST_01: `${ASSETS_BASE.URL}/contrast-01.svg`,
  CONTRAST_02: `${ASSETS_BASE.URL}/contrast-02.svg`,
  CONTRAST_03: `${ASSETS_BASE.URL}/contrast-03.svg`,
  COPY_01: `${ASSETS_BASE.URL}/copy-01.svg`,
  COPY_02: `${ASSETS_BASE.URL}/copy-02.svg`,
  COPY_03: `${ASSETS_BASE.URL}/copy-03.svg`,
  COPY_04: `${ASSETS_BASE.URL}/copy-04.svg`,
  COPY_05: `${ASSETS_BASE.URL}/copy-05.svg`,
  COPY_06: `${ASSETS_BASE.URL}/copy-06.svg`,
  COPY_07: `${ASSETS_BASE.URL}/copy-07.svg`,
  CORNER_DOWN_LEFT: `${ASSETS_BASE.URL}/corner-down-left.svg`,
  CORNER_DOWN_RIGHT: `${ASSETS_BASE.URL}/corner-down-right.svg`,
  CORNER_LEFT_DOWN: `${ASSETS_BASE.URL}/corner-left-down.svg`,
  CORNER_LEFT_UP: `${ASSETS_BASE.URL}/corner-left-up.svg`,
  CORNER_RIGHT_DOWN: `${ASSETS_BASE.URL}/corner-right-down.svg`,
  CORNER_RIGHT_UP: `${ASSETS_BASE.URL}/corner-right-up.svg`,
  CORNER_UP_LEFT: `${ASSETS_BASE.URL}/corner-up-left.svg`,
  CORNER_UP_RIGHT: `${ASSETS_BASE.URL}/corner-up-right.svg`,
  CPU_CHIP_01: `${ASSETS_BASE.URL}/cpu-chip-01.svg`,
  CPU_CHIP_02: `${ASSETS_BASE.URL}/cpu-chip-02.svg`,
  CREDIT_CARD_01: `${ASSETS_BASE.URL}/credit-card-01.svg`,
  CREDIT_CARD_02: `${ASSETS_BASE.URL}/credit-card-02.svg`,
  CREDIT_CARD_CHECK: `${ASSETS_BASE.URL}/credit-card-check.svg`,
  CREDIT_CARD_DOWN: `${ASSETS_BASE.URL}/credit-card-down.svg`,
  CREDIT_CARD_DOWNLOAD: `${ASSETS_BASE.URL}/credit-card-download.svg`,
  CREDIT_CARD_EDIT: `${ASSETS_BASE.URL}/credit-card-edit.svg`,
  CREDIT_CARD_LOCK: `${ASSETS_BASE.URL}/credit-card-lock.svg`,
  CREDIT_CARD_MINUS: `${ASSETS_BASE.URL}/credit-card-minus.svg`,
  CREDIT_CARD_PLUS: `${ASSETS_BASE.URL}/credit-card-plus.svg`,
  CREDIT_CARD_REFRESH: `${ASSETS_BASE.URL}/credit-card-refresh.svg`,
  CREDIT_CARD_SEARCH: `${ASSETS_BASE.URL}/credit-card-search.svg`,
  CREDIT_CARD_SHIELD: `${ASSETS_BASE.URL}/credit-card-shield.svg`,
  CREDIT_CARD_UP: `${ASSETS_BASE.URL}/credit-card-up.svg`,
  CREDIT_CARD_UPLOAD: `${ASSETS_BASE.URL}/credit-card-upload.svg`,
  CREDIT_CARD_X: `${ASSETS_BASE.URL}/credit-card-x.svg`,
  CROP_01: `${ASSETS_BASE.URL}/crop-01.svg`,
  CROP_02: `${ASSETS_BASE.URL}/crop-02.svg`,
  CRYPTOCURRENCY_01: `${ASSETS_BASE.URL}/cryptocurrency-01.svg`,
  CRYPTOCURRENCY_02: `${ASSETS_BASE.URL}/cryptocurrency-02.svg`,
  CRYPTOCURRENCY_03: `${ASSETS_BASE.URL}/cryptocurrency-03.svg`,
  CRYPTOCURRENCY_04: `${ASSETS_BASE.URL}/cryptocurrency-04.svg`,
  CUBE_01: `${ASSETS_BASE.URL}/cube-01.svg`,
  CUBE_02: `${ASSETS_BASE.URL}/cube-02.svg`,
  CUBE_03: `${ASSETS_BASE.URL}/cube-03.svg`,
  CUBE_04: `${ASSETS_BASE.URL}/cube-04.svg`,
  CUBE_OUTLINE: `${ASSETS_BASE.URL}/cube-outline.svg`,
  CURRENCY_BITCOIN_CIRCLE: `${ASSETS_BASE.URL}/currency-bitcoin-circle.svg`,
  CURRENCY_BITCOIN: `${ASSETS_BASE.URL}/currency-bitcoin.svg`,
  CURRENCY_DOLLAR_CIRCLE: `${ASSETS_BASE.URL}/currency-dollar-circle.svg`,
  CURRENCY_DOLLAR: `${ASSETS_BASE.URL}/currency-dollar.svg`,
  CURRENCY_ETHEREUM_CIRCLE: `${ASSETS_BASE.URL}/currency-ethereum-circle.svg`,
  CURRENCY_ETHEREUM: `${ASSETS_BASE.URL}/currency-ethereum.svg`,
  CURRENCY_EURO_CIRCLE: `${ASSETS_BASE.URL}/currency-euro-circle.svg`,
  CURRENCY_EURO: `${ASSETS_BASE.URL}/currency-euro.svg`,
  CURRENCY_POUND_CIRCLE: `${ASSETS_BASE.URL}/currency-pound-circle.svg`,
  CURRENCY_POUND: `${ASSETS_BASE.URL}/currency-pound.svg`,
  CURRENCY_RUBLE_CIRCLE: `${ASSETS_BASE.URL}/currency-ruble-circle.svg`,
  CURRENCY_RUBLE: `${ASSETS_BASE.URL}/currency-ruble.svg`,
  CURRENCY_RUPEE_CIRCLE: `${ASSETS_BASE.URL}/currency-rupee-circle.svg`,
  CURRENCY_RUPEE: `${ASSETS_BASE.URL}/currency-rupee.svg`,
  CURRENCY_YEN_CIRCLE: `${ASSETS_BASE.URL}/currency-yen-circle.svg`,
  CURRENCY_YEN: `${ASSETS_BASE.URL}/currency-yen.svg`,
  CURSOR_01: `${ASSETS_BASE.URL}/cursor-01.svg`,
  CURSOR_02: `${ASSETS_BASE.URL}/cursor-02.svg`,
  CURSOR_03: `${ASSETS_BASE.URL}/cursor-03.svg`,
  CURSOR_04: `${ASSETS_BASE.URL}/cursor-04.svg`,
  CURSOR_BOX: `${ASSETS_BASE.URL}/cursor-box.svg`,
  CURSOR_CLICK_01: `${ASSETS_BASE.URL}/cursor-click-01.svg`,
  CURSOR_CLICK_02: `${ASSETS_BASE.URL}/cursor-click-02.svg`,
  DATA: `${ASSETS_BASE.URL}/data.svg`,
  DATABASE_01: `${ASSETS_BASE.URL}/database-01.svg`,
  DATABASE_02: `${ASSETS_BASE.URL}/database-02.svg`,
  DATABASE_03: `${ASSETS_BASE.URL}/database-03.svg`,
  DATAFLOW_01: `${ASSETS_BASE.URL}/dataflow-01.svg`,
  DATAFLOW_02: `${ASSETS_BASE.URL}/dataflow-02.svg`,
  DATAFLOW_03: `${ASSETS_BASE.URL}/dataflow-03.svg`,
  DATAFLOW_04: `${ASSETS_BASE.URL}/dataflow-04.svg`,
  DELETE: `${ASSETS_BASE.URL}/delete.svg`,
  DIAMOND_01: `${ASSETS_BASE.URL}/diamond-01.svg`,
  DIAMOND_02: `${ASSETS_BASE.URL}/diamond-02.svg`,
  DICE_1: `${ASSETS_BASE.URL}/dice-1.svg`,
  DICE_2: `${ASSETS_BASE.URL}/dice-2.svg`,
  DICE_3: `${ASSETS_BASE.URL}/dice-3.svg`,
  DICE_4: `${ASSETS_BASE.URL}/dice-4.svg`,
  DICE_5: `${ASSETS_BASE.URL}/dice-5.svg`,
  DICE_6: `${ASSETS_BASE.URL}/dice-6.svg`,
  DISC_01: `${ASSETS_BASE.URL}/disc-01.svg`,
  DISC_02: `${ASSETS_BASE.URL}/disc-02.svg`,
  DISTRIBUTE_SPACING_HORIZONTAL: `${ASSETS_BASE.URL}/distribute-spacing-horizontal.svg`,
  DISTRIBUTE_SPACING_VERTICAL: `${ASSETS_BASE.URL}/distribute-spacing-vertical.svg`,
  DIVIDE_01: `${ASSETS_BASE.URL}/divide-01.svg`,
  DIVIDE_02: `${ASSETS_BASE.URL}/divide-02.svg`,
  DIVIDE_03: `${ASSETS_BASE.URL}/divide-03.svg`,
  DIVIDER: `${ASSETS_BASE.URL}/divider.svg`,
  DOTPOINTS_01: `${ASSETS_BASE.URL}/dotpoints-01.svg`,
  DOTPOINTS_02: `${ASSETS_BASE.URL}/dotpoints-02.svg`,
  DOTS_GRID: `${ASSETS_BASE.URL}/dots-grid.svg`,
  DOTS_HORIZONTAL: `${ASSETS_BASE.URL}/dots-horizontal.svg`,
  DOTS_VERTICAL: `${ASSETS_BASE.URL}/dots-vertical.svg`,
  DOWNLOAD_01: `${ASSETS_BASE.URL}/download-01.svg`,
  DOWNLOAD_02: `${ASSETS_BASE.URL}/download-02.svg`,
  DOWNLOAD_03: `${ASSETS_BASE.URL}/download-03.svg`,
  DOWNLOAD_04: `${ASSETS_BASE.URL}/download-04.svg`,
  DOWNLOAD_CLOUD_01: `${ASSETS_BASE.URL}/download-cloud-01.svg`,
  DOWNLOAD_CLOUD_02: `${ASSETS_BASE.URL}/download-cloud-02.svg`,
  DROP: `${ASSETS_BASE.URL}/drop.svg`,
  DROPLETS_01: `${ASSETS_BASE.URL}/droplets-01.svg`,
  DROPLETS_02: `${ASSETS_BASE.URL}/droplets-02.svg`,
  DROPLETS_03: `${ASSETS_BASE.URL}/droplets-03.svg`,
  DROPPER: `${ASSETS_BASE.URL}/dropper.svg`,
  EDIT_01: `${ASSETS_BASE.URL}/edit-01.svg`,
  EDIT_02: `${ASSETS_BASE.URL}/edit-02.svg`,
  EDIT_03: `${ASSETS_BASE.URL}/edit-03.svg`,
  EDIT_04: `${ASSETS_BASE.URL}/edit-04.svg`,
  EDIT_05: `${ASSETS_BASE.URL}/edit-05.svg`,
  EQUAL_NOT: `${ASSETS_BASE.URL}/equal-not.svg`,
  EQUAL: `${ASSETS_BASE.URL}/equal.svg`,
  ERASER: `${ASSETS_BASE.URL}/eraser.svg`,
  EXPAND_01: `${ASSETS_BASE.URL}/expand-01.svg`,
  EXPAND_02: `${ASSETS_BASE.URL}/expand-02.svg`,
  EXPAND_03: `${ASSETS_BASE.URL}/expand-03.svg`,
  EXPAND_04: `${ASSETS_BASE.URL}/expand-04.svg`,
  EXPAND_05: `${ASSETS_BASE.URL}/expand-05.svg`,
  EXPAND_06: `${ASSETS_BASE.URL}/expand-06.svg`,
  EYE_OFF: `${ASSETS_BASE.URL}/eye-off.svg`,
  EYE: `${ASSETS_BASE.URL}/eye.svg`,
  FACE_CONTENT: `${ASSETS_BASE.URL}/face-content.svg`,
  FACE_FROWN: `${ASSETS_BASE.URL}/face-frown.svg`,
  FACE_HAPPY: `${ASSETS_BASE.URL}/face-happy.svg`,
  FACE_ID_SQUARE: `${ASSETS_BASE.URL}/face-id-square.svg`,
  FACE_ID: `${ASSETS_BASE.URL}/face-id.svg`,
  FACE_NEUTRAL: `${ASSETS_BASE.URL}/face-neutral.svg`,
  FACE_SAD: `${ASSETS_BASE.URL}/face-sad.svg`,
  FACE_SMILE: `${ASSETS_BASE.URL}/face-smile.svg`,
  FACE_WINK: `${ASSETS_BASE.URL}/face-wink.svg`,
  FAST_BACKWARD: `${ASSETS_BASE.URL}/fast-backward.svg`,
  FAST_FORWARD: `${ASSETS_BASE.URL}/fast-forward.svg`,
  FEATHER: `${ASSETS_BASE.URL}/feather.svg`,
  FIGMA: `${ASSETS_BASE.URL}/figma.svg`,
  FILE_01: `${ASSETS_BASE.URL}/file-01.svg`,
  FILE_02: `${ASSETS_BASE.URL}/file-02.svg`,
  FILE_03: `${ASSETS_BASE.URL}/file-03.svg`,
  FILE_04: `${ASSETS_BASE.URL}/file-04.svg`,
  FILE_05: `${ASSETS_BASE.URL}/file-05.svg`,
  FILE_06: `${ASSETS_BASE.URL}/file-06.svg`,
  FILE_07: `${ASSETS_BASE.URL}/file-07.svg`,
  FILE_ATTACHMENT_01: `${ASSETS_BASE.URL}/file-attachment-01.svg`,
  FILE_ATTACHMENT_02: `${ASSETS_BASE.URL}/file-attachment-02.svg`,
  FILE_ATTACHMENT_03: `${ASSETS_BASE.URL}/file-attachment-03.svg`,
  FILE_ATTACHMENT_04: `${ASSETS_BASE.URL}/file-attachment-04.svg`,
  FILE_ATTACHMENT_05: `${ASSETS_BASE.URL}/file-attachment-05.svg`,
  FILE_CHECK_01: `${ASSETS_BASE.URL}/file-check-01.svg`,
  FILE_CHECK_02: `${ASSETS_BASE.URL}/file-check-02.svg`,
  FILE_CHECK_03: `${ASSETS_BASE.URL}/file-check-03.svg`,
  FILE_CODE_01: `${ASSETS_BASE.URL}/file-code-01.svg`,
  FILE_CODE_02: `${ASSETS_BASE.URL}/file-code-02.svg`,
  FILE_DOWNLOAD_01: `${ASSETS_BASE.URL}/file-download-01.svg`,
  FILE_DOWNLOAD_02: `${ASSETS_BASE.URL}/file-download-02.svg`,
  FILE_DOWNLOAD_03: `${ASSETS_BASE.URL}/file-download-03.svg`,
  FILE_HEART_01: `${ASSETS_BASE.URL}/file-heart-01.svg`,
  FILE_HEART_02: `${ASSETS_BASE.URL}/file-heart-02.svg`,
  FILE_HEART_03: `${ASSETS_BASE.URL}/file-heart-03.svg`,
  FILE_LOCK_01: `${ASSETS_BASE.URL}/file-lock-01.svg`,
  FILE_LOCK_02: `${ASSETS_BASE.URL}/file-lock-02.svg`,
  FILE_LOCK_03: `${ASSETS_BASE.URL}/file-lock-03.svg`,
  FILE_MINUS_01: `${ASSETS_BASE.URL}/file-minus-01.svg`,
  FILE_MINUS_02: `${ASSETS_BASE.URL}/file-minus-02.svg`,
  FILE_MINUS_03: `${ASSETS_BASE.URL}/file-minus-03.svg`,
  FILE_PLUS_01: `${ASSETS_BASE.URL}/file-plus-01.svg`,
  FILE_PLUS_02: `${ASSETS_BASE.URL}/file-plus-02.svg`,
  FILE_PLUS_03: `${ASSETS_BASE.URL}/file-plus-03.svg`,
  FILE_QUESTION_01: `${ASSETS_BASE.URL}/file-question-01.svg`,
  FILE_QUESTION_02: `${ASSETS_BASE.URL}/file-question-02.svg`,
  FILE_QUESTION_03: `${ASSETS_BASE.URL}/file-question-03.svg`,
  FILE_SEARCH_01: `${ASSETS_BASE.URL}/file-search-01.svg`,
  FILE_SEARCH_02: `${ASSETS_BASE.URL}/file-search-02.svg`,
  FILE_SEARCH_03: `${ASSETS_BASE.URL}/file-search-03.svg`,
  FILE_SHIELD_01: `${ASSETS_BASE.URL}/file-shield-01.svg`,
  FILE_SHIELD_02: `${ASSETS_BASE.URL}/file-shield-02.svg`,
  FILE_SHIELD_03: `${ASSETS_BASE.URL}/file-shield-03.svg`,
  FILE_X_01: `${ASSETS_BASE.URL}/file-x-01.svg`,
  FILE_X_02: `${ASSETS_BASE.URL}/file-x-02.svg`,
  FILE_X_03: `${ASSETS_BASE.URL}/file-x-03.svg`,
  FILM_01: `${ASSETS_BASE.URL}/film-01.svg`,
  FILM_02: `${ASSETS_BASE.URL}/film-02.svg`,
  FILM_03: `${ASSETS_BASE.URL}/film-03.svg`,
  FILTER_FUNNEL_01: `${ASSETS_BASE.URL}/filter-funnel-01.svg`,
  FILTER_FUNNEL_02: `${ASSETS_BASE.URL}/filter-funnel-02.svg`,
  FILTER_LINES: `${ASSETS_BASE.URL}/filter-lines.svg`,
  FINGERPRINT_01: `${ASSETS_BASE.URL}/fingerprint-01.svg`,
  FINGERPRINT_02: `${ASSETS_BASE.URL}/fingerprint-02.svg`,
  FINGERPRINT_03: `${ASSETS_BASE.URL}/fingerprint-03.svg`,
  FINGERPRINT_04: `${ASSETS_BASE.URL}/fingerprint-04.svg`,
  FLAG_01: `${ASSETS_BASE.URL}/flag-01.svg`,
  FLAG_02: `${ASSETS_BASE.URL}/flag-02.svg`,
  FLAG_03: `${ASSETS_BASE.URL}/flag-03.svg`,
  FLAG_04: `${ASSETS_BASE.URL}/flag-04.svg`,
  FLAG_05: `${ASSETS_BASE.URL}/flag-05.svg`,
  FLAG_06: `${ASSETS_BASE.URL}/flag-06.svg`,
  FLASH_OFF: `${ASSETS_BASE.URL}/flash-off.svg`,
  FLASH: `${ASSETS_BASE.URL}/flash.svg`,
  FLEX_ALIGN_BOTTOM: `${ASSETS_BASE.URL}/flex-align-bottom.svg`,
  FLEX_ALIGN_LEFT: `${ASSETS_BASE.URL}/flex-align-left.svg`,
  FLEX_ALIGN_RIGHT: `${ASSETS_BASE.URL}/flex-align-right.svg`,
  FLEX_ALIGN_TOP: `${ASSETS_BASE.URL}/flex-align-top.svg`,
  FLIP_BACKWARD: `${ASSETS_BASE.URL}/flip-backward.svg`,
  FLIP_FORWARD: `${ASSETS_BASE.URL}/flip-forward.svg`,
  FOLDER_CHECK: `${ASSETS_BASE.URL}/folder-check.svg`,
  FOLDER_CLOSED: `${ASSETS_BASE.URL}/folder-closed.svg`,
  FOLDER_CODE: `${ASSETS_BASE.URL}/folder-code.svg`,
  FOLDER_DOWNLOAD: `${ASSETS_BASE.URL}/folder-download.svg`,
  FOLDER_LOCK: `${ASSETS_BASE.URL}/folder-lock.svg`,
  FOLDER_MINUS: `${ASSETS_BASE.URL}/folder-minus.svg`,
  FOLDER_PLUS: `${ASSETS_BASE.URL}/folder-plus.svg`,
  FOLDER_QUESTION: `${ASSETS_BASE.URL}/folder-question.svg`,
  FOLDER_SEARCH: `${ASSETS_BASE.URL}/folder-search.svg`,
  FOLDER_SHIELD: `${ASSETS_BASE.URL}/folder-shield.svg`,
  FOLDER_X: `${ASSETS_BASE.URL}/folder-x.svg`,
  FOLDER: `${ASSETS_BASE.URL}/folder.svg`,
  FRAMER: `${ASSETS_BASE.URL}/framer.svg`,
  GAMING_PAD_01: `${ASSETS_BASE.URL}/gaming-pad-01.svg`,
  GAMING_PAD_02: `${ASSETS_BASE.URL}/gaming-pad-02.svg`,
  GIFT_01: `${ASSETS_BASE.URL}/gift-01.svg`,
  GIFT_02: `${ASSETS_BASE.URL}/gift-02.svg`,
  GIT_BRANCH_01: `${ASSETS_BASE.URL}/git-branch-01.svg`,
  GIT_BRANCH_02: `${ASSETS_BASE.URL}/git-branch-02.svg`,
  GIT_COMMIT: `${ASSETS_BASE.URL}/git-commit.svg`,
  GIT_MERGE: `${ASSETS_BASE.URL}/git-merge.svg`,
  GIT_PULL_REQUEST: `${ASSETS_BASE.URL}/git-pull-request.svg`,
  GLASSES_01: `${ASSETS_BASE.URL}/glasses-01.svg`,
  GLASSES_02: `${ASSETS_BASE.URL}/glasses-02.svg`,
  GLOBE_01_EDUCATION: `${ASSETS_BASE.URL}/globe-01-education.svg`,
  GLOBE_01: `${ASSETS_BASE.URL}/globe-01.svg`,
  GLOBE_02_EDUCATION: `${ASSETS_BASE.URL}/globe-02-education.svg`,
  GLOBE_02: `${ASSETS_BASE.URL}/globe-02.svg`,
  GLOBE_03: `${ASSETS_BASE.URL}/globe-03.svg`,
  GLOBE_04: `${ASSETS_BASE.URL}/globe-04.svg`,
  GLOBE_05: `${ASSETS_BASE.URL}/globe-05.svg`,
  GLOBE_06: `${ASSETS_BASE.URL}/globe-06.svg`,
  GOOGLE_CHROME: `${ASSETS_BASE.URL}/google-chrome.svg`,
  GRADUATION_HAT_01: `${ASSETS_BASE.URL}/graduation-hat-01.svg`,
  GRADUATION_HAT_02: `${ASSETS_BASE.URL}/graduation-hat-02.svg`,
  GRID_01: `${ASSETS_BASE.URL}/grid-01.svg`,
  GRID_02: `${ASSETS_BASE.URL}/grid-02.svg`,
  GRID_03: `${ASSETS_BASE.URL}/grid-03.svg`,
  GRID_DOTS_BLANK: `${ASSETS_BASE.URL}/grid-dots-blank.svg`,
  GRID_DOTS_BOTTOM: `${ASSETS_BASE.URL}/grid-dots-bottom.svg`,
  GRID_DOTS_HORIZONTAL_CENTER: `${ASSETS_BASE.URL}/grid-dots-horizontal-center.svg`,
  GRID_DOTS_LEFT: `${ASSETS_BASE.URL}/grid-dots-left.svg`,
  GRID_DOTS_OUTER: `${ASSETS_BASE.URL}/grid-dots-outer.svg`,
  GRID_DOTS_RIGHT: `${ASSETS_BASE.URL}/grid-dots-right.svg`,
  GRID_DOTS_TOP: `${ASSETS_BASE.URL}/grid-dots-top.svg`,
  GRID_DOTS_VERTICAL_CENTER: `${ASSETS_BASE.URL}/grid-dots-vertical-center.svg`,
  GRID: `${ASSETS_BASE.URL}/grid.svg`,
  HAND: `${ASSETS_BASE.URL}/hand.svg`,
  HARD_DRIVE: `${ASSETS_BASE.URL}/hard-drive.svg`,
  HASH_01: `${ASSETS_BASE.URL}/hash-01.svg`,
  HASH_02: `${ASSETS_BASE.URL}/hash-02.svg`,
  HEADING_01: `${ASSETS_BASE.URL}/heading-01.svg`,
  HEADING_02: `${ASSETS_BASE.URL}/heading-02.svg`,
  HEADING_SQUARE: `${ASSETS_BASE.URL}/heading-square.svg`,
  HEADPHONES_01: `${ASSETS_BASE.URL}/headphones-01.svg`,
  HEADPHONES_02: `${ASSETS_BASE.URL}/headphones-02.svg`,
  HEART_CIRCLE: `${ASSETS_BASE.URL}/heart-circle.svg`,
  HEART_HAND: `${ASSETS_BASE.URL}/heart-hand.svg`,
  HEART_OCTAGON: `${ASSETS_BASE.URL}/heart-octagon.svg`,
  HEART_ROUNDED: `${ASSETS_BASE.URL}/heart-rounded.svg`,
  HEART_SQUARE: `${ASSETS_BASE.URL}/heart-square.svg`,
  HEART: `${ASSETS_BASE.URL}/heart.svg`,
  HEARTS: `${ASSETS_BASE.URL}/hearts.svg`,
  HELP_CIRCLE: `${ASSETS_BASE.URL}/help-circle.svg`,
  HELP_HEXAGON: `${ASSETS_BASE.URL}/help-hexagon.svg`,
  HELP_OCTAGON_1: `${ASSETS_BASE.URL}/help-octagon-1.svg`,
  HELP_OCTAGON: `${ASSETS_BASE.URL}/help-octagon.svg`,
  HELP_SQUARE: `${ASSETS_BASE.URL}/help-square.svg`,
  HEXAGON_01: `${ASSETS_BASE.URL}/hexagon-01.svg`,
  HEXAGON_02: `${ASSETS_BASE.URL}/hexagon-02.svg`,
  HOME_01: `${ASSETS_BASE.URL}/home-01.svg`,
  HOME_02: `${ASSETS_BASE.URL}/home-02.svg`,
  HOME_03: `${ASSETS_BASE.URL}/home-03.svg`,
  HOME_04: `${ASSETS_BASE.URL}/home-04.svg`,
  HOME_05: `${ASSETS_BASE.URL}/home-05.svg`,
  HOME_LINE: `${ASSETS_BASE.URL}/home-line.svg`,
  HOME_SMILE: `${ASSETS_BASE.URL}/home-smile.svg`,
  HORIZONTAL_BAR_CHART_01: `${ASSETS_BASE.URL}/horizontal-bar-chart-01.svg`,
  HORIZONTAL_BAR_CHART_02: `${ASSETS_BASE.URL}/horizontal-bar-chart-02.svg`,
  HORIZONTAL_BAR_CHART_03: `${ASSETS_BASE.URL}/horizontal-bar-chart-03.svg`,
  HOURGLASS_01: `${ASSETS_BASE.URL}/hourglass-01.svg`,
  HOURGLASS_02: `${ASSETS_BASE.URL}/hourglass-02.svg`,
  HOURGLASS_03: `${ASSETS_BASE.URL}/hourglass-03.svg`,
  HURRICANE_01: `${ASSETS_BASE.URL}/hurricane-01.svg`,
  HURRICANE_02: `${ASSETS_BASE.URL}/hurricane-02.svg`,
  HURRICANE_03: `${ASSETS_BASE.URL}/hurricane-03.svg`,
  IMAGE_01: `${ASSETS_BASE.URL}/image-01.svg`,
  IMAGE_02: `${ASSETS_BASE.URL}/image-02.svg`,
  IMAGE_03: `${ASSETS_BASE.URL}/image-03.svg`,
  IMAGE_04: `${ASSETS_BASE.URL}/image-04.svg`,
  IMAGE_05: `${ASSETS_BASE.URL}/image-05.svg`,
  IMAGE_CHECK: `${ASSETS_BASE.URL}/image-check.svg`,
  IMAGE_DOWN: `${ASSETS_BASE.URL}/image-down.svg`,
  IMAGE_INDENT_LEFT: `${ASSETS_BASE.URL}/image-indent-left.svg`,
  IMAGE_INDENT_RIGHT: `${ASSETS_BASE.URL}/image-indent-right.svg`,
  IMAGE_LEFT: `${ASSETS_BASE.URL}/image-left.svg`,
  IMAGE_PLUS: `${ASSETS_BASE.URL}/image-plus.svg`,
  IMAGE_RIGHT: `${ASSETS_BASE.URL}/image-right.svg`,
  IMAGE_UP: `${ASSETS_BASE.URL}/image-up.svg`,
  IMAGE_USER_CHECK: `${ASSETS_BASE.URL}/image-user-check.svg`,
  IMAGE_USER_DOWN: `${ASSETS_BASE.URL}/image-user-down.svg`,
  IMAGE_USER_LEFT: `${ASSETS_BASE.URL}/image-user-left.svg`,
  IMAGE_USER_PLUS: `${ASSETS_BASE.URL}/image-user-plus.svg`,
  IMAGE_USER_RIGHT: `${ASSETS_BASE.URL}/image-user-right.svg`,
  IMAGE_USER_UP: `${ASSETS_BASE.URL}/image-user-up.svg`,
  IMAGE_USER_X: `${ASSETS_BASE.URL}/image-user-x.svg`,
  IMAGE_USER: `${ASSETS_BASE.URL}/image-user.svg`,
  IMAGE_X: `${ASSETS_BASE.URL}/image-x.svg`,
  INBOX_01: `${ASSETS_BASE.URL}/inbox-01.svg`,
  INBOX_02: `${ASSETS_BASE.URL}/inbox-02.svg`,
  INFINITY: `${ASSETS_BASE.URL}/infinity.svg`,
  INFO_CIRCLE: `${ASSETS_BASE.URL}/info-circle.svg`,
  INFO_HEXAGON: `${ASSETS_BASE.URL}/info-hexagon.svg`,
  INFO_OCTAGON: `${ASSETS_BASE.URL}/info-octagon.svg`,
  INFO_SQUARE: `${ASSETS_BASE.URL}/info-square.svg`,
  INTERSECT_CIRCLE: `${ASSETS_BASE.URL}/intersect-circle.svg`,
  INTERSECT_SQUARE: `${ASSETS_BASE.URL}/intersect-square.svg`,
  ITALIC_01: `${ASSETS_BASE.URL}/italic-01.svg`,
  ITALIC_02: `${ASSETS_BASE.URL}/italic-02.svg`,
  ITALIC_SQUARE: `${ASSETS_BASE.URL}/italic-square.svg`,
  KEY_01: `${ASSETS_BASE.URL}/key-01.svg`,
  KEY_02: `${ASSETS_BASE.URL}/key-02.svg`,
  KEYBOARD_01: `${ASSETS_BASE.URL}/keyboard-01.svg`,
  KEYBOARD_02: `${ASSETS_BASE.URL}/keyboard-02.svg`,
  LAPTOP_01: `${ASSETS_BASE.URL}/laptop-01.svg`,
  LAPTOP_02: `${ASSETS_BASE.URL}/laptop-02.svg`,
  LAYER_SINGLE: `${ASSETS_BASE.URL}/layer-single.svg`,
  LAYERS_THREE_01: `${ASSETS_BASE.URL}/layers-three-01.svg`,
  LAYERS_THREE_02: `${ASSETS_BASE.URL}/layers-three-02.svg`,
  LAYERS_TWO_01: `${ASSETS_BASE.URL}/layers-two-01.svg`,
  LAYERS_TWO_02: `${ASSETS_BASE.URL}/layers-two-02.svg`,
  LAYOUT_ALT_01: `${ASSETS_BASE.URL}/layout-alt-01.svg`,
  LAYOUT_ALT_02: `${ASSETS_BASE.URL}/layout-alt-02.svg`,
  LAYOUT_ALT_03: `${ASSETS_BASE.URL}/layout-alt-03.svg`,
  LAYOUT_ALT_04: `${ASSETS_BASE.URL}/layout-alt-04.svg`,
  LAYOUT_BOTTOM: `${ASSETS_BASE.URL}/layout-bottom.svg`,
  LAYOUT_GRID_01: `${ASSETS_BASE.URL}/layout-grid-01.svg`,
  LAYOUT_GRID_02: `${ASSETS_BASE.URL}/layout-grid-02.svg`,
  LAYOUT_LEFT: `${ASSETS_BASE.URL}/layout-left.svg`,
  LAYOUT_RIGHT: `${ASSETS_BASE.URL}/layout-right.svg`,
  LAYOUT_TOP: `${ASSETS_BASE.URL}/layout-top.svg`,
  LEFT_INDENT_01: `${ASSETS_BASE.URL}/left-indent-01.svg`,
  LEFT_INDENT_02: `${ASSETS_BASE.URL}/left-indent-02.svg`,
  LETTER_SPACING_01: `${ASSETS_BASE.URL}/letter-spacing-01.svg`,
  LETTER_SPACING_02: `${ASSETS_BASE.URL}/letter-spacing-02.svg`,
  LIFE_BUOY_01: `${ASSETS_BASE.URL}/life-buoy-01.svg`,
  LIFE_BUOY_02: `${ASSETS_BASE.URL}/life-buoy-02.svg`,
  LIGHTBULB_01: `${ASSETS_BASE.URL}/lightbulb-01.svg`,
  LIGHTBULB_02: `${ASSETS_BASE.URL}/lightbulb-02.svg`,
  LIGHTBULB_03: `${ASSETS_BASE.URL}/lightbulb-03.svg`,
  LIGHTBULB_04: `${ASSETS_BASE.URL}/lightbulb-04.svg`,
  LIGHTBULB_05: `${ASSETS_BASE.URL}/lightbulb-05.svg`,
  LIGHTNING_01: `${ASSETS_BASE.URL}/lightning-01.svg`,
  LIGHTNING_02: `${ASSETS_BASE.URL}/lightning-02.svg`,
  LINE_CHART_DOWN_01: `${ASSETS_BASE.URL}/line-chart-down-01.svg`,
  LINE_CHART_DOWN_02: `${ASSETS_BASE.URL}/line-chart-down-02.svg`,
  LINE_CHART_DOWN_03: `${ASSETS_BASE.URL}/line-chart-down-03.svg`,
  LINE_CHART_DOWN_04: `${ASSETS_BASE.URL}/line-chart-down-04.svg`,
  LINE_CHART_DOWN_05: `${ASSETS_BASE.URL}/line-chart-down-05.svg`,
  LINE_CHART_UP_01: `${ASSETS_BASE.URL}/line-chart-up-01.svg`,
  LINE_CHART_UP_02: `${ASSETS_BASE.URL}/line-chart-up-02.svg`,
  LINE_CHART_UP_03: `${ASSETS_BASE.URL}/line-chart-up-03.svg`,
  LINE_CHART_UP_04: `${ASSETS_BASE.URL}/line-chart-up-04.svg`,
  LINE_CHART_UP_05: `${ASSETS_BASE.URL}/line-chart-up-05.svg`,
  LINE_HEIGHT: `${ASSETS_BASE.URL}/line-height.svg`,
  LINK_01: `${ASSETS_BASE.URL}/link-01.svg`,
  LINK_02: `${ASSETS_BASE.URL}/link-02.svg`,
  LINK_03: `${ASSETS_BASE.URL}/link-03.svg`,
  LINK_04: `${ASSETS_BASE.URL}/link-04.svg`,
  LINK_05: `${ASSETS_BASE.URL}/link-05.svg`,
  LINK_BROKEN_01: `${ASSETS_BASE.URL}/link-broken-01.svg`,
  LINK_BROKEN_02: `${ASSETS_BASE.URL}/link-broken-02.svg`,
  LINK_EXTERNAL_01: `${ASSETS_BASE.URL}/link-external-01.svg`,
  LINK_EXTERNAL_02: `${ASSETS_BASE.URL}/link-external-02.svg`,
  LIST: `${ASSETS_BASE.URL}/list.svg`,
  LOADING_01: `${ASSETS_BASE.URL}/loading-01.svg`,
  LOADING_02: `${ASSETS_BASE.URL}/loading-02.svg`,
  LOADING_03: `${ASSETS_BASE.URL}/loading-03.svg`,
  LOCK_01: `${ASSETS_BASE.URL}/lock-01.svg`,
  LOCK_02: `${ASSETS_BASE.URL}/lock-02.svg`,
  LOCK_03: `${ASSETS_BASE.URL}/lock-03.svg`,
  LOCK_04: `${ASSETS_BASE.URL}/lock-04.svg`,
  LOCK_KEYHOLE_CIRCLE: `${ASSETS_BASE.URL}/lock-keyhole-circle.svg`,
  LOCK_KEYHOLE_SQUARE: `${ASSETS_BASE.URL}/lock-keyhole-square.svg`,
  LOCK_UNLOCKED_01: `${ASSETS_BASE.URL}/lock-unlocked-01.svg`,
  LOCK_UNLOCKED_02: `${ASSETS_BASE.URL}/lock-unlocked-02.svg`,
  LOCK_UNLOCKED_03: `${ASSETS_BASE.URL}/lock-unlocked-03.svg`,
  LOCK_UNLOCKED_04: `${ASSETS_BASE.URL}/lock-unlocked-04.svg`,
  LOG_IN_01: `${ASSETS_BASE.URL}/log-in-01.svg`,
  LOG_IN_02: `${ASSETS_BASE.URL}/log-in-02.svg`,
  LOG_IN_03: `${ASSETS_BASE.URL}/log-in-03.svg`,
  LOG_IN_04: `${ASSETS_BASE.URL}/log-in-04.svg`,
  LOG_OUT_01: `${ASSETS_BASE.URL}/log-out-01.svg`,
  LOG_OUT_02: `${ASSETS_BASE.URL}/log-out-02.svg`,
  LOG_OUT_03: `${ASSETS_BASE.URL}/log-out-03.svg`,
  LOG_OUT_04: `${ASSETS_BASE.URL}/log-out-04.svg`,
  LUGGAGE_01: `${ASSETS_BASE.URL}/luggage-01.svg`,
  LUGGAGE_02: `${ASSETS_BASE.URL}/luggage-02.svg`,
  LUGGAGE_03: `${ASSETS_BASE.URL}/luggage-03.svg`,
  MAGIC_WAND_01: `${ASSETS_BASE.URL}/magic-wand-01.svg`,
  MAGIC_WAND_02: `${ASSETS_BASE.URL}/magic-wand-02.svg`,
  MAIL_01: `${ASSETS_BASE.URL}/mail-01.svg`,
  MAIL_02: `${ASSETS_BASE.URL}/mail-02.svg`,
  MAIL_03: `${ASSETS_BASE.URL}/mail-03.svg`,
  MAIL_04: `${ASSETS_BASE.URL}/mail-04.svg`,
  MAIL_05: `${ASSETS_BASE.URL}/mail-05.svg`,
  MAP_01: `${ASSETS_BASE.URL}/map-01.svg`,
  MAP_02: `${ASSETS_BASE.URL}/map-02.svg`,
  MARK: `${ASSETS_BASE.URL}/mark.svg`,
  MARKER_PIN_01: `${ASSETS_BASE.URL}/marker-pin-01.svg`,
  MARKER_PIN_02: `${ASSETS_BASE.URL}/marker-pin-02.svg`,
  MARKER_PIN_03: `${ASSETS_BASE.URL}/marker-pin-03.svg`,
  MARKER_PIN_04: `${ASSETS_BASE.URL}/marker-pin-04.svg`,
  MARKER_PIN_05: `${ASSETS_BASE.URL}/marker-pin-05.svg`,
  MARKER_PIN_06: `${ASSETS_BASE.URL}/marker-pin-06.svg`,
  MAXIMIZE_01: `${ASSETS_BASE.URL}/maximize-01.svg`,
  MAXIMIZE_02: `${ASSETS_BASE.URL}/maximize-02.svg`,
  MEDICAL_CIRCLE: `${ASSETS_BASE.URL}/medical-circle.svg`,
  MEDICAL_CROSS: `${ASSETS_BASE.URL}/medical-cross.svg`,
  MEDICAL_SQUARE: `${ASSETS_BASE.URL}/medical-square.svg`,
  MENU_01: `${ASSETS_BASE.URL}/menu-01.svg`,
  MENU_02: `${ASSETS_BASE.URL}/menu-02.svg`,
  MENU_03: `${ASSETS_BASE.URL}/menu-03.svg`,
  MENU_04: `${ASSETS_BASE.URL}/menu-04.svg`,
  MENU_05: `${ASSETS_BASE.URL}/menu-05.svg`,
  MESSAGE_ALERT_CIRCLE: `${ASSETS_BASE.URL}/message-alert-circle.svg`,
  MESSAGE_ALERT_SQUARE: `${ASSETS_BASE.URL}/message-alert-square.svg`,
  MESSAGE_CHAT_CIRCLE: `${ASSETS_BASE.URL}/message-chat-circle.svg`,
  MESSAGE_CHAT_SQUARE: `${ASSETS_BASE.URL}/message-chat-square.svg`,
  MESSAGE_CHECK_CIRCLE: `${ASSETS_BASE.URL}/message-check-circle.svg`,
  MESSAGE_CHECK_SQUARE: `${ASSETS_BASE.URL}/message-check-square.svg`,
  MESSAGE_CIRCLE_01: `${ASSETS_BASE.URL}/message-circle-01.svg`,
  MESSAGE_CIRCLE_02: `${ASSETS_BASE.URL}/message-circle-02.svg`,
  MESSAGE_DOTS_CIRCLE: `${ASSETS_BASE.URL}/message-dots-circle.svg`,
  MESSAGE_DOTS_SQUARE: `${ASSETS_BASE.URL}/message-dots-square.svg`,
  MESSAGE_HEART_CIRCLE: `${ASSETS_BASE.URL}/message-heart-circle.svg`,
  MESSAGE_HEART_SQUARE: `${ASSETS_BASE.URL}/message-heart-square.svg`,
  MESSAGE_NOTIFICATION_CIRCLE: `${ASSETS_BASE.URL}/message-notification-circle.svg`,
  MESSAGE_NOTIFICATION_SQUARE_ALERTS: `${ASSETS_BASE.URL}/message-notification-square-alerts.svg`,
  MESSAGE_NOTIFICATION_SQUARE: `${ASSETS_BASE.URL}/message-notification-square.svg`,
  MESSAGE_PLUS_CIRCLE: `${ASSETS_BASE.URL}/message-plus-circle.svg`,
  MESSAGE_PLUS_SQUARE: `${ASSETS_BASE.URL}/message-plus-square.svg`,
  MESSAGE_QUESTION_CIRCLE: `${ASSETS_BASE.URL}/message-question-circle.svg`,
  MESSAGE_QUESTION_SQUARE: `${ASSETS_BASE.URL}/message-question-square.svg`,
  MESSAGE_SMILE_CIRCLE: `${ASSETS_BASE.URL}/message-smile-circle.svg`,
  MESSAGE_SMILE_SQUARE: `${ASSETS_BASE.URL}/message-smile-square.svg`,
  MESSAGE_SQUARE_01: `${ASSETS_BASE.URL}/message-square-01.svg`,
  MESSAGE_SQUARE_02: `${ASSETS_BASE.URL}/message-square-02.svg`,
  MESSAGE_TEXT_CIRCLE_01: `${ASSETS_BASE.URL}/message-text-circle-01.svg`,
  MESSAGE_TEXT_CIRCLE_02: `${ASSETS_BASE.URL}/message-text-circle-02.svg`,
  MESSAGE_TEXT_SQUARE_01: `${ASSETS_BASE.URL}/message-text-square-01.svg`,
  MESSAGE_TEXT_SQUARE_02: `${ASSETS_BASE.URL}/message-text-square-02.svg`,
  MESSAGE_X_CIRCLE: `${ASSETS_BASE.URL}/message-x-circle.svg`,
  MESSAGE_X_SQUARE: `${ASSETS_BASE.URL}/message-x-square.svg`,
  MICROPHONE_01: `${ASSETS_BASE.URL}/microphone-01.svg`,
  MICROPHONE_02: `${ASSETS_BASE.URL}/microphone-02.svg`,
  MICROPHONE_OFF_01: `${ASSETS_BASE.URL}/microphone-off-01.svg`,
  MICROPHONE_OFF_02: `${ASSETS_BASE.URL}/microphone-off-02.svg`,
  MICROSCOPE: `${ASSETS_BASE.URL}/microscope.svg`,
  MINIMIZE_01: `${ASSETS_BASE.URL}/minimize-01.svg`,
  MINIMIZE_02: `${ASSETS_BASE.URL}/minimize-02.svg`,
  MINUS_CIRCLE: `${ASSETS_BASE.URL}/minus-circle.svg`,
  MINUS_SQUARE: `${ASSETS_BASE.URL}/minus-square.svg`,
  MINUS: `${ASSETS_BASE.URL}/minus.svg`,
  MODEM_01: `${ASSETS_BASE.URL}/modem-01.svg`,
  MODEM_02: `${ASSETS_BASE.URL}/modem-02.svg`,
  MONITOR_01: `${ASSETS_BASE.URL}/monitor-01.svg`,
  MONITOR_02: `${ASSETS_BASE.URL}/monitor-02.svg`,
  MONITOR_03: `${ASSETS_BASE.URL}/monitor-03.svg`,
  MONITOR_04: `${ASSETS_BASE.URL}/monitor-04.svg`,
  MONITOR_05: `${ASSETS_BASE.URL}/monitor-05.svg`,
  MOON_01: `${ASSETS_BASE.URL}/moon-01.svg`,
  MOON_02: `${ASSETS_BASE.URL}/moon-02.svg`,
  MOON_ECLIPSE: `${ASSETS_BASE.URL}/moon-eclipse.svg`,
  MOON_STAR: `${ASSETS_BASE.URL}/moon-star.svg`,
  MOUSE: `${ASSETS_BASE.URL}/mouse.svg`,
  MOVE: `${ASSETS_BASE.URL}/move.svg`,
  MUSIC_NOTE_01: `${ASSETS_BASE.URL}/music-note-01.svg`,
  MUSIC_NOTE_02: `${ASSETS_BASE.URL}/music-note-02.svg`,
  MUSIC: `${ASSETS_BASE.URL}/music.svg`,
  NAVIGATION_POINTER_01: `${ASSETS_BASE.URL}/navigation-pointer-01.svg`,
  NAVIGATION_POINTER_02: `${ASSETS_BASE.URL}/navigation-pointer-02.svg`,
  NAVIGATION_POINTER_OFF_01: `${ASSETS_BASE.URL}/navigation-pointer-off-01.svg`,
  NAVIGATION_POINTER_OFF_02: `${ASSETS_BASE.URL}/navigation-pointer-off-02.svg`,
  NOTIFICATION_BOX: `${ASSETS_BASE.URL}/notification-box.svg`,
  NOTIFICATION_TEXT: `${ASSETS_BASE.URL}/notification-text.svg`,
  OCTAGON: `${ASSETS_BASE.URL}/octagon.svg`,
  PACKAGE_CHECK: `${ASSETS_BASE.URL}/package-check.svg`,
  PACKAGE_MINUS: `${ASSETS_BASE.URL}/package-minus.svg`,
  PACKAGE_PLUS: `${ASSETS_BASE.URL}/package-plus.svg`,
  PACKAGE_SEARCH: `${ASSETS_BASE.URL}/package-search.svg`,
  PACKAGE_X: `${ASSETS_BASE.URL}/package-x.svg`,
  PACKAGE: `${ASSETS_BASE.URL}/package.svg`,
  PAINT_POUR: `${ASSETS_BASE.URL}/paint-pour.svg`,
  PAINT: `${ASSETS_BASE.URL}/paint.svg`,
  PALETTE: `${ASSETS_BASE.URL}/palette.svg`,
  PAPERCLIP: `${ASSETS_BASE.URL}/paperclip.svg`,
  PARAGRAPH_SPACING: `${ASSETS_BASE.URL}/paragraph-spacing.svg`,
  PARAGRAPH_WRAP: `${ASSETS_BASE.URL}/paragraph-wrap.svg`,
  PASSCODE_LOCK: `${ASSETS_BASE.URL}/passcode-lock.svg`,
  PASSCODE: `${ASSETS_BASE.URL}/passcode.svg`,
  PASSPORT: `${ASSETS_BASE.URL}/passport.svg`,
  PAUSE_CIRCLE: `${ASSETS_BASE.URL}/pause-circle.svg`,
  PAUSE_SQUARE: `${ASSETS_BASE.URL}/pause-square.svg`,
  PEN_TOOL_01: `${ASSETS_BASE.URL}/pen-tool-01.svg`,
  PEN_TOOL_02: `${ASSETS_BASE.URL}/pen-tool-02.svg`,
  PEN_TOOL_MINUS: `${ASSETS_BASE.URL}/pen-tool-minus.svg`,
  PEN_TOOL_PLUS: `${ASSETS_BASE.URL}/pen-tool-plus.svg`,
  PENCIL_01: `${ASSETS_BASE.URL}/pencil-01.svg`,
  PENCIL_02: `${ASSETS_BASE.URL}/pencil-02.svg`,
  PENCIL_LINE: `${ASSETS_BASE.URL}/pencil-line.svg`,
  PENTAGON: `${ASSETS_BASE.URL}/pentagon.svg`,
  PERCENT_01: `${ASSETS_BASE.URL}/percent-01.svg`,
  PERCENT_02: `${ASSETS_BASE.URL}/percent-02.svg`,
  PERCENT_03: `${ASSETS_BASE.URL}/percent-03.svg`,
  PERSPECTIVE_01: `${ASSETS_BASE.URL}/perspective-01.svg`,
  PERSPECTIVE_02: `${ASSETS_BASE.URL}/perspective-02.svg`,
  PHONE_01: `${ASSETS_BASE.URL}/phone-01.svg`,
  PHONE_02: `${ASSETS_BASE.URL}/phone-02.svg`,
  PHONE_CALL_01: `${ASSETS_BASE.URL}/phone-call-01.svg`,
  PHONE_CALL_02: `${ASSETS_BASE.URL}/phone-call-02.svg`,
  PHONE_HANG_UP: `${ASSETS_BASE.URL}/phone-hang-up.svg`,
  PHONE_INCOMING_01: `${ASSETS_BASE.URL}/phone-incoming-01.svg`,
  PHONE_INCOMING_02: `${ASSETS_BASE.URL}/phone-incoming-02.svg`,
  PHONE_OUTGOING_01: `${ASSETS_BASE.URL}/phone-outgoing-01.svg`,
  PHONE_OUTGOING_02: `${ASSETS_BASE.URL}/phone-outgoing-02.svg`,
  PHONE_PAUSE: `${ASSETS_BASE.URL}/phone-pause.svg`,
  PHONE_PLUS: `${ASSETS_BASE.URL}/phone-plus.svg`,
  PHONE_X: `${ASSETS_BASE.URL}/phone-x.svg`,
  PHONE: `${ASSETS_BASE.URL}/phone.svg`,
  PIE_CHART_01: `${ASSETS_BASE.URL}/pie-chart-01.svg`,
  PIE_CHART_02: `${ASSETS_BASE.URL}/pie-chart-02.svg`,
  PIE_CHART_03: `${ASSETS_BASE.URL}/pie-chart-03.svg`,
  PIE_CHART_04: `${ASSETS_BASE.URL}/pie-chart-04.svg`,
  PIGGY_BANK_01: `${ASSETS_BASE.URL}/piggy-bank-01.svg`,
  PIGGY_BANK_02: `${ASSETS_BASE.URL}/piggy-bank-02.svg`,
  PILCROW_01: `${ASSETS_BASE.URL}/pilcrow-01.svg`,
  PILCROW_02: `${ASSETS_BASE.URL}/pilcrow-02.svg`,
  PILCROW_SQUARE: `${ASSETS_BASE.URL}/pilcrow-square.svg`,
  PIN_01: `${ASSETS_BASE.URL}/pin-01.svg`,
  PIN_02: `${ASSETS_BASE.URL}/pin-02.svg`,
  PLACEHOLDER: `${ASSETS_BASE.URL}/placeholder.svg`,
  PLANE: `${ASSETS_BASE.URL}/plane.svg`,
  PLAY_CIRCLE: `${ASSETS_BASE.URL}/play-circle.svg`,
  PLAY_SQUARE: `${ASSETS_BASE.URL}/play-square.svg`,
  PLAY: `${ASSETS_BASE.URL}/play.svg`,
  PLUS_CIRCLE: `${ASSETS_BASE.URL}/plus-circle.svg`,
  PLUS_SQUARE: `${ASSETS_BASE.URL}/plus-square.svg`,
  PLUS: `${ASSETS_BASE.URL}/plus.svg`,
  PODCAST: `${ASSETS_BASE.URL}/podcast.svg`,
  POWER_01: `${ASSETS_BASE.URL}/power-01.svg`,
  POWER_02: `${ASSETS_BASE.URL}/power-02.svg`,
  POWER_03: `${ASSETS_BASE.URL}/power-03.svg`,
  PRESENTATION_CHART_01: `${ASSETS_BASE.URL}/presentation-chart-01.svg`,
  PRESENTATION_CHART_02: `${ASSETS_BASE.URL}/presentation-chart-02.svg`,
  PRESENTATION_CHART_03: `${ASSETS_BASE.URL}/presentation-chart-03.svg`,
  PRINTER: `${ASSETS_BASE.URL}/printer.svg`,
  PUZZLE_PIECE_02: `${ASSETS_BASE.URL}/puzzle-piece-02.svg`,
  PUZZLE_PIECE: `${ASSETS_BASE.URL}/puzzle-piece.svg`,
  QR_CODE_01: `${ASSETS_BASE.URL}/qr-code-01.svg`,
  QR_CODE_02: `${ASSETS_BASE.URL}/qr-code-02.svg`,
  RECEIPT_CHECK: `${ASSETS_BASE.URL}/receipt-check.svg`,
  RECEIPT: `${ASSETS_BASE.URL}/receipt.svg`,
  RECORDING_01: `${ASSETS_BASE.URL}/recording-01.svg`,
  RECORDING_02: `${ASSETS_BASE.URL}/recording-02.svg`,
  RECORDING_03: `${ASSETS_BASE.URL}/recording-03.svg`,
  REFLECT_01: `${ASSETS_BASE.URL}/reflect-01.svg`,
  REFLECT_02: `${ASSETS_BASE.URL}/reflect-02.svg`,
  REFRESH_CCW_01: `${ASSETS_BASE.URL}/refresh-ccw-01.svg`,
  REFRESH_CCW_02: `${ASSETS_BASE.URL}/refresh-ccw-02.svg`,
  REFRESH_CCW_03: `${ASSETS_BASE.URL}/refresh-ccw-03.svg`,
  REFRESH_CCW_04: `${ASSETS_BASE.URL}/refresh-ccw-04.svg`,
  REFRESH_CCW_05: `${ASSETS_BASE.URL}/refresh-ccw-05.svg`,
  REFRESH_CW_01: `${ASSETS_BASE.URL}/refresh-cw-01.svg`,
  REFRESH_CW_02: `${ASSETS_BASE.URL}/refresh-cw-02.svg`,
  REFRESH_CW_03: `${ASSETS_BASE.URL}/refresh-cw-03.svg`,
  REFRESH_CW_04: `${ASSETS_BASE.URL}/refresh-cw-04.svg`,
  REFRESH_CW_05: `${ASSETS_BASE.URL}/refresh-cw-05.svg`,
  REPEAT_01: `${ASSETS_BASE.URL}/repeat-01.svg`,
  REPEAT_02: `${ASSETS_BASE.URL}/repeat-02.svg`,
  REPEAT_03: `${ASSETS_BASE.URL}/repeat-03.svg`,
  REPEAT_04: `${ASSETS_BASE.URL}/repeat-04.svg`,
  REVERSE_LEFT: `${ASSETS_BASE.URL}/reverse-left.svg`,
  REVERSE_RIGHT: `${ASSETS_BASE.URL}/reverse-right.svg`,
  RIGHT_INDENT_01: `${ASSETS_BASE.URL}/right-indent-01.svg`,
  RIGHT_INDENT_02: `${ASSETS_BASE.URL}/right-indent-02.svg`,
  ROCKET_01: `${ASSETS_BASE.URL}/rocket-01.svg`,
  ROCKET_02: `${ASSETS_BASE.URL}/rocket-02.svg`,
  ROLLER_BRUSH: `${ASSETS_BASE.URL}/roller-brush.svg`,
  ROUTE: `${ASSETS_BASE.URL}/route.svg`,
  ROWS_01: `${ASSETS_BASE.URL}/rows-01.svg`,
  ROWS_02: `${ASSETS_BASE.URL}/rows-02.svg`,
  ROWS_03: `${ASSETS_BASE.URL}/rows-03.svg`,
  RSS_01: `${ASSETS_BASE.URL}/rss-01.svg`,
  RSS_02: `${ASSETS_BASE.URL}/rss-02.svg`,
  RULER: `${ASSETS_BASE.URL}/ruler.svg`,
  SAFE: `${ASSETS_BASE.URL}/safe.svg`,
  SALE_01: `${ASSETS_BASE.URL}/sale-01.svg`,
  SALE_02: `${ASSETS_BASE.URL}/sale-02.svg`,
  SALE_03: `${ASSETS_BASE.URL}/sale-03.svg`,
  SALE_04: `${ASSETS_BASE.URL}/sale-04.svg`,
  SAVE_01: `${ASSETS_BASE.URL}/save-01.svg`,
  SAVE_02: `${ASSETS_BASE.URL}/save-02.svg`,
  SAVE_03: `${ASSETS_BASE.URL}/save-03.svg`,
  SCALE_01: `${ASSETS_BASE.URL}/scale-01.svg`,
  SCALE_02: `${ASSETS_BASE.URL}/scale-02.svg`,
  SCALE_03: `${ASSETS_BASE.URL}/scale-03.svg`,
  SCALES_01: `${ASSETS_BASE.URL}/scales-01.svg`,
  SCALES_02: `${ASSETS_BASE.URL}/scales-02.svg`,
  SCAN: `${ASSETS_BASE.URL}/scan.svg`,
  SCISSORS_01: `${ASSETS_BASE.URL}/scissors-01.svg`,
  SCISSORS_02: `${ASSETS_BASE.URL}/scissors-02.svg`,
  SCISSORS_CUT_01: `${ASSETS_BASE.URL}/scissors-cut-01.svg`,
  SCISSORS_CUT_02: `${ASSETS_BASE.URL}/scissors-cut-02.svg`,
  SEARCH_LG: `${ASSETS_BASE.URL}/search-lg.svg`,
  SEARCH_MD: `${ASSETS_BASE.URL}/search-md.svg`,
  SEARCH_REFRACTION: `${ASSETS_BASE.URL}/search-refraction.svg`,
  SEARCH_SM: `${ASSETS_BASE.URL}/search-sm.svg`,
  SEND_01: `${ASSETS_BASE.URL}/send-01.svg`,
  SEND_02: `${ASSETS_BASE.URL}/send-02.svg`,
  SEND_03: `${ASSETS_BASE.URL}/send-03.svg`,
  SERVER_01: `${ASSETS_BASE.URL}/server-01.svg`,
  SERVER_02: `${ASSETS_BASE.URL}/server-02.svg`,
  SERVER_03: `${ASSETS_BASE.URL}/server-03.svg`,
  SERVER_04: `${ASSETS_BASE.URL}/server-04.svg`,
  SERVER_05: `${ASSETS_BASE.URL}/server-05.svg`,
  SERVER_06: `${ASSETS_BASE.URL}/server-06.svg`,
  SETTINGS_01: `${ASSETS_BASE.URL}/settings-01.svg`,
  SETTINGS_02: `${ASSETS_BASE.URL}/settings-02.svg`,
  SETTINGS_03: `${ASSETS_BASE.URL}/settings-03.svg`,
  SETTINGS_04: `${ASSETS_BASE.URL}/settings-04.svg`,
  SHARE_01: `${ASSETS_BASE.URL}/share-01.svg`,
  SHARE_02: `${ASSETS_BASE.URL}/share-02.svg`,
  SHARE_03: `${ASSETS_BASE.URL}/share-03.svg`,
  SHARE_04: `${ASSETS_BASE.URL}/share-04.svg`,
  SHARE_05: `${ASSETS_BASE.URL}/share-05.svg`,
  SHARE_06: `${ASSETS_BASE.URL}/share-06.svg`,
  SHARE_07: `${ASSETS_BASE.URL}/share-07.svg`,
  SHIELD_01: `${ASSETS_BASE.URL}/shield-01.svg`,
  SHIELD_02: `${ASSETS_BASE.URL}/shield-02.svg`,
  SHIELD_03: `${ASSETS_BASE.URL}/shield-03.svg`,
  SHIELD_DOLLAR: `${ASSETS_BASE.URL}/shield-dollar.svg`,
  SHIELD_OFF: `${ASSETS_BASE.URL}/shield-off.svg`,
  SHIELD_PLUS: `${ASSETS_BASE.URL}/shield-plus.svg`,
  SHIELD_TICK: `${ASSETS_BASE.URL}/shield-tick.svg`,
  SHIELD_ZAP: `${ASSETS_BASE.URL}/shield-zap.svg`,
  SHOPPING_BAG_01: `${ASSETS_BASE.URL}/shopping-bag-01.svg`,
  SHOPPING_BAG_02: `${ASSETS_BASE.URL}/shopping-bag-02.svg`,
  SHOPPING_BAG_03: `${ASSETS_BASE.URL}/shopping-bag-03.svg`,
  SHOPPING_CART_01: `${ASSETS_BASE.URL}/shopping-cart-01.svg`,
  SHOPPING_CART_02: `${ASSETS_BASE.URL}/shopping-cart-02.svg`,
  SHOPPING_CART_03: `${ASSETS_BASE.URL}/shopping-cart-03.svg`,
  SHUFFLE_01: `${ASSETS_BASE.URL}/shuffle-01.svg`,
  SHUFFLE_02: `${ASSETS_BASE.URL}/shuffle-02.svg`,
  SIGNAL_01: `${ASSETS_BASE.URL}/signal-01.svg`,
  SIGNAL_02: `${ASSETS_BASE.URL}/signal-02.svg`,
  SIGNAL_03: `${ASSETS_BASE.URL}/signal-03.svg`,
  SIMCARD: `${ASSETS_BASE.URL}/simcard.svg`,
  SKEW: `${ASSETS_BASE.URL}/skew.svg`,
  SKIP_BACK: `${ASSETS_BASE.URL}/skip-back.svg`,
  SKIP_FORWARD: `${ASSETS_BASE.URL}/skip-forward.svg`,
  SLASH_CIRCLE_01: `${ASSETS_BASE.URL}/slash-circle-01.svg`,
  SLASH_CIRCLE_02: `${ASSETS_BASE.URL}/slash-circle-02.svg`,
  SLASH_DIVIDER: `${ASSETS_BASE.URL}/slash-divider.svg`,
  SLASH_OCTAGON: `${ASSETS_BASE.URL}/slash-octagon.svg`,
  SLIDERS_01: `${ASSETS_BASE.URL}/sliders-01.svg`,
  SLIDERS_02: `${ASSETS_BASE.URL}/sliders-02.svg`,
  SLIDERS_03: `${ASSETS_BASE.URL}/sliders-03.svg`,
  SLIDERS_04: `${ASSETS_BASE.URL}/sliders-04.svg`,
  SNOWFLAKE_01: `${ASSETS_BASE.URL}/snowflake-01.svg`,
  SNOWFLAKE_02: `${ASSETS_BASE.URL}/snowflake-02.svg`,
  SPACING_HEIGHT_01: `${ASSETS_BASE.URL}/spacing-height-01.svg`,
  SPACING_HEIGHT_02: `${ASSETS_BASE.URL}/spacing-height-02.svg`,
  SPACING_WIDTH_01: `${ASSETS_BASE.URL}/spacing-width-01.svg`,
  SPACING_WIDTH_02: `${ASSETS_BASE.URL}/spacing-width-02.svg`,
  SPEAKER_01: `${ASSETS_BASE.URL}/speaker-01.svg`,
  SPEAKER_02: `${ASSETS_BASE.URL}/speaker-02.svg`,
  SPEAKER_03: `${ASSETS_BASE.URL}/speaker-03.svg`,
  SPEEDOMETER_01: `${ASSETS_BASE.URL}/speedometer-01.svg`,
  SPEEDOMETER_02: `${ASSETS_BASE.URL}/speedometer-02.svg`,
  SPEEDOMETER_03: `${ASSETS_BASE.URL}/speedometer-03.svg`,
  SPEEDOMETER_04: `${ASSETS_BASE.URL}/speedometer-04.svg`,
  SQUARE: `${ASSETS_BASE.URL}/square.svg`,
  STAND: `${ASSETS_BASE.URL}/stand.svg`,
  STAR_01: `${ASSETS_BASE.URL}/star-01.svg`,
  STAR_02: `${ASSETS_BASE.URL}/star-02.svg`,
  STAR_03: `${ASSETS_BASE.URL}/star-03.svg`,
  STAR_04: `${ASSETS_BASE.URL}/star-04.svg`,
  STAR_05: `${ASSETS_BASE.URL}/star-05.svg`,
  STAR_06: `${ASSETS_BASE.URL}/star-06.svg`,
  STAR_07: `${ASSETS_BASE.URL}/star-07.svg`,
  STARS_01: `${ASSETS_BASE.URL}/stars-01.svg`,
  STARS_02: `${ASSETS_BASE.URL}/stars-02.svg`,
  STARS_03: `${ASSETS_BASE.URL}/stars-03.svg`,
  STICKER_CIRCLE: `${ASSETS_BASE.URL}/sticker-circle.svg`,
  STICKER_SQUARE: `${ASSETS_BASE.URL}/sticker-square.svg`,
  STOP_CIRCLE: `${ASSETS_BASE.URL}/stop-circle.svg`,
  STOP_SQUARE: `${ASSETS_BASE.URL}/stop-square.svg`,
  STOP: `${ASSETS_BASE.URL}/stop.svg`,
  STRIKETHROUGH_01: `${ASSETS_BASE.URL}/strikethrough-01.svg`,
  STRIKETHROUGH_02: `${ASSETS_BASE.URL}/strikethrough-02.svg`,
  STRIKETHROUGH_SQUARE: `${ASSETS_BASE.URL}/strikethrough-square.svg`,
  SUBSCRIPT: `${ASSETS_BASE.URL}/subscript.svg`,
  SUM: `${ASSETS_BASE.URL}/sum.svg`,
  SUN_SETTING_01: `${ASSETS_BASE.URL}/sun-setting-01.svg`,
  SUN_SETTING_02: `${ASSETS_BASE.URL}/sun-setting-02.svg`,
  SUN_SETTING_03: `${ASSETS_BASE.URL}/sun-setting-03.svg`,
  SUN: `${ASSETS_BASE.URL}/sun.svg`,
  SUNRISE: `${ASSETS_BASE.URL}/sunrise.svg`,
  SUNSET: `${ASSETS_BASE.URL}/sunset.svg`,
  SWITCH_HORIZONTAL_01: `${ASSETS_BASE.URL}/switch-horizontal-01.svg`,
  SWITCH_HORIZONTAL_02: `${ASSETS_BASE.URL}/switch-horizontal-02.svg`,
  SWITCH_VERTICAL_01: `${ASSETS_BASE.URL}/switch-vertical-01.svg`,
  SWITCH_VERTICAL_02: `${ASSETS_BASE.URL}/switch-vertical-02.svg`,
  TABLE: `${ASSETS_BASE.URL}/table.svg`,
  TABLET_01: `${ASSETS_BASE.URL}/tablet-01.svg`,
  TABLET_02: `${ASSETS_BASE.URL}/tablet-02.svg`,
  TAG_01: `${ASSETS_BASE.URL}/tag-01.svg`,
  TAG_02: `${ASSETS_BASE.URL}/tag-02.svg`,
  TAG_03: `${ASSETS_BASE.URL}/tag-03.svg`,
  TARGET_01: `${ASSETS_BASE.URL}/target-01.svg`,
  TARGET_02: `${ASSETS_BASE.URL}/target-02.svg`,
  TARGET_03: `${ASSETS_BASE.URL}/target-03.svg`,
  TARGET_04: `${ASSETS_BASE.URL}/target-04.svg`,
  TARGET_05: `${ASSETS_BASE.URL}/target-05.svg`,
  TELESCOPE: `${ASSETS_BASE.URL}/telescope.svg`,
  TERMINAL_BROWSER: `${ASSETS_BASE.URL}/terminal-browser.svg`,
  TERMINAL_CIRCLE: `${ASSETS_BASE.URL}/terminal-circle.svg`,
  TERMINAL_SQUARE: `${ASSETS_BASE.URL}/terminal-square.svg`,
  TERMINAL: `${ASSETS_BASE.URL}/terminal.svg`,
  TEXT_INPUT: `${ASSETS_BASE.URL}/text-input.svg`,
  THERMOMETER_01: `${ASSETS_BASE.URL}/thermometer-01.svg`,
  THERMOMETER_02: `${ASSETS_BASE.URL}/thermometer-02.svg`,
  THERMOMETER_03: `${ASSETS_BASE.URL}/thermometer-03.svg`,
  THERMOMETER_COLD: `${ASSETS_BASE.URL}/thermometer-cold.svg`,
  THERMOMETER_WARM: `${ASSETS_BASE.URL}/thermometer-warm.svg`,
  THUMBS_DOWN: `${ASSETS_BASE.URL}/thumbs-down.svg`,
  THUMBS_UP: `${ASSETS_BASE.URL}/thumbs-up.svg`,
  TICKET_01: `${ASSETS_BASE.URL}/ticket-01.svg`,
  TICKET_02: `${ASSETS_BASE.URL}/ticket-02.svg`,
  TOGGLE_01_LEFT: `${ASSETS_BASE.URL}/toggle-01-left.svg`,
  TOGGLE_01_RIGHT: `${ASSETS_BASE.URL}/toggle-01-right.svg`,
  TOGGLE_02_LEFT: `${ASSETS_BASE.URL}/toggle-02-left.svg`,
  TOGGLE_02_RIGHT: `${ASSETS_BASE.URL}/toggle-02-right.svg`,
  TOGGLE_03_LEFT: `${ASSETS_BASE.URL}/toggle-03-left.svg`,
  TOGGLE_03_RIGHT: `${ASSETS_BASE.URL}/toggle-03-right.svg`,
  TOOL_01: `${ASSETS_BASE.URL}/tool-01.svg`,
  TOOL_02: `${ASSETS_BASE.URL}/tool-02.svg`,
  TRAIN: `${ASSETS_BASE.URL}/train.svg`,
  TRAM: `${ASSETS_BASE.URL}/tram.svg`,
  TRANSFORM: `${ASSETS_BASE.URL}/transform.svg`,
  TRANSLATE_01: `${ASSETS_BASE.URL}/translate-01.svg`,
  TRANSLATE_02: `${ASSETS_BASE.URL}/translate-02.svg`,
  TRASH_01: `${ASSETS_BASE.URL}/trash-01.svg`,
  TRASH_02: `${ASSETS_BASE.URL}/trash-02.svg`,
  TRASH_03: `${ASSETS_BASE.URL}/trash-03.svg`,
  TRASH_04: `${ASSETS_BASE.URL}/trash-04.svg`,
  TREND_DOWN_01: `${ASSETS_BASE.URL}/trend-down-01.svg`,
  TREND_DOWN_02: `${ASSETS_BASE.URL}/trend-down-02.svg`,
  TREND_UP_01: `${ASSETS_BASE.URL}/trend-up-01.svg`,
  TREND_UP_02: `${ASSETS_BASE.URL}/trend-up-02.svg`,
  TRIANGLE: `${ASSETS_BASE.URL}/triangle.svg`,
  TROPHY_01: `${ASSETS_BASE.URL}/trophy-01.svg`,
  TROPHY_02: `${ASSETS_BASE.URL}/trophy-02.svg`,
  TRUCK_01: `${ASSETS_BASE.URL}/truck-01.svg`,
  TRUCK_02: `${ASSETS_BASE.URL}/truck-02.svg`,
  TV_01: `${ASSETS_BASE.URL}/tv-01.svg`,
  TV_02: `${ASSETS_BASE.URL}/tv-02.svg`,
  TV_03: `${ASSETS_BASE.URL}/tv-03.svg`,
  TYPE_01: `${ASSETS_BASE.URL}/type-01.svg`,
  TYPE_02: `${ASSETS_BASE.URL}/type-02.svg`,
  TYPE_SQUARE: `${ASSETS_BASE.URL}/type-square.svg`,
  TYPE_STRIKETHROUGH_01: `${ASSETS_BASE.URL}/type-strikethrough-01.svg`,
  TYPE_STRIKETHROUGH_02: `${ASSETS_BASE.URL}/type-strikethrough-02.svg`,
  UMBRELLA_01: `${ASSETS_BASE.URL}/umbrella-01.svg`,
  UMBRELLA_02: `${ASSETS_BASE.URL}/umbrella-02.svg`,
  UMBRELLA_03: `${ASSETS_BASE.URL}/umbrella-03.svg`,
  UNDERLINE_01: `${ASSETS_BASE.URL}/underline-01.svg`,
  UNDERLINE_02: `${ASSETS_BASE.URL}/underline-02.svg`,
  UNDERLINE_SQUARE: `${ASSETS_BASE.URL}/underline-square.svg`,
  UPLOAD_01: `${ASSETS_BASE.URL}/upload-01.svg`,
  UPLOAD_02: `${ASSETS_BASE.URL}/upload-02.svg`,
  UPLOAD_03: `${ASSETS_BASE.URL}/upload-03.svg`,
  UPLOAD_04: `${ASSETS_BASE.URL}/upload-04.svg`,
  UPLOAD_CLOUD_01: `${ASSETS_BASE.URL}/upload-cloud-01.svg`,
  UPLOAD_CLOUD_02: `${ASSETS_BASE.URL}/upload-cloud-02.svg`,
  USB_FLASH_DRIVE: `${ASSETS_BASE.URL}/usb-flash-drive.svg`,
  USER_01: `${ASSETS_BASE.URL}/user-01.svg`,
  USER_02: `${ASSETS_BASE.URL}/user-02.svg`,
  USER_03: `${ASSETS_BASE.URL}/user-03.svg`,
  USER_CHECK_01: `${ASSETS_BASE.URL}/user-check-01.svg`,
  USER_CHECK_02: `${ASSETS_BASE.URL}/user-check-02.svg`,
  USER_CIRCLE: `${ASSETS_BASE.URL}/user-circle.svg`,
  USER_DOWN_01: `${ASSETS_BASE.URL}/user-down-01.svg`,
  USER_DOWN_02: `${ASSETS_BASE.URL}/user-down-02.svg`,
  USER_EDIT: `${ASSETS_BASE.URL}/user-edit.svg`,
  USER_LEFT_01: `${ASSETS_BASE.URL}/user-left-01.svg`,
  USER_LEFT_02: `${ASSETS_BASE.URL}/user-left-02.svg`,
  USER_MINUS_01: `${ASSETS_BASE.URL}/user-minus-01.svg`,
  USER_MINUS_02: `${ASSETS_BASE.URL}/user-minus-02.svg`,
  USER_PLUS_01: `${ASSETS_BASE.URL}/user-plus-01.svg`,
  USER_PLUS_02: `${ASSETS_BASE.URL}/user-plus-02.svg`,
  USER_RIGHT_01: `${ASSETS_BASE.URL}/user-right-01.svg`,
  USER_RIGHT_02: `${ASSETS_BASE.URL}/user-right-02.svg`,
  USER_SQUARE: `${ASSETS_BASE.URL}/user-square.svg`,
  USER_UP_01: `${ASSETS_BASE.URL}/user-up-01.svg`,
  USER_UP_02: `${ASSETS_BASE.URL}/user-up-02.svg`,
  USER_X_01: `${ASSETS_BASE.URL}/user-x-01.svg`,
  USER_X_02: `${ASSETS_BASE.URL}/user-x-02.svg`,
  USERS_01: `${ASSETS_BASE.URL}/users-01.svg`,
  USERS_02: `${ASSETS_BASE.URL}/users-02.svg`,
  USERS_03: `${ASSETS_BASE.URL}/users-03.svg`,
  USERS_CHECK: `${ASSETS_BASE.URL}/users-check.svg`,
  USERS_DOWN: `${ASSETS_BASE.URL}/users-down.svg`,
  USERS_EDIT: `${ASSETS_BASE.URL}/users-edit.svg`,
  USERS_LEFT: `${ASSETS_BASE.URL}/users-left.svg`,
  USERS_MINUS: `${ASSETS_BASE.URL}/users-minus.svg`,
  USERS_PLUS: `${ASSETS_BASE.URL}/users-plus.svg`,
  USERS_RIGHT: `${ASSETS_BASE.URL}/users-right.svg`,
  USERS_UP: `${ASSETS_BASE.URL}/users-up.svg`,
  USERS_X: `${ASSETS_BASE.URL}/users-x.svg`,
  VARIABLE: `${ASSETS_BASE.URL}/variable.svg`,
  VIDEO_RECORDER_OFF: `${ASSETS_BASE.URL}/video-recorder-off.svg`,
  VIDEO_RECORDER: `${ASSETS_BASE.URL}/video-recorder.svg`,
  VIRUS: `${ASSETS_BASE.URL}/virus.svg`,
  VOICEMAIL: `${ASSETS_BASE.URL}/voicemail.svg`,
  VOLUME_MAX: `${ASSETS_BASE.URL}/volume-max.svg`,
  VOLUME_MIN: `${ASSETS_BASE.URL}/volume-min.svg`,
  VOLUME_MINUS: `${ASSETS_BASE.URL}/volume-minus.svg`,
  VOLUME_PLUS: `${ASSETS_BASE.URL}/volume-plus.svg`,
  VOLUME_X: `${ASSETS_BASE.URL}/volume-x.svg`,
  WALLET_01: `${ASSETS_BASE.URL}/wallet-01.svg`,
  WALLET_02: `${ASSETS_BASE.URL}/wallet-02.svg`,
  WALLET_03: `${ASSETS_BASE.URL}/wallet-03.svg`,
  WALLET_04: `${ASSETS_BASE.URL}/wallet-04.svg`,
  WALLET_05: `${ASSETS_BASE.URL}/wallet-05.svg`,
  WATCH_CIRCLE: `${ASSETS_BASE.URL}/watch-circle.svg`,
  WATCH_SQUARE: `${ASSETS_BASE.URL}/watch-square.svg`,
  WAVES: `${ASSETS_BASE.URL}/waves.svg`,
  WEBCAM_01: `${ASSETS_BASE.URL}/webcam-01.svg`,
  WEBCAM_02: `${ASSETS_BASE.URL}/webcam-02.svg`,
  WIFI_OFF: `${ASSETS_BASE.URL}/wifi-off.svg`,
  WIFI: `${ASSETS_BASE.URL}/wifi.svg`,
  WIND_01: `${ASSETS_BASE.URL}/wind-01.svg`,
  WIND_02: `${ASSETS_BASE.URL}/wind-02.svg`,
  WIND_03: `${ASSETS_BASE.URL}/wind-03.svg`,
  X_CIRCLE: `${ASSETS_BASE.URL}/x-circle.svg`,
  X_CLOSE: `${ASSETS_BASE.URL}/x-close.svg`,
  X_SQUARE: `${ASSETS_BASE.URL}/x-square.svg`,
  X: `${ASSETS_BASE.URL}/x.svg`,
  YOUTUBE: `${ASSETS_BASE.URL}/youtube.svg`,
  ZAP_CIRCLE: `${ASSETS_BASE.URL}/zap-circle.svg`,
  ZAP_FAST: `${ASSETS_BASE.URL}/zap-fast.svg`,
  ZAP_OFF: `${ASSETS_BASE.URL}/zap-off.svg`,
  ZAP_SQUARE: `${ASSETS_BASE.URL}/zap-square.svg`,
  ZAP: `${ASSETS_BASE.URL}/zap.svg`,
  ZOOM_IN: `${ASSETS_BASE.URL}/zoom-in.svg`,
  ZOOM_OUT: `${ASSETS_BASE.URL}/zoom-out.svg`,
};

import {
  Directive,
  ElementRef,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslationService } from '@asol/core';
import { ControlBase } from './control-base';

@Directive()
export abstract class NativeControlBase<T> extends ControlBase<T> {
  @ViewChild('inputField', { static: false }) input: ElementRef | null = null;

  constructor(
    protected renderer: Renderer2,
    protected trans: TranslationService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(trans, ngControl);
  }

  protected getControlValue(): T | null {
    if (!this.input) {
      return null;
    }

    return this.input.nativeElement.value;
  }

  protected setControlValue(val: T | null): void {
    if (!this.input) {
      return;
    }

    if (val) {
      this.input.nativeElement.value = val;
    } else {
      this.input.nativeElement.value = '';
    }
  }

  protected setControlDisabled(isDisabled: boolean): void {
    if (!this.input) {
      return;
    }
    this.renderer.setProperty(this.input.nativeElement, 'disabled', isDisabled);
  }

  public focus(): void {
    this.input.nativeElement.focus();
  }
}

import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { TranslationService } from '@asol/core';
import {
  ChangeEventArgs,
  ComboBox,
  FieldSettingsModel,
} from '@syncfusion/ej2-angular-dropdowns';
import { ICONS } from '../../../../shared/constants/icon.constant';
import { ControlBase } from '../../base/control-base';
import { DataProvider } from '../../data-provider/data-provider.interface';

@Component({
  selector: 'asol-select-field',
  templateUrl: './select-field.component.html',
})
export class AsolSelectFieldComponent
  extends ControlBase<string | number | boolean>
  implements OnChanges, OnInit, AfterViewInit
{
  @Input() options: unknown[] | null = [];
  @Input() valueProperty = 'value';
  @Input() displayProperty = 'text';
  /** Data provider to load data from API */
  @Input() dataProvider?: DataProvider;
  /** Optional filter parameters for data provider */
  @Input() filterParams?: unknown;
  @Input() autofill = false;
  @Input() showClearButton = false;
  @Input() openOnFocus = true;
  /** Allows user defined value which does not exist in data source. */
  @Input() allowCustom = true;
  /** Defines popup width */
  @Input() popupWidth = '100%';

  /**
   * Allow filtering of options in dropdown
   * @default false
   */
  @Input() allowFiltering = false;

  /**
   * Prefills the first item in the list if there is only one item
   * @default true
   */
  @Input() selectFirstItem = true;

  /** Defines the item template */
  @ContentChild('itemTemplate')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemTemplate: any;

  @ViewChild('comboBox')
  selectInput!: ComboBox;

  public locale = '';
  public field?: FieldSettingsModel;

  ICONS = ICONS;

  constructor(
    public trans: TranslationService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(trans, ngControl);
    this.locale = this.trans.currentLocale;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueProperty || changes.displayProperty) {
      this.field = {
        text: this.displayProperty,
        value: this.valueProperty,
      };
    }

    if (changes.filterParams && !changes.filterParams.firstChange) {
      this.loadOptions();
    }

    if (changes['options'] && this.value) {
      this.setControlValue(this.value);
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // if options are loaded via async pipe, and not using data provider, set the value
    if (!this.dataProvider && this.value && this.options?.length) {
      this.setControlValue(this.value);
    }

    if (this.itemTemplate) {
      this.selectInput.itemTemplate = this.itemTemplate;
    }
  }

  loadOptions(): void {
    if (!this.dataProvider) {
      return;
    }
    if (!this.displayProperty) {
      this.displayProperty = 'text';
    }
    if (!this.valueProperty) {
      this.valueProperty = 'value';
    }
    this.dataProvider.getData(this.filterParams).subscribe((response) => {
      this.options = response;
      this.setControlValue(this.value);
    });
  }

  callOnChange(event: ChangeEventArgs) {
    if (!event.isInteracted) {
      return;
    }

    const change = event.itemData
      ? this.valueProperty
        ? event.itemData[this.valueProperty]
        : event.itemData.value
      : null;
    this.onChange(change);
    this.valueChangedDebouncer.next(change);
  }

  protected getControlValue(): string | number | boolean {
    if (!this.selectInput) {
      return null;
    }

    return this.selectInput.value;
  }

  protected setControlValue(val: string | number | boolean): void {
    if (!this.selectInput) {
      return;
    }

    if (val?.toString()) {
      if (this.options?.length && this.valueProperty) {
        // Changed to == to allow for number/string comparison
        const value = this.options.find((p) => p[this.valueProperty] == val);
        if (value) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.selectInput.value = value[this.displayProperty] as any;
        } else {
          this.selectInput.value = val;
        }
      } else {
        this.selectInput.value = val;
      }
    } else {
      this.selectInput.value = '';
    }

    // Prefill require field if there is only one passible option
    if (
      this.selectFirstItem &&
      !this.selectInput.value &&
      this.options?.length === 1 &&
      this.ngControl?.control &&
      typeof this.ngControl.control?.validator === 'function' &&
      this.ngControl.control.validator({} as AbstractControl)?.required
    ) {
      const value = this.options[0];
      this.selectInput.value = value[this.displayProperty];
      this.onChange(value[this.valueProperty]);
    }
  }

  protected setControlDisabled(isDisable: boolean): void {
    // empty
  }

  /**
   * Opens select popup on input focus
   */
  onFocus(): void {
    if (this.openOnFocus) {
      this.selectInput.showPopup();
    }
  }

  /**
   * Gets complete selected object
   * @returns Selected entity or null
   */
  getSelectedEntity<T>(): T | null {
    if (!this.selectInput.value || !this.options?.length) {
      return null;
    }

    return this.options?.find(
      (p) => p[this.valueProperty] === this.selectInput.value
    ) as T;
  }
}

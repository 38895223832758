import { Component, Input, Optional, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RemoveValidationError, TranslationService } from '@asol/core';
import { finalize } from 'rxjs';
import { ICONS } from '../../../../shared/constants/icon.constant';
import { NativeControlBase } from '../../base/native-control-base';
import { CheckFieldProvider } from '../../data-provider/check-field-provider.interface';
import { InputType } from '../models/input.type';

@Component({
  selector: 'asol-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class AsolInputFieldComponent extends NativeControlBase<string> {
  /** Prefix icon in input field */
  @Input() prefixIcon: string | undefined;
  /** Suffix icon in input field */
  @Input() suffixIcon: string | undefined;
  /** Type of the  input field */
  @Input() inputType: InputType = 'text';
  /** Provider to check item for duplication */
  @Input() provider: CheckFieldProvider | null = null;
  /** Provider URI */
  @Input() providerUri: string | null = null;
  /** Error translation key */
  @Input() checkTransKey: string | undefined;
  /** Error message key */
  @Input() checkErrorKey: string | undefined;

  ICONS = ICONS;

  /**
   * Flag to show loading spinner
   */
  public loading: boolean | null = null;

  /** Flag if input is valid */
  public valid = false;

  constructor(
    renderer: Renderer2,
    trans: TranslationService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(renderer, trans, ngControl);
  }

  protected getControlValue(): string | null {
    if (!this.input) {
      return null;
    }

    return this.input.nativeElement.value;
  }

  public focusIn(target: unknown): void {
    (target as HTMLElement).parentElement.classList.add('e-input-focus');
  }

  public focusOut(target: unknown): void {
    (target as HTMLElement).parentElement.classList.remove('e-input-focus');
  }

  callOnChange() {
    this.onChange(this.value);
    this.valueChangedDebouncer.next(this.value);
    this.valid = false;
  }

  /**
   * Validates user input for duplicates
   */
  protected override controlValid(): void {
    if (!this.provider) {
      return;
    }

    this.loading = true;
    this.provider
      .validateInput(this.providerUri, this.value)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((valid) => {
        this.valid = valid;
        if (!valid) {
          this.ngControl.control.setErrors({ duplicate: true });
        } else {
          RemoveValidationError(this.ngControl.control, 'duplicate');
        }
      });
  }

  /**
   * Overrides duplicate error message
   */
  override checkErrorMessage(): string {
    if (!this.checkTransKey || !this.checkErrorKey) {
      return super.checkErrorMessage();
    }
    return this.trans.get(this.checkTransKey, this.checkErrorKey);
  }
}

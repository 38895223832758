import { Directive } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Base class for service to store and get settings
 */
@Directive()
export abstract class SettingsServiceBase {
  /**
   * Stores setting by id
   * @param syncId Synchronization identifier
   * @param module Module identifier
   * @param settings Setting to store
   */
  public abstract store(
    syncId: string,
    module: string,
    settings: string
  ): Observable<boolean>;

  /**
   * Gets setting by id
   * @param syncId Synchro identifier that was used to store setting
   * @param module Module identifier
   */
  public abstract get(syncId: string, module: string): Observable<unknown>;

  /**
   * Deletes setting by id
   * @param syncId Synchro identifier that was used to store setting
   * @param module Module identifier
   */
  public abstract delete(syncId: string, module: string): Observable<boolean>;
}

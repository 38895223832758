import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Onboarding } from '../models/onboarding.interface';

/**
 * Data source for onboarding
 */
@Injectable({
  providedIn: 'root',
})
export class OnboardingDataSource {
  private showOnboarding = new Subject<Onboarding>();
  public show$ = this.showOnboarding.asObservable();

  /**
   * Show onboarding with given data
   * @param data Data to show
   */
  show(data: Onboarding): void {
    this.showOnboarding.next(data);
  }
}

import { Component, Input } from '@angular/core';
import { GENERAL_TRANSLATION, TranslationService } from '@asol/core';
import { Color } from '../../../../shared/types/color.type';
import { ButtonTextType } from '../models/button-text.type';
import { ButtonType } from '../models/button.type';
import { IconPosition } from '../models/icon-position.type';

@Component({
  selector: 'asol-button',
  templateUrl: './asol-button.component.html',
})
export class AsolButtonComponent {
  GENERAL_TRANSLATION = GENERAL_TRANSLATION;

  /**
   * Type of button
   * @default default
   */
  @Input() type: ButtonType = 'default';
  /** Button text based on type */
  @Input() textType: ButtonTextType | null = null;
  /**
   * Button color @see {@link Color}
   * @default primary
   */
  @Input() color: Color = 'primary';
  /**
   * Icon position @see {@link IconPosition}
   * @defaults left
   */
  @Input() iconPosition: IconPosition = 'left';

  /** SVG icon URL, use ICONS constant */
  @Input() iconSvgUrl = '';
  /**
   * Flag if button is disabled
   * @default false
   */
  @Input() disabled = false;
  /** Flag if button is active
   * @default false
   */
  @Input() active = false;
  /** Css class definition to add to button */
  @Input() cssClass?: string;

  constructor(public trans: TranslationService) {}
}

<div
  class="d-inline-flex asol-split"
  [class]="cssClass"
  [ngClass]="{ 'split-outline': showOutline }">
  <button
    #primaryButton
    [ngClass]="'e-' + color + ' e-' + type"
    [disabled]="disabled"
    (click)="onPrimaryClick()"
    class="e-btn d-inline-flex primary-split-button align-items-center">
    <svg-icon
      [src]="iconSvgUrl"
      *ngIf="iconSvgUrl"
      class="btn-icon center-item me-1"></svg-icon>
    <ng-content></ng-content>
  </button>
  <button
    type="button"
    ejs-dropdownbutton
    cssClass="e-caret-hide asol-custom d-inline-flex align-items-center"
    [items]="items"
    [disabled]="disabled"
    (select)="onSelect($event)"
    (open)="onOpen($event)"
    [ngClass]="'e-' + color + ' e-' + type">
    <svg-icon class="dropdown-icon" [src]="chevronSvgUrl"></svg-icon>
  </button>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationServiceBase,
  CORE_CONSTANTS,
  TenantData,
  TranslationService,
} from '@asol/core';
import { SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Observable, from, of, tap } from 'rxjs';
import { NotificationService } from '../../../../services/notification-service/notification.service';
import { SpinnerService } from '../../../layout-controls/spinner/services/spinner.service';
import translation from '../asol-tenant-select.translation.json';
import { TENANT_SELECT_CONSTANT } from '../constants/tenant-select.constant';

@Component({
  selector: 'asol-tenant-select',
  templateUrl: './asol-tenant-select.component.html',
})
export class AsolTenantSelectComponent implements OnInit {
  /** Selected or stored tenant identifier */
  tenantValue: string | null = null;
  /** List of all available tenants */
  tenants$: Observable<TenantData[]> = of([]);

  constructor(
    public authService: AuthenticationServiceBase,
    private trans: TranslationService,
    private router: Router,
    private spinner: SpinnerService,
    private notificationService: NotificationService
  ) {
    /** initializes translation service */
    this.trans.initialize(TENANT_SELECT_CONSTANT.TRANS, translation);

    this.authService.loginChanged$.subscribe((logged) => {
      if (logged) {
        this.loadTenants();
      }
    });
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().then((logged) => {
      if (logged) {
        this.loadTenants();
      }
    });
  }

  /**
   * Stores selected tenant to session storage and API
   * @param tenant Selected tenant
   */
  onTenantSelect(tenant: SelectEventArgs): void {
    sessionStorage.setItem(
      CORE_CONSTANTS.TENANT_STORAGE,
      tenant.itemData['id']
    );

    /** sends new tenant id to API */
    this.spinner.show();
    this.authService
      .updateTenant(tenant.itemData['id'])
      .then((success: boolean) => {
        if (success) {
          /** shows success message */
          this.notificationService.success(
            this.trans.get(TENANT_SELECT_CONSTANT.TRANS, 'success')
          );

          /** if tenant select change was successful, reload application */
          this.router.navigate(['/']).then(() => window.location.reload());
        } else {
          /** if tenant select change failed, throw toast exception */
          this.notificationService.danger(
            this.trans.get(TENANT_SELECT_CONSTANT.TRANS, 'fail')
          );
        }
        /** hides the spinner */
        this.spinner.hide();
      });
  }

  /**
   * Gets tenant from session storage
   */
  private getTenant(): void {
    this.tenantValue = sessionStorage.getItem(CORE_CONSTANTS.TENANT_STORAGE);
  }

  /**
   * Loads tenant from auth service
   */
  private loadTenants(): void {
    this.tenants$ = from(this.authService.getTenants()).pipe(
      tap((data) => {
        if (!data?.length) {
          throw new Error('No tenants for user');
        }
        this.getTenant();
      })
    );
  }
}

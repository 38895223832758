import { Component, Input, Optional, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslationService } from '@asol/core';
import { NativeControlBase } from '../../base/native-control-base';

@Component({
  selector: 'asol-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class AsolTextAreaComponent extends NativeControlBase<string> {
  @Input() rows = 3;

  constructor(
    renderer: Renderer2,
    trans: TranslationService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(renderer, trans, ngControl);
  }

  public focusIn(target: unknown): void {
    (target as HTMLElement).parentElement.classList.add('e-input-focus');
  }

  public focusOut(target: unknown): void {
    (target as HTMLElement).parentElement.classList.remove('e-input-focus');
  }

  callOnChange(): void {
    this.onChange(this.value);
    this.valueChangedDebouncer.next(this.value);
  }
}

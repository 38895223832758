import { Component } from '@angular/core';
import { TranslationService } from '@asol/core';
import { loadCldr, setCulture } from '@syncfusion/ej2-base';
import calendarCs from 'cldr-data/main/cs/ca-gregorian.json';
import numbersCs from 'cldr-data/main/cs/numbers.json';
import timeCs from 'cldr-data/main/cs/timeZoneNames.json';
import calendarEn from 'cldr-data/main/en/ca-gregorian.json';
import numbersEn from 'cldr-data/main/en/numbers.json';
import timeEn from 'cldr-data/main/en/timeZoneNames.json';
import calendarSk from 'cldr-data/main/sk/ca-gregorian.json';
import numbersSk from 'cldr-data/main/sk/numbers.json';
import timeSk from 'cldr-data/main/sk/timeZoneNames.json';
import numbering from 'cldr-data/supplemental/numberingSystems.json';
import week from 'cldr-data/supplemental/weekData.json'; // To load the culture based first day of week
import { AuthenticationService } from './core/authentication/authentication.service';
import translation from './core/main-menu/main-menu.translate.json';
import { MenuService } from './core/main-menu/services/menu.service';
import { TRANS } from './shared/constants/translation.constant';

loadCldr(
  numbersEn,
  numbersSk,
  numbersCs,
  calendarEn,
  calendarSk,
  calendarCs,
  numbering,
  timeEn,
  timeSk,
  timeCs,
  week
);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MenuService],
})
export class AppComponent {
  TRANS = TRANS;

  constructor(
    public menuProvider: MenuService,
    public authService: AuthenticationService,
    public trans: TranslationService
  ) {
    this.trans.initialize(TRANS.MAIN_MENU, translation);

    setCulture(this.trans.currentLocale);

    this.trans.languageChanged$.subscribe((locale) => {
      setCulture(locale.currentLocale);
    });
  }
}

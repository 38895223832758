import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  GENERAL_TRANSLATION,
  markForValidation,
  TranslationService,
} from '@asol/core';
import { ONBOARDING_CONST } from '../../constants/onboarding.constants';
import { OnboardingCreate } from '../../models/onboarding-create.interface';
import translation from '../../onboarding.translation.json';

@Component({
  selector: 'asol-onboarding-create',
  templateUrl: './onboarding-create.component.html',
})
export class AsolOnboardingCreateComponent {
  ONBOARDING_CONST = ONBOARDING_CONST;
  GENERAL_TRANSLATION = GENERAL_TRANSLATION;
  formGroup: FormGroup;

  @Output() save = new EventEmitter<OnboardingCreate>();

  constructor(public trans: TranslationService, private fb: FormBuilder) {
    this.trans.initialize(ONBOARDING_CONST.TRANS, translation);

    this.formGroup = this.fb.group({
      title: [null, Validators.required],
      module: [],
      highlitedText: [],
      text: [],
      image: [],
      extension: [],
      language: [null, Validators.required],
    });
  }

  /**
   * Event handler after user clicks on skip or next button
   * @param skip Flag if user clicked on skip button
   */
  public onCreate(): void {
    markForValidation(this.formGroup);
    if (!this.formGroup.valid) {
      return;
    }

    this.save.emit(this.formGroup.value);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from '@asol/core';
import { SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { ASSETS_BASE } from '../../../../shared/constants/assets-base.constant';
import { ICON_FORMATS } from '../../../../shared/constants/icon-formats.constant';

@Component({
  selector: 'asol-language-select',
  templateUrl: './asol-language-select.component.html',
  styleUrls: ['./asol-language-select.component.scss'],
})
export class AsolLanguageSelectComponent {
  /** custom css class for dropdown button
   * @default ''
   */
  @Input() cssClass = '';

  /**
   * DEVCLOUD-6793 temporary fix for language select
   * Emits language when new language is selected.
   *
   * @type {string}
   */
  @Output() onLanguageChange = new EventEmitter<string>();

  ASSETS_BASE = ASSETS_BASE;
  ICON_FORMATS = ICON_FORMATS;

  constructor(public translationService: TranslationService) {}

  /**
   * This method sets selected language from dropdown list in translation service
   * @param language Event from dropdownlist. SelectEventArgs contains selected language short code in itemData
   *
   */
  onFlagSelect(language: SelectEventArgs): void {
    this.onLanguageChange.emit(language.itemData['short']);
    this.translationService.setLocale(language.itemData['short'], true);
  }
}

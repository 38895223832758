<ng-container *ngIf="mainApp$ | async as mainApp">
  <div class="d-flex vh-100 row g-none overflow-hidden">
    <div class="content-image col-12 col-lg-8 d-flex flex-column">
      <div
        class="h-100 back"
        [ngStyle]="{
          'background-image': 'url(\'' + mainApp.background + '\')'
        }">
        <asol-app-background
          class="back-overlay"
          [color]="mainApp.color"></asol-app-background>
        <div class="back-heading w-100">
          <div class="d-flex justify-content-between">
            <svg-icon class="me-6" [src]="mainApp.logo"></svg-icon>
            <asol-language-select cssClass="d-lg-none"> </asol-language-select>
          </div>
          <div class="title h-100">
            <div class="d-flex flex-column">
              <h1>{{ mainAppData.title }}</h1>

              <div class="mt-5">
                {{ mainAppData.description }}
              </div>
            </div>
          </div>
          <div class="d-lg-none mt-8 login">
            <ng-container *ngTemplateOutlet="login"></ng-container>
          </div>
        </div>
      </div>

      <div class="apps" *ngIf="displayApps && apps$ | async as apps">
        <a class="app" *ngFor="let app of apps" [href]="app.url">
          <asol-app-background [color]="app.color"></asol-app-background>
          <svg-icon class="app-logo" [src]="app.logo" [applyClass]="true">
          </svg-icon>
        </a>
      </div>
    </div>
    <div class="content-login col-4">
      <div class="d-flex justify-content-end">
        <asol-language-select cssClass="landing-language-input">
        </asol-language-select>
      </div>
      <div class="center-item">
        <ng-container *ngTemplateOutlet="login"></ng-container>
      </div>
      <div class="text-center">
        {{
          trans.get(LANDING_PAGE_CONST.TRANS, 'copyright')
            | stringFormat : [year]
        }}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #login>
  <div class="flex-column text-start">
    <h1 class="display">
      {{ trans.get(LANDING_PAGE_CONST.TRANS, 'welcome') }}
    </h1>
    <div class="description mb-6">
      {{ trans.get(LANDING_PAGE_CONST.TRANS, 'logToAccount') }}
    </div>
    <asol-button [color]="showMobile ? 'light' : 'dark'" (click)="onLogin()">
      {{
        trans.get(LANDING_PAGE_CONST.TRANS, 'logButton')
          | stringFormat : [mainAppData.name]
      }}
    </asol-button>

    <!-- Registration message -->
    <div
      *ngIf="registerLink"
      class="register-message d-flex align-items-center">
      <div>
        {{ trans.get(LANDING_PAGE_CONST.TRANS, 'registerMessage') }}
      </div>
      <asol-button type="flat" color="primary" [routerLink]="registerLink">{{
        trans.get(LANDING_PAGE_CONST.TRANS, 'register')
      }}</asol-button>
    </div>
  </div>
</ng-template>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LandingApp } from '../models/landing-app.interface';

@Injectable()
export class AppService {
  constructor(private http: HttpClient) {}

  /**
   * Returns max. 4 apps
   * @returns Collection of landing apps @see {@link LandingApp}
   */
  getApps(slice = true): Observable<LandingApp[]> {
    return this.http
      .get<LandingApp[]>('assets/landing/apps.json', {
        responseType: 'json',
      })
      .pipe(
        map((result) => {
          return slice ? result.slice(0, 5) : result;
        })
      );
  }

  /**
   * Gets main app based on provided token
   * @returns Landing app @see {@link LandingApp}
   */
  getMainApp(appCode: string): Observable<LandingApp | null> {
    return this.getApps(false).pipe(
      map((apps) => {
        return apps.find((app) => app.code === appCode) || null;
      })
    );
  }
}

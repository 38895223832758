import { NgModule } from '@angular/core';
import { PipesModule } from '@asol/core';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolButtonModule } from '../../controls/button/asol-button.module';
import { AsolLanguageSelectModule } from '../../controls/language-select/asol-language-select.module';
import { AppBackgroundComponent } from './components/app-background.component';
import { AsolLandingPageComponent } from './components/landing-page.component';

@NgModule({
  declarations: [AsolLandingPageComponent, AppBackgroundComponent],
  exports: [AsolLandingPageComponent],
  imports: [
    SharedModule,
    AsolLanguageSelectModule,
    AsolButtonModule,
    PipesModule,
  ],
})
export class AsolLandingPageModule {}

import { MenuItem } from 'projects/asol-controls/src/lib/modules/layout-controls/main-layout/models/menu-item.interface';
import { MenuProvider } from 'projects/asol-controls/src/lib/modules/layout-controls/main-layout/services/menu.provider';
import { Observable, of } from 'rxjs';
import { ADDRESS_NAVIGATION } from '../../../modules/composite-controls/address/constants/dev-address-navigation.constant';
import { COMMENTS_NAVIGATION } from '../../../modules/composite-controls/comments/constants/dev-comments-navigation.constant';
import { ITEMS_LIST_NAVIGATION } from '../../../modules/composite-controls/items-list/constants/dev-items-list-navigation.constant';
import { BREADCRUMB_NAVIGATION } from '../../../modules/controls/breadcrumb/constants/breadcrumb-navigation.constant';
import { BUTTONS_NAVIGATION } from '../../../modules/controls/buttons/constants/buttons-navigation.constant';
import { DEV_DIALOG_NAVIGATION } from '../../../modules/controls/dialog/constants/dev-dialog-navigation.constant';
import { ENTITY_LINK_NAVIGATION } from '../../../modules/controls/entity-link/constants/entity-link-navigation.constant';
import { FILTER_NAVIGATION } from '../../../modules/controls/filter/constants/filter-navigation.constant';
import { GRID_NAVIGATION } from '../../../modules/controls/grid/constants/grid-navigation.constant';
import { INFO_PANEL_NAVIGATION } from '../../../modules/controls/info-panel/constants/info-panel.navigation.constant';
import { PAGER_NAVIGATION } from '../../../modules/controls/pager/constants/dev-pager-navigation.constant';
import { PRIORITY_NAVIGATION } from '../../../modules/controls/priority/constants/dev-priority-navigation.constant';
import { PROGRESS_BAR_NAVIGATION } from '../../../modules/controls/progress-bar/constants/progress-bar-navigation.constant';
import { STEPPER_NAVIGATION } from '../../../modules/controls/stepper/constants/dev-stepper-navigation.constant';
import { TABS_NAVIGATION } from '../../../modules/controls/tabs/constants/tabs-navigation.constant';
import { TOAST_NAVIGATION } from '../../../modules/controls/toast/constants/toast-navigation.constant';
import { TOOLBAR_NAVIGATION } from '../../../modules/controls/toolbar/constants/toolbar-navigation.constant';
import { WORKFLOW_NAVIGATION } from '../../../modules/controls/workflow/constants/workflow-navigation.constant';
import { DASHBOARD_NAVIGATION } from '../../../modules/dashboard/constants/dashboard-navigation.constant';
import { UPLOAD_NAVIGATION } from '../../../modules/form-controls/components/upload/constants/upload-navigation.constant';
import { INPUTS_NAVIGATION } from '../../../modules/form-controls/constants/inputs-navigation.constant';
import { CONTENT_WRAPPER_NAVIGATION } from '../../../modules/layout-controls/content-wrapper/constants/dev-content-wrapper-navigation.constant';
import { ONBOARDING_NAVIGATION } from '../../../modules/layout-controls/onboarding/constants/onboarding-navigation.constant';
import { SPINNER_NAVIGATION } from '../../../modules/layout-controls/spinner/constants/dev-spinner-navigation.constant';
import { PROFILE_NAVIGATION } from '../../../modules/profile/constants/profile-navigation.constant';
import { TEST_NAVIGATION } from '../../../modules/test-page/constants/test-page-navigation.constant';
import { MESSAGE_PANEL_NAVIGATION } from '../.././../modules/controls/message-panel/constants/message-panel-navigation.constant';

export class MenuService implements MenuProvider {
  private assetsIconPath = '/assets/icons/';
  private mainMenuPath = `${this.assetsIconPath}main-menu/`;

  private menuItems: MenuItem[] = [
    {
      text: 'dashboard',
      iconUrl: this.mainMenuPath + 'Dashboard.svg',
      url: DASHBOARD_NAVIGATION.MAIN,
      id: '10',
    },
    {
      text: 'composite-controls',
      iconUrl: this.mainMenuPath + 'Dashboard.svg',
      id: '20',
      items: [
        {
          text: 'address',
          url: ADDRESS_NAVIGATION.MAIN,
          id: '2010',
        },
        {
          text: 'itemsList',
          url: ITEMS_LIST_NAVIGATION.MAIN,
          id: '2020',
        },
        {
          text: 'comments',
          url: COMMENTS_NAVIGATION.MAIN,
          id: '2030',
        },
      ],
    },
    {
      text: 'controls',
      iconUrl: this.mainMenuPath + 'Dashboard.svg',
      id: '30',
      items: [
        {
          text: 'dialog',
          url: DEV_DIALOG_NAVIGATION.MAIN,
          id: '3010',
        },
        {
          text: 'buttons',
          url: BUTTONS_NAVIGATION.MAIN,
          id: '3020',
        },
        {
          text: 'stepper',
          url: STEPPER_NAVIGATION.MAIN,
          id: '3030',
        },
        {
          text: 'filter',
          url: FILTER_NAVIGATION.MAIN,
          id: '3040',
        },
        {
          text: 'grid',
          url: GRID_NAVIGATION.MAIN,
          id: '3050',
        },
        {
          text: 'toolbar',
          url: TOOLBAR_NAVIGATION.MAIN,
          id: '3060',
        },
        {
          text: 'priority',
          url: PRIORITY_NAVIGATION.MAIN,
          id: '3070',
        },
        {
          text: 'tabs',
          url: TABS_NAVIGATION.MAIN,
          id: '3080',
        },
        {
          text: 'toast',
          url: TOAST_NAVIGATION.MAIN,
          id: '3090',
        },
        {
          text: 'breadcrumb',
          url: BREADCRUMB_NAVIGATION.MAIN,
          id: '3100',
          items: [
            {
              text: 'sub-breadcrumb',
              url: `${BREADCRUMB_NAVIGATION.MAIN}/${BREADCRUMB_NAVIGATION.SUB}`,
              id: '3101',
            },
          ],
        },
        {
          text: 'progressBar',
          url: PROGRESS_BAR_NAVIGATION.MAIN,
          id: '3110',
        },
        {
          text: 'infoPanel',
          url: INFO_PANEL_NAVIGATION.MAIN,
          id: '3120',
        },
        {
          text: 'workflow',
          url: WORKFLOW_NAVIGATION.MAIN,
          id: '3130',
        },
        {
          text: 'messagePanel',
          url: MESSAGE_PANEL_NAVIGATION.MAIN,
          id: '3140',
        },
        {
          text: 'entityLink',
          url: ENTITY_LINK_NAVIGATION.MAIN,
          id: '3150',
        },
        {
          text: 'pager',
          url: PAGER_NAVIGATION.MAIN,
          id: '3160',
        },
      ],
    },
    {
      text: 'form-controls',
      iconUrl: this.mainMenuPath + 'Dashboard.svg',
      id: '40',
      items: [
        {
          text: 'autocomplete',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.AUTOCOMPLETE}`,
          id: '4010',
        },
        {
          text: 'inputs',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.INPUTS}`,
          id: '4020',
        },
        {
          text: 'selects',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.SELECT}`,
          id: '4030',
        },
        {
          text: 'labels',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.LABEL}`,
          id: '4040',
        },
        {
          text: 'datetimes',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.DATETIME}`,
          id: '4050',
        },
        {
          text: 'text-area',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.TEXTAREA}`,
          id: '4060',
        },
        {
          text: 'checkbox',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.CHECKBOX}`,
          id: '4070',
        },
        {
          text: 'radio',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.RADIO}`,
          id: '4080',
        },
        {
          text: 'editor',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.EDITOR}`,
          id: '4090',
        },
        {
          text: 'switch',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.SWITCH}`,
          id: '4100',
        },
        {
          text: 'multiselect',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.MULTISELECT}`,
          id: '4110',
        },
        {
          text: 'timepicker',
          url: `${INPUTS_NAVIGATION.MAIN}/${INPUTS_NAVIGATION.TIMEPICKER}`,
          id: '4110',
        },
        {
          text: 'upload',
          url: UPLOAD_NAVIGATION.MAIN,
          id: '3150',
        },
      ],
    },
    {
      text: 'layout-controls',
      iconUrl: this.mainMenuPath + 'Dashboard.svg',
      id: '50',
      items: [
        {
          text: 'spinner',
          url: SPINNER_NAVIGATION.MAIN,
          id: '5010',
        },
        {
          text: 'content-wrapper',
          url: CONTENT_WRAPPER_NAVIGATION.MAIN,
          id: '5020',
        },
        {
          text: 'onboarding',
          url: ONBOARDING_NAVIGATION.MAIN,
          id: '5030',
        },
      ],
    },
    {
      text: 'test-page',
      iconUrl: this.mainMenuPath + 'Dashboard.svg',
      url: TEST_NAVIGATION.MAIN,
      id: '60',
    },
  ];

  private profileMenuItems: MenuItem[] = [
    {
      text: 'profile',
      url: PROFILE_NAVIGATION.MAIN,
      id: '1',
    },
  ];

  getMenuItems(): Observable<MenuItem[]> {
    return of(this.menuItems);
  }

  getProfileMenuItems(): Observable<MenuItem[]> {
    return of(this.profileMenuItems);
  }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolButtonModule } from '../../controls/button/asol-button.module';
import { AsolContentWrapperComponent } from './components/asol-content-wrapper.component';

@NgModule({
  declarations: [AsolContentWrapperComponent],
  exports: [AsolContentWrapperComponent],
  imports: [SharedModule, AsolButtonModule],
})
export class AsolContentWrapperModule {}

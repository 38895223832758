import { NgModule } from '@angular/core';
import {
  MenuModule,
  SidebarModule,
  TreeViewModule,
} from '@syncfusion/ej2-angular-navigations';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolButtonModule } from '../../controls/button/asol-button.module';
import { AsolLanguageSelectModule } from '../../controls/language-select/asol-language-select.module';
import { AsolMessagePanelModule } from '../../controls/message-panel/asol-message-panel.module';
import { AsolTenantSelectModule } from '../../controls/tenant-select/asol-tenant-select.module';
import { AsolAboutComponent } from './components/about/about.component';
import { AsolMainLayoutComponent } from './components/main-layout/main-layout.component';

@NgModule({
  declarations: [AsolMainLayoutComponent, AsolAboutComponent],
  exports: [AsolMainLayoutComponent, AsolAboutComponent],
  providers: [],
  imports: [
    SidebarModule,
    MenuModule,
    TreeViewModule,
    SharedModule,
    AsolLanguageSelectModule,
    AsolMessagePanelModule,
    AsolButtonModule,
    AsolTenantSelectModule,
  ],
})
export class MainLayoutModule {}

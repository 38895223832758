<div [ngClass]="{ 'mb-2': showError }" [class]="cssClass">
  <asol-label-field
    *ngIf="labelText"
    [labelTextStyle]="labelTextStyle"
    labelTextClass="mb-0"
    [labelText]="labelText"
    [control]="ngControl.control"
    [isDisabled]="ngControl.control?.disabled"
    [isRequired]="isRequired"></asol-label-field>

  <div class="asol-hint mb-0" *ngIf="hintText">
    {{ hintText }}
  </div>

  <div
    class="e-input-group asol-input"
    [ngClass]="{
      'e-error': ngControl.control.errors && ngControl.control.touched,
      'e-disabled': ngControl.control?.disabled,
      'is-valid': valid && ngControl.control?.value
    }">
    <svg-icon
      *ngIf="prefixIcon"
      [src]="prefixIcon"
      class="e-input-group-icon"></svg-icon>
    <input
      #inputField
      [id]="id ?? 'inputelement'"
      class="e-input"
      [type]="inputType"
      [ngStyle]="{ 'width.px': width }"
      [placeholder]="placeholderText"
      [disabled]="ngControl.control?.disabled"
      [readonly]="isReadonly"
      (change)="callOnChange()"
      (focus)="focusIn($event.target)"
      (blur)="focusOut($event.target)" />

    <svg-icon
      *ngIf="suffixIcon"
      [src]="suffixIcon"
      class="e-input-group-icon"></svg-icon>
    <svg-icon
      *ngIf="loading"
      [src]="ICONS.REFRESH_CW_02"
      class="e-input-group-icon loading-icon"></svg-icon>
    <svg-icon
      *ngIf="!loading && valid && ngControl.control?.value"
      [src]="ICONS.CHECK"
      class="e-input-group-icon success-icon"></svg-icon>
  </div>

  <ng-container *ngIf="showError">
    <div
      class="asol-error d-inline-flex align-items-center"
      *ngFor="let msg of getErrorMessages()">
      <svg-icon [src]="ICONS.ERROR"></svg-icon>
      {{ msg }}
    </div>
  </ng-container>
</div>

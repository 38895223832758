import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CORE_CONSTANTS, TranslationService } from '@asol/core';
import { Subscription } from 'rxjs';
import { ICONS } from './../../../../shared/constants/icon.constant';

@Component({
  selector: 'asol-label-field',
  templateUrl: './label-field.component.html',
  styleUrls: ['./label-field.component.scss'],
})
export class AsolLabelFieldComponent implements OnInit, OnDestroy {
  /** Header text if label is used as readonly field */
  @Input() text?: string | null = null;
  /** Header style if label is used as readonly field */
  @Input() textStyle: unknown;
  /** Main label class */
  @Input() textClass = '';

  /** Main label text */
  @Input() labelText?: string | null = null;
  /** Main label style */
  @Input() labelTextStyle: unknown;
  /** Main label class */
  @Input() labelTextClass = '';
  /** Displays translated label text based on key */
  @Input() labelKey = '';

  /** Translation key */
  @Input() transKey = '';

  /**
   * Flag if template should be rendered
   * @default false
   */
  @Input() useTemplate = false;

  /**
   * Flag if empty text should be replaced with core constant
   * @default false
   */
  @Input() useDefaultText = false;

  @Input() control?: AbstractControl | null;
  @Input() isDisabled: boolean | undefined = false;
  @Input() isRequired: boolean | undefined;
  @Input() width: string | number | null = null;

  /**
   * Custom icon
   * @default ICONS.EDIT_05
   */
  @Input() icon = ICONS.EDIT_05;

  /**
   * Flag to display edit icon
   * @default false
   */
  @Input() editable = false;

  /**
   * Event emitted when the edit icon is clicked.
   */
  @Output() edit = new EventEmitter<void>();

  CORE_CONSTANTS = CORE_CONSTANTS;

  protected subs = new Subscription();

  constructor(protected trans: TranslationService) {}

  ngOnInit(): void {
    this.localizeTexts();
    this.subs.add(
      this.trans.languageChanged$.subscribe(() => {
        this.localizeTexts();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private localizeTexts(): void {
    if (this.labelKey && this.transKey) {
      this.labelText = this.trans.get(this.transKey, this.labelKey);
    }
  }
}

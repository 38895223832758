import { Component, OnDestroy, ViewChild } from '@angular/core';
import {
  AsolMediaQueryService,
  MEDIA_QUERY,
  TranslationService,
} from '@asol/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../../../services/notification-service/notification.service';
import { ONBOARDING_CONST } from '../../constants/onboarding.constants';
import { OnboardingItem } from '../../models/onboarding-item.interface';
import translation from '../../onboarding.translation.json';
import { OnboardingDataSource } from '../../services/onboarding.service';

@Component({
  selector: 'asol-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class AsolOnboardingComponent implements OnDestroy {
  /** Onboarding items collection */
  items: OnboardingItem[] = [];

  /** Current selected item index */
  selectedIndex = 0;
  /** Logo shown in the header of dialog */
  logo: string | null = null;
  /** Background image of dialog header */
  background = ONBOARDING_CONST.BACKGROUND;
  /** Image path of the onboarding image */
  imagePath = '';
  /** Resolution of image */
  resolution = '';

  /**
   * Flag if onboarding should be visible
   */
  showOnboarding = false;

  ONBOARDING_CONST = ONBOARDING_CONST;

  @ViewChild('ejDialog') ejDialog!: DialogComponent;

  private sub = new Subscription();

  constructor(
    public trans: TranslationService,
    private provider: OnboardingDataSource,
    private notificationService: NotificationService,
    public mediaService: AsolMediaQueryService
  ) {
    this.trans.initialize(ONBOARDING_CONST.TRANS, translation);

    this.sub.add(
      this.mediaService.viewportChanged$.subscribe((state) => {
        if (state.breakpoints[MEDIA_QUERY.XXL] === true) {
          this.resolution = '1920px';
          this.refreshPosition();
          this.fillImage();
        }
        if (
          state.breakpoints[MEDIA_QUERY.LG] === true ||
          state.breakpoints[MEDIA_QUERY.XL] === true
        ) {
          this.resolution = '1280px';
          this.refreshPosition();
          this.fillImage();
        }
        if (state.breakpoints[MEDIA_QUERY.MD] === true) {
          this.resolution = '800px';
          this.refreshPosition();
          this.fillImage();
        }
        if (
          state.breakpoints[MEDIA_QUERY.XS] === true ||
          state.breakpoints[MEDIA_QUERY.SM] === true
        ) {
          this.hide();
        }
      })
    );

    this.sub.add(
      this.provider.show$.subscribe((data) => {
        if (!data.items?.length) {
          this.notificationService.warning(
            this.trans.get(ONBOARDING_CONST.TRANS, 'noItems')
          );
          return;
        }
        this.logo = data.logoUrl;
        this.items = data.items;
        this.show();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  /**
   * Event handler after user clicks on skip or next button
   * @param skip Flag if user clicked on skip button
   */
  public onClick(skip: boolean): void {
    if (skip) {
      this.hide();
      return;
    }

    if (this.selectedIndex + 1 === this.items.length) {
      this.hide();
      return;
    }

    this.selectedIndex = this.selectedIndex + 1;
    this.fillImage();
  }

  /**
   * Shows dialog window
   */
  private show(): void {
    this.showOnboarding = true;
    this.fillImage();
    this.ejDialog.show();
    this.ejDialog.refreshPosition();
  }

  /**
   * Hides dialog window
   */
  private hide(): void {
    this.selectedIndex = 0;
    this.showOnboarding = false;
    this.imagePath = '';
    this.ejDialog?.hide();
  }

  /**
   * Fills image from selected item if there is one defined
   */
  private fillImage(): void {
    const item = this.items[this.selectedIndex];
    if (!item || !item.image) {
      return;
    }
    if (item.image) {
      this.imagePath = `${this.ONBOARDING_CONST.ASSETS_PATH}${
        this.trans.getCurrentLanguage().short
      }/${item.image} ${this.resolution}.${item.extension}`;
    } else {
      this.imagePath = '';
    }
  }

  /**
   * Refresher position of dialog window
   */
  private refreshPosition(): void {
    if (this.showOnboarding) {
      this.ejDialog?.refreshPosition();
    }
  }
}

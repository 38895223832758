import {
  AfterViewInit,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ERROR_TRANSLATION, TranslationService } from '@asol/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { ICONS } from '../../../shared/constants/icon.constant';
import { getErrorMessage } from '../../../shared/functions/get-error-message.function';

@Directive()
export abstract class ControlBase<T>
  implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy
{
  ICONS = ICONS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onTouched: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected onChange: any;

  private disabledFlag = false;
  protected value: T | null = null;

  /**
   * Flag if control is focused
   */
  protected hasFocus = false;

  protected subs = new Subscription();
  protected providerSub = new Subscription();

  /** Displays placeholder text */
  @Input() placeholderText = '';
  /** Displays translated placeholder text based on key */
  @Input() placeholderKey = '';

  private _labelKey: string;
  /** Displays translated label text based on key */
  @Input('labelKey')
  set labelKey(value: string) {
    this._labelKey = value;
    this.localizeTexts();
  }
  get labelKey(): string {
    return this._labelKey;
  }

  /** Displays label text */
  @Input() labelText = '';
  @Input() labelTextStyle: unknown;

  /** Translation key */
  @Input() transKey = '';

  /** Displays hint text */
  @Input() hintText = '';
  /** Displays translated hint text based on key */
  @Input() hintKey = '';

  @Input() formControlName = '';

  @Input() isReadonly = false;
  @Input() isRequired: boolean | undefined;
  @Input() cssClass = '';
  @Input() id: string | null = null;
  @Input() width: string | number | null = null;
  /**
   * Debounce time for value changes to emit value
   * @default 1000
   */
  @Input() debounceTime = 1000;

  /**
   * Flag if error should be shown
   *
   * If errors are shown margin of mb-2 is added otherwise it is no margin
   *
   * @default true
   * */
  @Input() showError = true;

  @Output() valueChanged = new EventEmitter<T>();
  protected valueChangedDebouncer: Subject<T> = new Subject<T>();

  @Input('isDisabled')
  set isDisabled(value: boolean) {
    this.setDisabledState(value);
  }
  get isDisabled(): boolean {
    return this.disabledFlag;
  }

  constructor(
    protected trans: TranslationService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.localizeTexts();
    this.subs.add(
      this.trans.languageChanged$.subscribe(() => {
        this.localizeTexts();
      })
    );
    this.subs.add(
      this.valueChangedDebouncer
        .pipe(debounceTime(this.debounceTime))
        .subscribe((value) => this.valueChanged.emit(value))
    );
    if (this.formControlName) {
      this.id = this.formControlName;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setControlDisabled(this.disabledFlag);
      this.updateUI();
    });
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
    this.providerSub?.unsubscribe();
  }

  getErrorMessages(): string[] {
    if (!this.ngControl.control?.touched || !this.ngControl.control?.errors) {
      return [];
    }

    const errorMessages = [];
    for (const err in this.ngControl.control.errors) {
      const errorObject: unknown = this.ngControl.control.errors[err];
      errorMessages.push(getErrorMessage(err, errorObject, this.trans));
    }

    return errorMessages;
  }

  @HostListener('input', ['$event']) propagateValue(event: Event): void {
    this.storeValue();
    this.onChange(this.value);
  }

  @HostListener('focusout', ['$event']) propagateBlur(event: Event): void {
    this.onTouched(event);
    this.hasFocus = false;
    this.providerSub?.unsubscribe();
    this.onFocusOut();
    if (!this.ngControl.control?.errors) {
      this.controlValid();
    }
  }

  @HostListener('focusin', ['$event']) propagateFocusIn(event: Event): void {
    this.hasFocus = true;
  }

  writeValue(obj: T): void {
    this.value = obj;
    this.updateUI();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  protected localizeTexts(): void {
    if (this.labelKey && this.transKey) {
      this.labelText = this.trans.get(this.transKey, this.labelKey);
    }

    if (this.placeholderKey && this.transKey) {
      this.placeholderText = this.trans.get(this.transKey, this.placeholderKey);
    }

    if (this.hintKey && this.transKey) {
      this.hintText = this.trans.get(this.transKey, this.hintKey);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabledFlag = isDisabled;
    this.setControlDisabled(this.disabledFlag);
  }

  protected abstract getControlValue(): T | null;

  protected abstract setControlValue(val: T | null): void;

  protected abstract setControlDisabled(isDisable: boolean): void;

  protected storeValue(): void {
    this.value = this.getControlValue();
  }

  private updateUI(): void {
    this.setControlValue(this.value);
  }

  /**
   * Error message for duplicate error
   */
  protected checkErrorMessage(): string {
    return this.trans.get(ERROR_TRANSLATION, 'duplicate');
  }

  /**
   * Event on focus out
   */
  protected onFocusOut(): void {
    //
  }

  /**
   * Validates user input for duplicates
   * Subclasses can override this method to provide custom validation logic.
   */
  protected controlValid(): void {
    // Default no-op implementation
  }
}

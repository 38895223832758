import { Injectable } from '@angular/core';
import { AuthenticationServiceBase, TenantData, UserData } from '@asol/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements AuthenticationServiceBase {
  updateTenant(tenantId: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  userData: UserData = {
    firstname: 'John',
    lastname: 'Doe',
    userId: '1',
    userName: 'johndoe',
  };
  private loginChangedSubject = new Subject<boolean>();
  /** Emits when user login changes  */
  loginChanged$ = this.loginChangedSubject.asObservable();

  private logged = false;

  logout(): void {
    this.logged = false;
    this.loginChangedSubject.next(false);
  }

  login(): void {
    this.logged = true;
    this.loginChangedSubject.next(true);
  }
  isLoggedIn(): Promise<boolean> {
    return Promise.resolve(this.logged);
  }

  getAccessToken(): Promise<string> {
    throw new Error('Method not implemented.');
  }

  getTenants(): Promise<TenantData[]> {
    return Promise.resolve([
      {
        id: '1',
        name: 'Tenant 1',
      },
      {
        id: '2',
        name: 'Tenant 2',
      },
      {
        id: '3',
        name: 'Tenant 3',
      },
    ]);
  }
}

/**
 * All possible actions defined for workflow step
 */
export enum WorkflowAction {
  CheckRequiredFields,
  CheckOptionalParameters,
  CheckNonRequiredParameters,
  AddComment,
  AddAssignee,
  AllowedActions,
  SendEmail,
  CreateTask,
}

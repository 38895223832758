import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { GENERAL_TRANSLATION, TranslationService } from '@asol/core';
import {
  DropDownButtonComponent,
  ItemModel,
  MenuEventArgs,
  OpenCloseMenuEventArgs,
} from '@syncfusion/ej2-angular-splitbuttons';
import { ICONS } from '../../../../shared/constants/icon.constant';
import { Color } from '../../../../shared/types/color.type';
import { ButtonTextType } from '../models/button-text.type';
import { ButtonType } from '../models/button.type';

@Component({
  selector: 'asol-dropdown-button',
  templateUrl: './asol-dropdown-button.component.html',
  styleUrls: ['./asol-dropdown-button.component.scss'],
})
export class AsolDropDownButtonComponent {
  ICONS = ICONS;
  GENERAL_TRANSLATION = GENERAL_TRANSLATION;

  /**
   * Type of button
   * @default default
   */
  @Input() type: ButtonType = 'default';
  /** Button text based on type */
  @Input() textType: ButtonTextType | null = null;
  /**
   * Button color @see {@link Color}
   * @default primary
   */
  @Input() color: Color = 'primary';
  /**
   * Flag if button is disabled
   * @default false
   */
  @Input() disabled = false;
  /** SVG icon URL, use ICONS constant */
  @Input() iconSvgUrl = '';
  /** SVG icon URL for chevron, use ICONS constant */
  @Input() iconChevronSvgUrl = ICONS.CHEVRON_DOWN;

  /**
   * Hides chevron icon
   */
  @Input() hideChevronIcon = false;
  /** Collection of dropdown items */
  @Input() items: ItemModel[] = [];
  /** Flag if button is active */
  @Input() active = false;
  /** Css class definition to add to button */
  @Input() cssClass?: string;

  /** Event after user selects item from dropdown */
  @Output() itemSelect = new EventEmitter<MenuEventArgs>();

  @Output() open = new EventEmitter<OpenCloseMenuEventArgs>();

  /**
   * Ref for DOM button
   *
   * @type {DropDownButtonComponent}
   */
  @ViewChild('asolDropdownButton')
  asolDropdownButton: DropDownButtonComponent = null;

  constructor(public trans: TranslationService) {}

  /**
   * Event handler after user selects item from dropdown
   * @param args Arguments of selected item @see {@link MenuEventArgs}
   */
  onItemSelect(args: MenuEventArgs): void {
    this.itemSelect.emit(args);
  }

  /**
   * Emits event before popup opens
   * @param args Open close args @see {@link OpenCloseMenuEventArgs}
   */
  onOpen(args: OpenCloseMenuEventArgs): void {
    this.open.emit(args);
  }

  /**
   * Method for adding new items on fly for dropdown button
   *
   * @param {ItemModel[]} items
   */
  addItems(items: ItemModel[]): void {
    this.asolDropdownButton.addItems(items);
  }
}

export const INPUTS_NAVIGATION = {
  MAIN: 'inputs',
  SELECT: 'select',
  TEXTAREA: 'text-area',
  DATETIME: 'datetime',
  LABEL: 'label',
  INPUTS: 'input',
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  EDITOR: 'editor',
  SWITCH: 'switch',
  MULTISELECT: 'multiselect',
  TIMEPICKER: 'timepicker',
};

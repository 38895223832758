import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessagePanelButton } from '../models/message-panel-button.interface';
import { MessagePanelProperties } from '../models/message-panel-properties.interface';
import { MessagePanelType } from '../models/message-panel.enum';
import { MessagePanel } from '../models/message-panel.interface';

@Injectable({
  providedIn: 'root',
})
export class MessagePanelService {
  private messageObservables = new Subject<MessagePanel>();
  /**
   * Subject when a new message panel is created and when it is removed
   */
  public message$ = this.messageObservables.asObservable();

  private buttonClickObservable = new Subject<MessagePanelButton>();
  /**
   * Subject when button is clicked
   */
  public buttonClick$ = this.buttonClickObservable.asObservable();

  private removeAllObservables = new Subject<void>();
  /**
   * Subject when a remove all panels action is triggered
   */
  public removeAll$ = this.removeAllObservables.asObservable();

  /**
   * The remove() is used to delete message panels that have the same ID
   * @param properties Properties of message that should be removed
   */
  public remove(properties: MessagePanelProperties) {
    this.messageObservables.next({
      show: false,
      properties: properties,
    });
  }

  /**
   * Removes all panels from the message panel list
   */
  public removeAll() {
    this.removeAllObservables.next();
  }

  /**
   * Set button name and message panel id to be returned to another component
   */
  public buttonClick(button: MessagePanelButton) {
    this.buttonClickObservable.next(button);
  }
  /**
   * Shows info message panel
   * @param type set the message panel type
   * @param severity severity of the message panel
   * @param show show message panel
   * @param properties message panel custom properties
   */
  public info(properties: MessagePanelProperties) {
    return this.messageObservables.next({
      type: MessagePanelType.info,
      severity: 0,
      show: true,
      properties,
    });
  }
  /**
   * Shows success message panel
   * @param type set the message panel type
   * @param severity severity of the message panel
   * @param show show message panel
   * @param properties message panel custom properties
   */
  public success(properties: MessagePanelProperties) {
    return this.messageObservables.next({
      type: MessagePanelType.success,
      severity: 1,
      show: true,
      properties,
    });
  }
  /**
   * Shows warning message panel
   * @param type set the message panel type
   * @param severity severity of the message panel
   * @param show show message panel
   * @param properties message panel custom properties
   */
  public warning(properties: MessagePanelProperties) {
    return this.messageObservables.next({
      type: MessagePanelType.warning,
      severity: 2,
      show: true,
      properties,
    });
  }
  /**
   * Shows danger message panel
   * @param type set the message panel type
   * @param severity severity of the message panel
   * @param show show message panel
   * @param properties message panel custom properties
   */
  public danger(properties: MessagePanelProperties) {
    return this.messageObservables.next({
      type: MessagePanelType.danger,
      severity: 3,
      show: true,
      properties,
    });
  }
}

import { Injectable } from '@angular/core';
import { ApiDataService } from '@asol/core';
import { Observable, Subject, map, of } from 'rxjs';
import { MODULE_CONST } from '../../../../shared/constants/module.contants';
import { FilterData } from '../../../../shared/models/filter-data.interface';
import { SettingsServiceBase } from '../../../../shared/services/settings-service.base';
import { AggregateDefinition } from '../models/aggregate-definition.interface';
import { AggregateResult } from '../models/aggregate-result.interface';

@Injectable({
  providedIn: 'root',
})
export class AggregateService {
  /**
   * Indicates whether aggregates have been changed
   * @param string Sync identifier
   */
  private refreshSubject = new Subject<string>();
  public refresh$ = this.refreshSubject.asObservable();

  /** Holds all loaded aggregate definitions */
  private aggregateMap = new Map<string, AggregateDefinition[]>();

  constructor(
    protected service: ApiDataService,
    private settingsService: SettingsServiceBase
  ) {}

  /**
   * Gets aggregates for sync identifier
   * @param uri Uri to get aggregates
   * @param syncId Synchronization identifier
   * @param aggregates Aggregate definitions @see {@link AggregateDefinition}
   * @param filters Filter data @see {@link FilterData}
   * @returns Collection of aggregates @see {@link AggregateResult}
   */
  public get(
    uri: string,
    syncId?: string,
    aggregates?: AggregateDefinition[],
    filters?: FilterData
  ): Observable<AggregateResult[] | null> {
    return this.service.postData<AggregateResult[]>(uri, {
      aggregates,
      filters,
    });
  }

  /**
   * Gets stored aggregate definition based on synchronization identifier
   * @param syncId Synchronization identifier
   * @returns Collection of aggregate definitions @see {@link AggregateDefinition}
   */
  public getDefinition(syncId: string): Observable<AggregateDefinition[]> {
    if (this.aggregateMap.has(syncId)) {
      return of(this.aggregateMap.get(syncId));
    }

    return this.settingsService.get(syncId, MODULE_CONST.AGGREGATE).pipe(
      map((settings) => {
        return settings ? (settings as AggregateDefinition[]) : null;
      })
    );
  }

  /**
   * Stores aggregate definition based on synchronization identifier
   * @param syncId Synchronization identifier
   * @param aggregates Aggregate definitions @see {@link AggregateDefinition}
   * @returns True if store was successful
   */
  public storeDefinition(
    syncId: string,
    aggregates: AggregateDefinition[]
  ): Observable<boolean> {
    this.aggregateMap.set(syncId, aggregates);

    if (!aggregates.length) {
      return this.settingsService.delete(syncId, MODULE_CONST.AGGREGATE);
    }

    return this.settingsService.store(
      syncId,
      MODULE_CONST.AGGREGATE,
      JSON.stringify(aggregates)
    );
  }

  /**
   * Refreshes aggregates
   * @param syncId Sync identifier
   */
  public refresh(syncId: string): void {
    this.refreshSubject.next(syncId);
  }
}

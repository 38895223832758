import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AggregationType } from '../../../../../asol-controls/src/lib/modules/controls/aggregate-dialog/enums/aggregation-type.enum';
import { AggregateDefinition } from '../../../../../asol-controls/src/lib/modules/controls/aggregate-dialog/models/aggregate-definition.interface';
import { AggregateResult } from '../../../../../asol-controls/src/lib/modules/controls/aggregate-dialog/models/aggregate-result.interface';
import { AggregateService } from '../../../../../asol-controls/src/lib/modules/controls/aggregate-dialog/services/aggregate.service';
import { FilterData } from '../../../../../asol-controls/src/lib/shared/models/filter-data.interface';

@Injectable({
  providedIn: 'root',
})
export class AggService extends AggregateService {
  public get(
    uri: string,
    syncId?: string,
    aggregates?: AggregateDefinition[],
    filters?: FilterData
  ): Observable<AggregateResult[] | null> {
    console.log('AggService.get()', uri, aggregates, filters);

    return of([
      {
        aggregationColumn: 'CustomerID',
        aggregationType: AggregationType.Average,
        value: this.generateRandomNumber(1000, 20000),
      },
      {
        aggregationColumn: 'Currency',
        aggregationType: AggregationType.Average,
        value: {
          value: this.generateRandomNumber(1000, 20000),
          currency: 'CZK',
        },
      },
      {
        aggregationColumn: 'Currency',
        aggregationType: AggregationType.Amount,
        value: {
          value: this.generateRandomNumber(1000, 20000),
          currency: 'CZK',
        },
      },
    ]);
  }

  private generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}

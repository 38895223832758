<ejs-dropdownlist
  [dataSource]="translationService.getSupportedLanguages"
  [cssClass]="'center-item dropdown-default' + ' ' + cssClass"
  [fields]="{ text: 'short', value: 'short' }"
  (select)="onFlagSelect($event)"
  [value]="translationService.currentLocale">
  <ng-template #itemTemplate #valueTemplate let-data>
    <img
      [src]="ASSETS_BASE.FLAGS + '/' + data.short + ICON_FORMATS.SVG"
      alt="flag" />
  </ng-template>
</ejs-dropdownlist>

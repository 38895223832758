<img
  [src]="imagePath"
  class="onboarding-image"
  *ngIf="imagePath && showOnboarding" />
<ejs-dialog
  id="onboardingDialog"
  #ejDialog
  [isModal]="false"
  class="onboardingDialog"
  [zIndex]="1100"
  [visible]="false">
  <ng-template #content>
    <div class="header">
      <svg-icon [stretch]="true" class="back" [src]="background"></svg-icon>
      <svg-icon [src]="logo"></svg-icon>
    </div>
    <div class="body" *ngIf="items.length">
      <div>
        <h1 class="mt-4 text-center">{{ items[selectedIndex].title }}</h1>
        <h4 class="mt-4 mb-7">{{ items[selectedIndex].highlitedText }}</h4>
        <div class="mt-2">{{ items[selectedIndex].text }}</div>
      </div>
    </div>
    <div
      class="overflow-hidden d-flex justify-content-between align-items-center">
      <div class="col-4"></div>
      <div class="col-4 text-center">
        {{ selectedIndex + 1 }}/{{ items.length }}
      </div>
      <div
        class="col-4 d-flex align-items-end justify-content-end pt-3 pe-5 pb-5">
        <asol-button
          color="light"
          id="onboarding-skip"
          cssClass="me-3"
          *ngIf="items.length !== selectedIndex + 1"
          (click)="onClick(true)">
          {{ trans.get(ONBOARDING_CONST.TRANS, 'skip') }}
        </asol-button>
        <asol-button
          color="primary"
          id="onboarding-continue"
          (click)="onClick(false)">
          {{
            items.length !== selectedIndex + 1
              ? trans.get(ONBOARDING_CONST.TRANS, 'continue')
              : trans.get(ONBOARDING_CONST.TRANS, 'finish')
          }}
        </asol-button>
      </div>
    </div>
  </ng-template>
</ejs-dialog>

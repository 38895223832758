<div class="w-100 h-100 content-panel">
  <h3 class="mb-3">{{ title }}</h3>
  <ng-content></ng-content>
  <div *ngIf="isDirty" class="mt-4">
    <asol-button
      class="me-2"
      color="success"
      textType="Save"
      (click)="onSave()"></asol-button>
    <asol-button
      color="light"
      textType="Discard"
      (click)="onDiscard()"></asol-button>
  </div>
</div>

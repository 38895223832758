import { ValidationErrors } from '@angular/forms';
import {
  ERROR_TRANSLATION,
  REGEX,
  TranslationService,
  stringFormat,
} from '@asol/core';

/**
 * Generates error message based on the validation error type.
 *
 * @param {string} err - The type of validation error.
 * @param {ValidationErrors} errorObject - The validation error object containing error details.
 * @param {TranslationService} trans - The translation service to retrieve localized error messages.
 */
export function getErrorMessage(
  err: string,
  errorObject: ValidationErrors,
  trans: TranslationService
): string {
  if (!err || !errorObject) {
    return '';
  }

  switch (err) {
    case 'required':
      return trans.get(ERROR_TRANSLATION, 'RequiredErrorMessage');
    case 'minlength':
      return stringFormat(trans.get(ERROR_TRANSLATION, 'minValueLength'), [
        errorObject.requiredLength,
        errorObject.actualLength,
      ]);
    case 'maxlength':
      return stringFormat(trans.get(ERROR_TRANSLATION, 'maxValueLength'), [
        errorObject.requiredLength,
        errorObject.actualLength,
      ]);
    case 'min':
      return stringFormat(trans.get(ERROR_TRANSLATION, 'minValue'), [
        errorObject.min,
        errorObject.actual,
      ]);
    case 'max':
      return stringFormat(trans.get(ERROR_TRANSLATION, 'maxValue'), [
        errorObject.max,
        errorObject.actual,
      ]);
    case 'dateRange':
      return trans.get(ERROR_TRANSLATION, 'dateRangeErrorMessage');
    case 'maxValues':
      return trans.get(ERROR_TRANSLATION, 'maxValuesErrorMessage');
    case 'minValues':
      return trans.get(ERROR_TRANSLATION, 'minValuesErrorMessage');
    case 'duplicate':
      return trans.get(ERROR_TRANSLATION, 'duplicate');
    case 'email':
      return trans.get(ERROR_TRANSLATION, 'emailErrorMessage');
    case 'pattern':
      switch (errorObject.requiredPattern) {
        case REGEX.ONLY_NUMBERS:
          return trans.get(ERROR_TRANSLATION, 'patternOnlyNumbersErrorMessage');
        case REGEX.PHONE:
          return trans.get(ERROR_TRANSLATION, 'patternPhoneErrorMessage');
        case REGEX.IBAN:
          return trans.get(ERROR_TRANSLATION, 'patternIbanErrorMessage');
        case REGEX.BIC:
          return trans.get(ERROR_TRANSLATION, 'patternBicErrorMessage');
        default:
          return trans.get(ERROR_TRANSLATION, 'generalValidationErrorMessage');
      }
    default:
      return trans.get(ERROR_TRANSLATION, 'generalValidationErrorMessage');
  }
}

import { Component, Inject } from '@angular/core';
import { Dependency } from '../../models/dependency.interface';
import { Package } from '../../models/package.interface';
import { PACKAGE_CONFIG } from '../../tokens/package-config.token';

@Component({
  selector: 'asol-about',
  templateUrl: './about.component.html',
})
export class AsolAboutComponent {
  public info: Dependency[] = [];
  public name: string;
  public version: string;

  constructor(@Inject(PACKAGE_CONFIG) private config: Package) {
    this.name = this.config.name;
    this.version = this.config.version;

    this.info = Object.entries(this.config.dependencies).map(
      ([name, version]) => ({ name, version })
    );
  }
}

<ejs-sidebar
  #dockBar
  id="dockSidebar"
  [enableDock]="false"
  class="sidebar"
  [ngClass]="{ invisible: !showMainLayout }"
  width="240px"
  [type]="mediaService.isMobile() ? 'Over' : 'Push'"
  [closeOnDocumentClick]="mediaService.isMobile()"
  [enableGestures]="false"
  dockSize="0px">
  <div class="logo center-item">
    <svg-icon [src]="logoUrl"></svg-icon>
  </div>
  <div class="overflow-auto mb-7">
    <ejs-treeview
      class="menu-tree mt-4"
      id="menu-tree"
      #menuTree
      [fields]="fields"
      [animation]="animation"
      (nodeExpanded)="onNodeExpanded($event)"
      (nodeCollapsed)="onNodeExpanded($event, false)"
      (nodeSelected)="navigateToPage($event)"
      expandOn="Click">
      <ng-template #nodeTemplate let-data>
        <div
          class="sub-item w-100 d-flex justify-content-between"
          [routerLink]="data.url ? data.url : null"
          [routerLinkActive]="[data.url ? 'e-url-active' : '']">
          <div class="d-inline-flex">
            <svg-icon
              *ngIf="data.iconUrl"
              class="menuIcon me-2"
              [src]="data.iconUrl"></svg-icon>
            <div class="w-100 text-wrap">
              <a>{{ translation.get(transKey, data.text) }}</a>
            </div>
          </div>
          <svg-icon
            *ngIf="(data.items && data.items.length) || data.hasChildren"
            [src]="
              !data.isExpanded ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP
            "></svg-icon>
        </div>
      </ng-template>
    </ejs-treeview>
  </div>
  <!-- <div class="sidebar-settings center-item">
    <a href="#" class="center-item-v">
      <svg-icon class="me-2 pb-0" src="/assets/icons/settings.svg"></svg-icon>
      {{ translation.get(transKey, 'menuSettings') }}
    </a>
  </div> -->
  <!-- <div class="sidebar-bottom" (click)="toggleSidebar()">Hide</div> -->
</ejs-sidebar>

<div class="main-page">
  <!--header-section  declaration -->
  <nav class="navbar" *ngIf="showMainLayout">
    <ul class="navbar-left">
      <li *ngIf="mediaService.isMobile()">
        <asol-icon-button
          type="flat"
          color="light"
          cssClass="hamburger-button"
          [iconSvgUrl]="ICONS.MENU_01"
          (click)="onToggleSidebar()">
        </asol-icon-button>
      </li>
    </ul>
    <ul>
      <li>
        <asol-tenant-select *ngIf="allowTenants"></asol-tenant-select>
      </li>
    </ul>
    <ul class="navbar-right pe-3">
      <li>
        <asol-language-select
          cssClass="custom-language-input"></asol-language-select>
      </li>
      <li>
        <div class="profile" *ngIf="profileMenuItems.length">
          <ejs-menu
            [items]="profileMenuItems"
            (select)="selectPersonalMenuItem($event)">
            <ng-template #template let-menuItems>
              <div
                *ngIf="menuItems.text"
                class="w-100 d-flex justify-content-between">
                <span class="{{ menuItems.iconCss }} center-item"></span>
                <span class="w-100">
                  <a>{{
                    menuItems.ignoreTranslate
                      ? menuItems.text
                      : translation.get(transKey, menuItems.text)
                  }}</a>
                </span>
              </div>
            </ng-template>
          </ejs-menu>
        </div>
      </li>
    </ul>
  </nav>

  <div [ngClass]="{ 'main-content': showMainLayout }">
    <!--end of header-section  declaration -->
    <div #messagePanel>
      <asol-message-panel></asol-message-panel>
    </div>
    <div
      class="inner"
      [ngStyle]="{
        height: 'calc(100vh - ' + messagePanelHeight + 'px)'
      }">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="message-panel-area">
  <div
    *ngIf="currentPanel"
    class="message-panel d-flex align-items-center justify-content-between w-100 px-6 py-3"
    ngClass="message-panel-{{ currentPanel.type }}">
    <div
      class="d-flex align-items-center user-select-none fw-medium"
      *ngIf="messagePanelList.length >= 2">
      <asol-icon-button
        type="flat"
        color="dark"
        [iconSvgUrl]="ICONS.CHEVRON_LEFT"
        (click)="moveLeft()"></asol-icon-button>
      {{ currentIndexPanel + 1 }}/{{ messagePanelList.length }}
      <asol-icon-button
        type="flat"
        color="dark"
        [iconSvgUrl]="ICONS.CHEVRON_RIGHT"
        (click)="moveRight()"></asol-icon-button>
    </div>
    <div class="d-flex align-items-center flex-grow-1">
      <svg-icon
        class="d-flex align-items-start me-2"
        [src]="map.get(currentPanel.type)"></svg-icon>
      <div>
        {{
          trans.get(
            currentPanel.properties.transKey,
            currentPanel.properties.textKey
          )
        }}
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div *ngFor="let button of currentPanel.properties.buttons">
        <asol-button
          type="flat"
          color="dark"
          (click)="onButtonClick(currentPanel.properties.id, button.textKey)">
          {{ trans.get(button.transKey, button.textKey) }}
        </asol-button>
      </div>

      <svg-icon
        *ngIf="currentPanel.properties.showCloseButton"
        (click)="removeMessage(currentPanel.properties.id)"
        class="close d-flex align-items-center"
        [src]="ICONS.CLOSE"></svg-icon>
    </div>
  </div>
</div>

import { Observable, of } from 'rxjs';
import { AggregationType } from '../../../../../asol-controls/src/lib/modules/controls/aggregate-dialog/enums/aggregation-type.enum';
import { SettingsServiceBase } from '../../../../../asol-controls/src/lib/shared/services/settings-service.base';

/**
 * Represents a service for managing application settings.
 * Extends the base `SettingsServiceBase` class.
 */
export class SettingsService extends SettingsServiceBase {
  private settingsMap: Map<string, unknown> = new Map<string, unknown>();

  constructor() {
    super();

    this.settingsMap.set('aggregate|gridFilter', [
      {
        aggregationColumn: 'CustomerID',
        aggregationType: AggregationType.Average,
        suffix: null,
        color: null,
      },
      {
        aggregationColumn: 'Currency',
        aggregationType: AggregationType.Average,
        suffix: null,
        color: null,
      },
    ]);

    this.settingsMap.set('filter|gridFilter', {
      filter: {
        isComplex: false,
        field: 'ShipName',
        operator: 'equal',
        value: 'dfefe',
        ignoreCase: false,
      },
      search: {
        isComplex: false,
        field: 'NotUsed',
        operator: 'contains',
        value: 'test',
        ignoreCase: false,
      },
    });
  }

  public store(
    id: string,
    module: string,
    settings: string
  ): Observable<boolean> {
    console.log('SettingsService ~ store ~ id:', id, module, settings);
    this.settingsMap.set(this.getId(id, module), JSON.parse(settings));
    console.log('SettingsService ~ store ~ settingsMap:', this.settingsMap);
    return of(true);
  }

  /**
   * Gets setting by id
   * @param id Entity identifier that was used to store setting
   */
  public get(id: string, module: string): Observable<unknown> {
    console.log('SettingsService ~ get ~ id:', id, module);
    return of(this.settingsMap.get(this.getId(id, module)));
  }

  /**
   * Deletes setting by id
   * @param id Entity identifier that was used to store setting
   */
  public delete(id: string, module: string): Observable<boolean> {
    console.log('SettingsService ~ delete ~ id:', id, module);
    this.settingsMap.delete(this.getId(id, module));
    return of(true);
  }

  private getId(id: string, module: string): string {
    return `${module}|${id}`;
  }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from '@asol/core';
import { Subscription } from 'rxjs';
import { ICONS } from '../../../../shared/constants/icon.constant';
import { MESSAGE_PANEL_CONSTANT } from '../constants/message-panel.constant';
import translation from '../message-panel.translation.json';
import { MessagePanel } from '../models/message-panel.interface';
import { MessagePanelService } from '../services/message-panel.service';

@Component({
  selector: 'asol-message-panel',
  templateUrl: './asol-message-panel.component.html',
  styleUrls: ['./asol-message-panel.component.scss'],
})
export class AsolMessagePanelComponent implements OnInit, OnDestroy {
  /** Subscription holder */
  private subscription: Subscription;
  /** Active message panel list */
  public messagePanelList: MessagePanel[] = [];
  /** Current message panel index */
  public currentIndexPanel: number;
  /** Currently visible message panel */
  public currentPanel: MessagePanel | null = null;
  /** Remove all subscription holder */
  private removeAllSub: Subscription;

  ICONS = ICONS;
  MESSAGE_PANEL_CONSTANT = MESSAGE_PANEL_CONSTANT;

  /**
   * Map types and assign icons to them
   */
  public map = new Map<string, string>([
    ['danger', ICONS.DANGER],
    ['success', ICONS.SUCCESS],
    ['warning', ICONS.WARNING],
    ['info', ICONS.INFO],
  ]);

  constructor(
    public trans: TranslationService,
    public messagePanelService: MessagePanelService,
    private cdf: ChangeDetectorRef
  ) {
    this.trans.initialize(MESSAGE_PANEL_CONSTANT.TRANS, translation);
  }

  ngOnInit() {
    this.subscription = this.messagePanelService.message$.subscribe(
      (messagePanel) => {
        messagePanel.show
          ? this.addMessage(messagePanel)
          : this.removeMessage(messagePanel.properties.id);
      }
    );
    /** remove all subscription initialization */
    this.removeAllSub = this.messagePanelService.removeAll$.subscribe(() => {
      /** remove all message panels */
      this.messagePanelList.splice(0, this.messagePanelList.length);
      this.currentPanel = null;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.removeAllSub?.unsubscribe();
  }

  /** Display another message panel from list when click on button left  */
  moveLeft() {
    this.currentIndexPanel--;

    if (this.currentIndexPanel === -1) {
      this.currentIndexPanel = this.messagePanelList.length - 1;
    }

    this.currentPanel = this.messagePanelList[this.currentIndexPanel];
  }

  /** Display another message panel from list when click on button right */
  moveRight() {
    this.currentIndexPanel++;

    if (this.currentIndexPanel === this.messagePanelList.length) {
      this.currentIndexPanel = 0;
    }

    this.currentPanel = this.messagePanelList[this.currentIndexPanel];
  }

  /**
   * Find object by id and remove it
   * @param id message panel id
   */
  public removeMessage(id: string) {
    if (!id) {
      return;
    }

    const index = this.messagePanelList.findIndex(
      (p) => p.properties.id === id
    );
    /** in case message panel exists and was found */
    if (index >= 0) {
      this.messagePanelList.splice(index, 1);
      this.currentPanel = null;
      this.sortPanels();
    }
  }
  /**
   * Add new message panel
   * @param messagePanel new created message panel
   */
  private addMessage(messagePanel: MessagePanel) {
    /** Return when message panel is the same as the last message Panel */
    if (
      this.messagePanelList.some(
        (p) => p.properties.id === messagePanel.properties.id
      )
    ) {
      this.sortPanels();
      return;
    }

    /** Create new message panel */
    this.messagePanelList.push(messagePanel);
    this.sortPanels();
    this.cdf.detectChanges();
  }

  /**
   * Event handler when user clicks on button in panel
   */
  public onButtonClick(id: string, buttonText: string) {
    this.messagePanelService.buttonClick({ id: id, textKey: buttonText });
  }

  /**
   * Sort panel based on severity
   */
  private sortPanels(): void {
    if (!this.messagePanelList.length) {
      return;
    }

    this.messagePanelList = this.messagePanelList.sort(
      (a, b) => b.severity - a.severity
    );
    this.currentIndexPanel = 0;
    this.currentPanel = this.messagePanelList[0];
  }
}

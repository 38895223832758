import { Component, Input } from '@angular/core';
import { Color } from '../../../../shared/types/color.type';
import { ButtonType } from '../models/button.type';

@Component({
  selector: 'asol-icon-button',
  templateUrl: './asol-icon-button.component.html',
})
export class AsolIconButtonComponent {
  /** Button text based on type */
  @Input() type: ButtonType = 'default';
  /**
   * Button color @see {@link Color}
   * @default primary
   */
  @Input() color: Color = 'primary';
  /** SVG icon URL, use ICONS constant */
  @Input() iconSvgUrl = '';
  /**
   * Flag if button is disabled
   * @default false
   */
  @Input() disabled = false;
  /** Flag if button is active
   * @default false
   */
  @Input() active = false;

  /** Css class definition to add to button */
  @Input() cssClass = '';
}

<svg
  *ngIf="color"
  width="100%"
  height="100%"
  viewBox="0 0 330 100"
  preserveAspectRatio="none"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <rect width="330" height="100" fill="#0E2642" />
  <path
    d="M0 100H330V0H0V100Z"
    [attr.fill]="'url(#paint0_linear_4268_148139' + id + ')'" />
  <defs>
    <linearGradient
      id="{{ 'paint0_linear_4268_148139' + id }}"
      x1="51.1227"
      y1="134.855"
      x2="147.124"
      y2="6.02002"
      gradientUnits="userSpaceOnUse">
      <stop [attr.stop-color]="color" />
      <stop stop-color="#F8BF27" />
      <stop offset="0.0001" [attr.stop-color]="color" />
      <stop offset="0.04" [attr.stop-color]="color" stop-opacity="0.84" />
      <stop offset="0.09" [attr.stop-color]="color" stop-opacity="0.67" />
      <stop offset="0.15" [attr.stop-color]="color" stop-opacity="0.51" />
      <stop offset="0.21" [attr.stop-color]="color" stop-opacity="0.37" />
      <stop offset="0.28" [attr.stop-color]="color" stop-opacity="0.25" />
      <stop offset="0.35" [attr.stop-color]="color" stop-opacity="0.16" />
      <stop offset="0.44" [attr.stop-color]="color" stop-opacity="0.09" />
      <stop offset="0.54" [attr.stop-color]="color" stop-opacity="0.04" />
      <stop offset="0.68" [attr.stop-color]="color" stop-opacity="0" />
      <stop offset="0.744792" [attr.stop-color]="color" stop-opacity="0" />
      <stop offset="1" [attr.stop-color]="color" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>

<button
  type="button"
  class="e-btn d-inline-flex"
  [disabled]="disabled"
  [ngClass]="{ 'e-active': active }"
  [class]="'e-' + color + ' e-' + type + ' ' + cssClass">
  <svg-icon
    [src]="iconSvgUrl"
    *ngIf="iconPosition === 'left' && iconSvgUrl"
    class="me-1 btn-icon center-item"></svg-icon>
  <ng-content></ng-content>
  {{ textType ? this.trans.get(GENERAL_TRANSLATION, textType) : '' }}
  <svg-icon
    [src]="iconSvgUrl"
    class="ms-1 btn-icon center-item"
    *ngIf="iconPosition === 'right' && iconSvgUrl"></svg-icon>
</button>

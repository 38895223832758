<div class="container">
  <h1>{{ trans.get(ONBOARDING_CONST.TRANS, 'onboarding') }}</h1>

  <form [formGroup]="formGroup">
    <asol-select-field
      formControlName="language"
      [options]="trans.getSupportedLanguages"
      valueProperty="locale"
      displayProperty="name"
      [transKey]="ONBOARDING_CONST.TRANS"
      labelKey="language">
    </asol-select-field>

    <div class="row">
      <div class="col-12 col-lg-6">
        <asol-input-field
          [transKey]="ONBOARDING_CONST.TRANS"
          labelKey="title"
          formControlName="title">
        </asol-input-field>
      </div>
      <div class="col-12 col-lg-6">
        <asol-input-field
          [transKey]="ONBOARDING_CONST.TRANS"
          labelKey="module"
          formControlName="module">
        </asol-input-field>
      </div>
    </div>

    <asol-text-area
      [transKey]="ONBOARDING_CONST.TRANS"
      labelKey="highlitedText"
      formControlName="highlitedText">
    </asol-text-area>

    <asol-text-area
      [transKey]="ONBOARDING_CONST.TRANS"
      labelKey="text"
      formControlName="text">
    </asol-text-area>

    <div class="row">
      <div class="col-12 col-lg-6">
        <asol-input-field
          [transKey]="ONBOARDING_CONST.TRANS"
          labelKey="image"
          formControlName="image">
        </asol-input-field>
      </div>
      <div class="col-12 col-lg-6">
        <asol-input-field
          [transKey]="ONBOARDING_CONST.TRANS"
          labelKey="extension"
          formControlName="extension">
        </asol-input-field>
      </div>
    </div>
  </form>

  <asol-button (click)="onCreate()" type="outline">
    {{ trans.get(GENERAL_TRANSLATION, 'create') }}
  </asol-button>
</div>

<div [ngClass]="{ 'mb-2': showError }" [class]="cssClass">
  <asol-label-field
    *ngIf="labelText"
    [labelTextStyle]="labelTextStyle"
    labelTextClass="mb-0"
    [labelText]="labelText"
    [control]="ngControl.control"
    [isDisabled]="ngControl.control?.disabled"
    [isRequired]="isRequired"></asol-label-field>

  <div class="asol-hint mb-0" *ngIf="hintText">
    {{ hintText }}
  </div>

  <ejs-combobox
    [id]="id ?? 'comboelement'"
    class="asol-select"
    #comboBox
    [ngClass]="{
      'e-error': ngControl.control.errors && ngControl.control.touched
    }"
    [dataSource]="options"
    [fields]="field"
    [placeholder]="placeholderText"
    [readonly]="isReadonly"
    [enabled]="!isDisabled"
    [width]="width"
    [locale]="locale"
    [popupWidth]="popupWidth"
    [autofill]="autofill"
    [showClearButton]="showClearButton"
    [allowFiltering]="allowFiltering"
    (focus)="onFocus()"
    (change)="callOnChange($event)"
    [allowCustom]="allowCustom">
  </ejs-combobox>

  <ng-container *ngIf="showError">
    <div
      class="asol-error d-inline-flex align-items-center"
      *ngFor="let msg of getErrorMessages()">
      <svg-icon [src]="ICONS.ERROR"></svg-icon>
      {{ msg }}
    </div>
  </ng-container>
</div>

import { WorkflowAction } from '../../../../../asol-controls/src/lib/modules/controls/workflow-actions/enums/workflow-action.enum';
import { ModuleActionsMap } from '../../../../../asol-controls/src/lib/modules/controls/workflow-actions/models/module-actions-map.interface';

export const workflowActionsModuleMap: ModuleActionsMap[] = [
  {
    module: 1,
    actions: [
      WorkflowAction.CheckRequiredFields,
      WorkflowAction.CheckOptionalParameters,
      WorkflowAction.CheckNonRequiredParameters,
      WorkflowAction.AddComment,
      WorkflowAction.AddAssignee,
      WorkflowAction.AllowedActions,
      WorkflowAction.SendEmail,
      WorkflowAction.CreateTask,
    ],
  },
];

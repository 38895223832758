import { Component, Input, OnInit } from '@angular/core';
import {
  createSpinner,
  hideSpinner,
  showSpinner,
} from '@syncfusion/ej2-angular-popups';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'asol-spinner',
  templateUrl: './asol-spinner.component.html',
})
export class AsolSpinnerComponent implements OnInit {
  /**
   * Custom width of spinner
   *
   * @type {string}
   */
  @Input() width: string | null = null;

  constructor(private spinner: SpinnerService) {}

  ngOnInit(): void {
    this.createSpinner(this.width);
    this.startSpinnerHandler();
  }

  /**
   * Subscribe to spinner service for watching and react to spinner events
   *
   * @private
   */
  private startSpinnerHandler(): void {
    this.spinner.spin$.subscribe((spin) => {
      if (spin) {
        this.showSpinner();
      } else {
        this.hideSpinner();
      }
    });
  }

  /**
   * Create spinner DOM element with default width
   *
   * @private
   * @param {?string} [width] Optional width of spinner
   */
  private createSpinner(width?: string): void {
    createSpinner({
      target: document.getElementById('global-progress-container'),
      width: width ? width : '',
    });
  }

  /**
   * Show spinner
   *
   * @private
   */
  private showSpinner(): void {
    showSpinner(document.getElementById('global-progress-container'));
  }

  /**
   * Hide spinner
   *
   * @private
   */
  private hideSpinner(): void {
    hideSpinner(document.getElementById('global-progress-container'));
  }
}

import { NgModule } from '@angular/core';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { AsolLanguageSelectComponent } from './components/asol-language-select.component';

@NgModule({
  declarations: [AsolLanguageSelectComponent],
  exports: [AsolLanguageSelectComponent],
  imports: [DropDownListModule],
})
export class AsolLanguageSelectModule {}

<button
  #asolDropdownButton
  type="button"
  ejs-dropdownbutton
  cssClass="e-caret-hide asol-custom d-inline-flex"
  [items]="items"
  [disabled]="disabled"
  [ngClass]="{ 'e-active': active, 'p-0': hideChevronIcon }"
  [class]="'e-' + color + ' e-' + type + ' ' + cssClass"
  (open)="onOpen($event)"
  (select)="onItemSelect($event)">
  <svg-icon
    [src]="iconSvgUrl"
    *ngIf="iconSvgUrl"
    [ngClass]="{ 'me-1': !hideChevronIcon }"
    class="btn-icon center-item"></svg-icon>
  <ng-content></ng-content>
  {{ textType ? this.trans.get(GENERAL_TRANSLATION, textType) : '' }}
  <svg-icon
    *ngIf="!hideChevronIcon"
    class="dropdown-icon ms-1"
    [src]="iconChevronSvgUrl"></svg-icon>
</button>

import { MenuItem } from '../models/menu-item.interface';

/**
 * Searches the tree structure and finds item with id
 * @param items Items collection to search
 * @param id Id to find
 * @returns Found node or null
 */
export function searchTree(items: MenuItem[], id: string): MenuItem | null {
  let foundItem: MenuItem | null = null;

  for (const item of items) {
    if (item.id === id) {
      foundItem = item;
    }
    if (item.items?.length && !foundItem) {
      foundItem = searchTree(item.items, id);
    }
  }

  // not found
  return foundItem;
}

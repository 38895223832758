import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[requiredAsterisk]',
})
export class RequiredAsteriskDirective implements OnInit, OnChanges, OnDestroy {
  @Input() control: AbstractControl | null = null;
  @Input() isRequired: boolean | undefined;

  /** Subscription to check validity of asterisk */
  private sub: Subscription;

  constructor(
    private elementRef: ElementRef,
    private detector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    /** checks control for required property */
    this.sub = this.control?.statusChanges.subscribe(() => {
      this.setAsterisk();
      this.detector.detectChanges();
    });
  }

  ngOnChanges(): void {
    this.setAsterisk();
  }

  ngOnDestroy(): void {
    /** unsubscribes from status changes observable */
    this.sub?.unsubscribe();
  }

  /**
   * Adds/Removes asterisk from DOM
   */
  private setAsterisk(): void {
    if (!this.control && !this.isRequired) {
      return;
    }

    const controlIsRequired =
      this.isRequired !== undefined
        ? this.isRequired
        : this.control?.hasValidator(Validators.required) ||
          this.control?.hasValidator(Validators.requiredTrue);
    if (controlIsRequired) {
      this.elementRef.nativeElement.innerHTML = '*';
      this.elementRef.nativeElement.classList.add('required');
    } else {
      this.elementRef.nativeElement.innerHTML = '';
    }
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AsolMediaQueryService,
  AuthenticationServiceBase,
  MEDIA_QUERY,
  TranslationService,
} from '@asol/core';
import {
  MenuEventArgs,
  MenuItemModel,
  NodeAnimationSettingsModel,
  NodeExpandEventArgs,
  NodeSelectEventArgs,
  SidebarComponent,
  TreeViewComponent,
} from '@syncfusion/ej2-angular-navigations';
import { ICONS } from '../../../../../shared/constants/icon.constant';
import { NAVIGATION } from '../../../../../shared/constants/navigation.constant';
import { MessagePanelService } from '../../../../controls/message-panel/services/message-panel.service';
import { SpinnerService } from '../../../spinner/services/spinner.service';
import { LAYOUT } from '../../constants/layout.constant';
import { searchTree } from '../../functions/search-tree.function';
import { MenuItem } from '../../models/menu-item.interface';
import { MenuProvider } from '../../services/menu.provider';

@Component({
  selector: 'asol-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class AsolMainLayoutComponent implements OnInit, AfterViewInit {
  /** Translation key */
  @Input() transKey = '';
  /** Provider to load the side menu content */
  @Input() menuProvider: MenuProvider | null = null;

  /** Logo URL shown in upper left corner */
  @Input() logoUrl = `/assets/icons/qasida-logo2.svg`;

  /** Allow tenant picker */
  @Input() allowTenants = true;

  /** Profile menu items */
  public profileMenuItems: MenuItem[] = [];

  /** Menu data items */
  menuData: MenuItem[] = [];

  /**
   * Flag if main layout should be shown
   * Only authenticated user can access main layout
   * @default false
   */
  showMainLayout = false;

  ICONS = ICONS;
  MEDIA_QUERY = MEDIA_QUERY;

  @ViewChild('menuTree')
  menuTree: TreeViewComponent | null = null;
  @ViewChild('dockBar')
  public sidebarInstance: SidebarComponent | null = null;

  /** Message panel div reference */
  @ViewChild('messagePanel') messagePanelRef: ElementRef | null = null;

  /** Message panel height for total height calculation */
  public messagePanelHeight = LAYOUT.TOP_MENU_HEIGHT;

  /**
   * Default animation setup
   */
  public animation: NodeAnimationSettingsModel = {
    collapse: { effect: 'SlideUp', duration: 0, easing: '' },
    expand: { effect: 'SlideDown', duration: 0, easing: '' },
  };

  /**
   * Default field setup
   */
  public fields = {
    id: 'id',
    text: 'text',
    iconCss: '',
    child: 'items',
    hasChildren: 'hasChildren',
    parentID: 'parentId',
    expanded: 'isExpanded',
  };

  /** Flag if menu was loaded */
  private menuLoaded = false;

  constructor(
    private router: Router,
    public translation: TranslationService,
    public mediaService: AsolMediaQueryService,
    private spinner: SpinnerService,
    private messagePanelService: MessagePanelService,
    public authService: AuthenticationServiceBase
  ) {
    this.spinner.show();

    // handle login changes
    this.authService.loginChanged$.subscribe((isLogged: boolean) => {
      this.showMainLayout = isLogged;
      if (!isLogged) {
        this.spinner.hide();
        this.sidebarInstance?.hide();
        return;
      }
      this.loadMenu();
      this.prepareProfileMenu();
      this.spinner.hide();
      if (!this.mediaService.isMobile() && this.showMainLayout) {
        this.sidebarInstance?.show();
      }
    });

    // Listes to storage for token changes
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      this.authService.isLoggedIn().then(() => {
        this.router.navigate(['']);
      });
    });
  }

  ngOnInit(): void {
    this.translation.languageChanged$.subscribe((value) => {
      if (value.isInteracted) {
        this.loadMenu(true);
        this.prepareProfileMenu();
      }
    });

    // Hide sidebar on mobile
    this.mediaService.mobileViewportChanged$.subscribe((state) => {
      if (!state.matches && this.showMainLayout) {
        this.sidebarInstance?.show();
      }
    });
  }

  ngAfterViewInit(): void {
    // checks if user is not already logged in
    this.checkAuth();

    if (!this.mediaService.isMobile() && this.showMainLayout) {
      this.sidebarInstance?.show();
    }

    /** Calculate total height with message panel height with responsivity */
    this.mediaService.viewportChanged$.subscribe(() => {
      this.recalculateMainLayoutHeight();
    });

    /** Calculate total height with message panel height when message panel is added or removed */
    this.messagePanelService.message$.subscribe(() => {
      this.recalculateMainLayoutHeight();
    });
  }

  /**
   * Method checks if user is authenticated and shows main layout
   */
  private checkAuth(): void {
    this.authService.isLoggedIn().then((isLogged: boolean) => {
      this.showMainLayout = isLogged;
      this.spinner.hide();

      if (!isLogged) {
        return;
      }

      this.prepareProfileMenu();
      this.loadMenu();
    });
  }

  /**
   * Loads side menu items
   */
  private loadMenu(refresh = false): void {
    if (this.menuLoaded && !refresh) {
      return;
    }

    this.menuProvider?.getMenuItems().subscribe((data) => {
      if (!this.menuTree) {
        return;
      }

      this.menuData = data;

      this.menuTree.fields = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataSource: this.menuData as any,
        id: 'id',
        text: 'text',
        child: 'items',
        expanded: 'isExpanded',
        hasChildren: 'hasChildren',
        parentID: 'parentId',
      };

      this.menuLoaded = true;

      if (refresh) {
        this.menuTree.refresh();
      }
    });
  }

  /**
   * Creates profile menu items
   */
  public prepareProfileMenu(name?: string) {
    if (!this.authService.userData && !name) {
      return;
    }

    const loggedUserName = name
      ? name
      : `${this.authService.userData.firstname} ${this.authService.userData.lastname}`;
    this.profileMenuItems.splice(0, this.profileMenuItems.length);
    this.profileMenuItems = [
      {
        text: loggedUserName,
        ignoreTranslate: true,
        items: [
          {
            text: 'logout',
            id: LAYOUT.LOGOUT,
            url: NAVIGATION.LOGOUT,
          },
          {
            text: 'about',
            id: LAYOUT.ABOUT,
            url: NAVIGATION.ABOUT,
          },
        ],
      },
    ];
    this.menuProvider?.getProfileMenuItems().subscribe((data) => {
      if (!data) {
        return;
      }

      this.profileMenuItems[0].items?.splice(0, 0, ...data);
    });
  }

  /**
   * Event handler when user selects a profile menu item
   * @param e Menu event args
   */
  selectPersonalMenuItem(e: MenuEventArgs): void {
    if (e.item.items.length > 0) {
      return;
    }
    switch (e.item.id) {
      case LAYOUT.LOGOUT:
        this.spinner.show();
        this.authService.logout();
        break;
      default:
        if (e.item.url) {
          this.router.navigate([e.item.url]);
        }
        break;
    }
  }

  /**
   * Toggles sidebar
   */
  onToggleSidebar(): void {
    this.sidebarInstance?.toggle();
  }

  /**
   * Event handler when user selects a menu item, it will navigate to page only if it is not collapsed
   * @param args Selected node args
   */
  navigateToPage(args: NodeSelectEventArgs): void {
    const data: MenuItemModel[] | undefined = this.menuTree?.getTreeData(
      args.node
    );
    if (!data || !data.length || !data[0].url) {
      return;
    }
    this.router.navigate([data[0].url]);
  }

  /**
   * Event handler when user expands/collapses a menu item
   * @param args Expanded node args
   * @param expand Flag if node should be expanded or collapsed
   */
  onNodeExpanded(args: NodeExpandEventArgs, expand = true): void {
    const menuItem = searchTree(this.menuData, args.nodeData.id as string);
    if (!menuItem) {
      return;
    }

    this.menuTree.refreshNode(args.node, [{ isExpanded: expand }]);
  }

  /**
   * Recalculates the main layout if there is message panel instance
   */
  private recalculateMainLayoutHeight(): void {
    if (!this.messagePanelRef) {
      return;
    }

    /** calculated height with message panel */
    this.messagePanelHeight =
      this.messagePanelRef?.nativeElement.offsetHeight + LAYOUT.TOP_MENU_HEIGHT;
  }
}

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeSk from '@angular/common/locales/sk';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  API_BASE_URI,
  AuthenticationServiceBase,
  DEFAULT_LANGUAGE,
  EN,
  LANGUAGES,
  MAIN_APP,
  MAIN_APP_CONST,
  TranslationService,
} from '@asol/core';
import { L10n } from '@syncfusion/ej2-base';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AsolLandingPageModule } from 'projects/asol-controls/src/lib/modules/layout-controls/landing-page/landing-page.module';
import { MainLayoutModule } from 'projects/asol-controls/src/lib/modules/layout-controls/main-layout/main-layout.module';
import { AsolOnboardingModule } from 'projects/asol-controls/src/lib/modules/layout-controls/onboarding/onboarding.module';
import { OnboardingDataSource } from 'projects/asol-controls/src/lib/modules/layout-controls/onboarding/services/onboarding.service';
import { AsolSpinnerModule } from 'projects/asol-controls/src/lib/modules/layout-controls/spinner/asol-spinner.module';
import { AsolDataColumnModule } from 'projects/asol-controls/src/lib/shared/directives/asol-data-column/asol-data-column.module';
import { AggregateService } from '../../../asol-controls/src/lib/modules/controls/aggregate-dialog/services/aggregate.service';
import { MODULE_ACTIONS_MAP } from '../../../asol-controls/src/lib/modules/controls/workflow-actions/tokens/module-actions-map.token';
import { SettingsServiceBase } from '../../../asol-controls/src/lib/shared/services/settings-service.base';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './core/authentication/authentication.service';
import { LANGUAGES_CONFIG } from './core/providers/languages.provider';
import EjsDatePickerTranslate from './shared/component-translation/ejs-datepicker.translate.json';
import EjsDateTimePickerTranslate from './shared/component-translation/ejs-datetimepicker.translate.json';
import EjsGridTranslate from './shared/component-translation/ejs-grid.translate.json';
import EjsScheduleTranslate from './shared/component-translation/ejs-schedule.translate.json';
import { workflowActionsModuleMap } from './shared/constants/workflow-actions-module-map.constant';
import { AggService } from './shared/services/agg.service';
import { SettingsService } from './shared/services/settings.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MainLayoutModule,
    AsolDataColumnModule,
    AsolSpinnerModule,
    AsolOnboardingModule,
    AsolLandingPageModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    OnboardingDataSource,
    {
      provide: LANGUAGES,
      useValue: LANGUAGES_CONFIG,
    },
    {
      provide: DEFAULT_LANGUAGE,
      useValue: EN,
    },
    {
      provide: AuthenticationServiceBase,
      useClass: AuthenticationService,
    },
    {
      provide: SettingsServiceBase,
      useClass: SettingsService,
    },
    {
      provide: AggregateService,
      useClass: AggService,
    },
    {
      provide: MAIN_APP,
      useValue: MAIN_APP_CONST.SERVICE,
    },
    {
      provide: MODULE_ACTIONS_MAP,
      useValue: workflowActionsModuleMap,
    },
    {
      provide: LOCALE_ID,
      deps: [TranslationService],
      useFactory: (trans: { currentLocale: string }) => trans.currentLocale,
    },
    {
      provide: API_BASE_URI,
      useValue: 'http://localhost:25924/api/v1/',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeSk, 'sk-SK');
    registerLocaleData(localeSk, 'sk');
    registerLocaleData(localeCs, 'cs-CZ');
    registerLocaleData(localeCs, 'cs');

    L10n.load(EjsDateTimePickerTranslate);
    L10n.load(EjsDatePickerTranslate);
    L10n.load(EjsScheduleTranslate);
    L10n.load(EjsGridTranslate);
  }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolButtonModule } from '../button/asol-button.module';
import { AsolMessagePanelComponent } from './components/asol-message-panel.component';

@NgModule({
  declarations: [AsolMessagePanelComponent],
  exports: [AsolMessagePanelComponent],
  imports: [SharedModule, AsolButtonModule],
  providers: [],
})
export class AsolMessagePanelModule {}

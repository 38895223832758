import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolLabelFieldModule } from '../label-field/asol-label-field.module';
import { AsolTextAreaComponent } from './components/text-area.component';

@NgModule({
  declarations: [AsolTextAreaComponent],
  exports: [AsolTextAreaComponent],
  imports: [SharedModule, AsolLabelFieldModule],
})
export class AsolTextAreaModule {}

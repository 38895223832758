import { NgModule } from '@angular/core';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolLabelFieldModule } from '../label-field/asol-label-field.module';
import { AsolSelectFieldComponent } from './components/select-field.component';

@NgModule({
  declarations: [AsolSelectFieldComponent],
  exports: [AsolSelectFieldComponent],
  imports: [SharedModule, ComboBoxModule, AsolLabelFieldModule],
})
export class AsolSelectFieldModule {}

<button
  type="button"
  class="e-btn asol-icon"
  [disabled]="disabled"
  [ngClass]="{ 'e-active': active }"
  [class]="'e-' + color + ' e-' + type + ' ' + cssClass">
  <svg-icon
    [src]="iconSvgUrl"
    *ngIf="iconSvgUrl"
    class="btn-icon center-item"></svg-icon>
  <!-- <svg-icon
    [src]="iconSvgUrl"
    class="ms-1 btn-icon center-item"
    *ngIf="iconPosition === 'right' && iconSvgUrl"></svg-icon> -->
</button>

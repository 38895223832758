import { ContentChild, Directive, Input } from '@angular/core';
import { SelectedItem } from '@asol/core';
import { Column, TextAlign } from '@syncfusion/ej2-angular-grids';
import { Observable } from 'rxjs';
import { AggregateColumn } from '../../models/aggregate-column.interface';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Directive({
  selector: 'asol-column',
})
export class AsolDataColumnDirective implements AggregateColumn {
  // Grid fields

  /**
   * If `allowResizing` set to false, it disables resize option of a particular column.
   *
   * @default true
   */
  @Input() allowResizing = true;
  /**
   * If `allowSorting` set to false, then it disables sorting option of a particular column.
   * By default all columns are sortable.
   *
   * @default true
   */
  @Input() allowSorting = true;
  /**
   * If `allowFiltering` set to false, then it disables filtering option and filter bar element of a particular column.
   * By default all columns are filterable.
   *
   * @default true
   */
  @Input() allowFiltering = true;
  /**
   * If `allowReordering` set to false, then it disables reorder of a particular column.
   * By default all columns can be reorder.
   *
   * @default true
   */
  @Input() allowReordering = true;
  /**
   * If `allowGrouping` set to false, then it disables grouping of a particular column.
   * By default all columns are groupable.
   *
   * @default true
   */
  @Input() allowGrouping = true;
  /**
   * If `allowSearching` set to false, then it disables Searching of a particular column.
   * By default all columns allow Searching.
   *
   * @default false
   */
  @Input() allowSearching = false;
  /**
   * If `isPrimaryKey` is set to true, considers this column as the primary key constraint.
   *
   * @default false
   */
  @Input() isPrimaryKey = false;

  /**
   * IF true than column wont be removed from grid
   *
   * @default false
   */
  @Input() isDynamic = false;

  /**
   * If `autoFit` set to true, then the particular column content width will be
   * adjusted based on its content in the initial rendering itself.
   * Setting this property as true is equivalent to calling `autoFitColumns` method in the `dataBound` event.
   *
   * @default false
   */
  @Input() autoFit = false;
  /**
   * Defines the cell content's overflow mode. The available modes are
   * * `Clip` -  Truncates the cell content when it overflows its area.
   * * `Ellipsis` -  Displays ellipsis when the cell content overflows its area.
   * * `EllipsisWithTooltip` - Displays ellipsis when the cell content overflows its area
   * also it will display tooltip while hover on ellipsis applied cell.
   *
   * @default Ellipsis
   */
  @Input() clipMode: 'Clip' | 'Ellipsis' | 'EllipsisWithTooltip' = 'Ellipsis';
  /** Defines the field name of column which is mapped with mapping name of DataSource. */
  @Input() field = '';
  /** Defines DB entity mapping */
  @Input() member = '';
  /** Defines DB entity mapping for sorting */
  @Input() memberSort = '';
  /**
   * It is used to change display value with the given format and does not affect the original data.
   */
  @Input() format: string | null = null;
  /** Defines the header text of column which is used to display in column header. */
  @Input() headerText = '';
  /** Key for header translation */
  @Input() headerKey = '';
  /** Key translation */
  @Input() transKey = '';
  /**
   * Define the alignment of column header which is used to align the text of column header.
   */
  @Input() headerTextAlign: TextAlign = 'Center';
  /** Template type */
  @Input() templateType: string | null = null;
  /** Template value field if different that field */
  @Input() templateValueField: string | null = null;
  /** Color definition for styles */
  @Input() color: string | null = null;
  /** Field that contains color definition */
  @Input() colorField: string | null = null;
  /**
   * Defines the minimum width of the column in pixels or percentage.
   *
   * @default 0
   */
  @Input() minWidth = 0;
  /**
   * Defines the maximum width of the column in pixel or percentage, which will restrict resizing beyond this pixel or percentage.
   *
   * @default 0
   */
  @Input() maxWidth = 0;
  /**
   * If `showInColumnChooser` set to false, then hides the particular column in column chooser.
   * By default all columns are displayed in column Chooser.
   *
   * @default true
   */
  @Input() showInColumnChooser = true;
  /**
   * Defines the alignment of the column in both header and content cells.
   *
   * @default Left
   */
  @Input() textAlign: TextAlign = 'Left';
  /**
   * If `visible` is set to false, hides the particular column. By default, all columns are displayed.
   *
   * @default true
   */
  @Input() visible = true;
  /**
   * Defines the width of the column in pixels or percentage.
   *
   * @default 0
   */
  @Input() width: string | number = 0;
  /**
   * Defines the data type of the column.
   *
   * @default null
   */
  @Input() type: string | null = null;

  /**
   * Defines the data type of the filter column.
   *
   * @default null
   */
  @Input() filterType: string | null = null;

  /**
   * Column visibility can change based on Media Queries. hideAtMedia accepts only valid Media Queries.
   *
   * @default ''
   */
  @Input() hideAtMedia = '';

  /** Defines the column template */
  @ContentChild('gridTemplate')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridTemplate: any;

  /** Template definition for filter */
  @ContentChild('filterTemplate')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterTemplate: any;

  /**
   * Defines column chooser group name
   * Will be used to group columns under header
   */
  @Input() columnChooserGroup = '';

  // Filter fields

  /**
   * Specifies the category for columns.
   * @default null
   */
  @Input() category: any;
  /**
   * Specifies the operators in columns.
   * @default null
   */
  @Input() operators: any;
  /**
   * Specifies the step value(numeric textbox) for columns.
   * @default null
   */
  @Input() step: any;
  /**
   * Specifies the validation for columns (text, number and date).
   * @default { isRequired: true , min: 0, max: Number.MAX_VALUE }
   */
  @Input() validation: any;
  /**
   * Specifies the default value for columns.
   * @default null
   */
  @Input() value: any;
  /**
   * Specifies the values in columns or bind the values from sub controls.
   * @default null
   */
  @Input() values: any;

  // Other fields

  /**
   * If `showInMassEdit` set to false, then hides the particular column in mass edit dialog.
   * By default all columns are displayed in mass edit dialog.
   *
   * @default true
   */
  @Input() showInMassEdit = true;
  /**
   * Defines the data type of the column in edit.
   *
   * @default null
   */
  @Input() editType: any | null = null;

  /**
   * Flag that defines if column definition should be used in filter
   *
   * @default false
   */
  @Input() useInFilter = false;

  /**
   * Flag if column should be used in aggregate dialog
   *
   * @default false
   */
  @Input() useInAggregate = false;

  /** Service for loading data for select field */
  @Input() dropDownService?: Observable<SelectedItem[]>;

  /** Service type that can be used to find service dynamically */
  @Input() dropDownServiceName?: string;

  /** Filter param for drop down service */
  @Input() dropDownServiceFilter?: unknown;

  /** Unique identifier */
  @Input() id?: string;

  /**
   * Defines type of displaying column group in column chooser
   * Multiselect: 'multiselect'
   *
   *
   * @type {?string}
   */
  @Input() columnChooserType?: string;

  /**
   * Defines multiselect service for multiselect in column chooser
   *
   * @type {?Observable<SelectedItem[]>}
   */
  @Input() columnChooserMultiselectService?: Observable<SelectedItem[]>;

  /**
   * Defines multiselect values in column chooser. If column is visible, then value is selected in multiselect
   *
   * @type {?(number|string)}
   */
  @Input() columnChooserMultiselectValue?: string | number;

  /**
   * specifies the type of column.
   *
   * @default null
   */
  @Input() exportType: string | null = null;

  /**
   * Used to define custom edit template for a particular column.
   * Excel export will format the cell value based on this function.
   *
   * @type {(
      field: string,
      data: unknown,
      column: Column
    ) => unknown}
   */
  @Input() valueAccessor: (
    field: string,
    data: unknown,
    column: Column
  ) => unknown;
}

<div [ngClass]="{ 'mb-2': showError }" [class]="cssClass">
  <asol-label-field
    *ngIf="labelText"
    [labelTextStyle]="labelTextStyle"
    labelTextClass="mb-0"
    [labelText]="labelText"
    [control]="ngControl.control"
    [isDisabled]="ngControl.control?.disabled"
    [isRequired]="isRequired"></asol-label-field>

  <div class="asol-hint mb-0" *ngIf="hintText">
    {{ hintText }}
  </div>

  <div
    class="e-input-group asol-input"
    [ngClass]="{
      'e-error': ngControl.control.errors && ngControl.control.touched,
      'e-disabled': ngControl.control?.disabled
    }">
    <textarea
      #inputField
      [id]="id ?? 'textareaelement'"
      class="e-input"
      [rows]="rows"
      [placeholder]="placeholderText"
      [disabled]="ngControl.control?.disabled"
      [readonly]="isReadonly"
      (change)="callOnChange()"
      (focus)="focusIn($event.target)"
      (blur)="focusOut($event.target)">
    </textarea>
  </div>

  <ng-container *ngIf="showError">
    <div
      class="asol-error d-inline-flex align-items-center"
      *ngFor="let msg of getErrorMessages()">
      <svg-icon [src]="ICONS.ERROR"></svg-icon>
      {{ msg }}
    </div>
  </ng-container>
</div>

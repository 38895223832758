import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  AsolMediaQueryService,
  AuthenticationServiceBase,
  MAIN_APP,
  MEDIA_QUERY,
  TranslationService,
} from '@asol/core';
import { Observable, Subscription, of, tap } from 'rxjs';
import { LANDING_PAGE_CONST } from '../constants/landing-page.constant';
import translation from '../landing-page.translation.json';
import { AppData } from '../models/app-data.interface';
import { LandingApp } from '../models/landing-app.interface';
import { AppService } from '../services/apps.service';
import { USER_REGISTRATION_LINK } from '../tokens/user-registration-link.token';

@Component({
  selector: 'asol-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers: [AppService],
})
export class AsolLandingPageComponent implements OnInit, OnDestroy {
  /** Observable containing all apps (max. 4) */
  apps$: Observable<LandingApp[]> = of([]);
  /** Observable containing main app */
  mainApp$: Observable<LandingApp> = of();
  /** Main app, used when switching locale */
  private mainApp: LandingApp | null = null;
  /** Main app data based on current locale */
  mainAppData: AppData | null = null;

  /**
   * Emits when user clicks on login button
   */
  @Output() loginTriggered = new EventEmitter<void>();

  /**
   * Flag to show/hide app selector.
   * @type {boolean}
   * @default true
   */
  @Input() displayApps = true;

  /** Current year shown in footer */
  year = new Date().getFullYear().toString();
  /** Flag if screen is mobile or not */
  showMobile = false;

  LANDING_PAGE_CONST = LANDING_PAGE_CONST;

  private sub = new Subscription();

  constructor(
    public trans: TranslationService,
    private service: AppService,
    private authService: AuthenticationServiceBase,
    @Optional() @Inject(USER_REGISTRATION_LINK) public registerLink: string,
    @Inject(MAIN_APP) private appCode: string,
    public mediaService: AsolMediaQueryService
  ) {
    this.trans.initialize(LANDING_PAGE_CONST.TRANS, translation);

    this.apps$ = this.service.getApps();
    this.mainApp$ = this.service.getMainApp(this.appCode).pipe(
      tap((app) => {
        this.mainApp = app;
        this.loadData();
      })
    );

    this.sub.add(
      this.mediaService.viewportChanged$.subscribe((state) => {
        if (
          state.breakpoints[MEDIA_QUERY.XL] === false &&
          state.breakpoints[MEDIA_QUERY.XXL] === false
        ) {
          this.showMobile = true;
        } else {
          this.showMobile = false;
        }
      })
    );
  }

  ngOnInit(): void {
    this.sub.add(
      this.trans.languageChanged$.subscribe(() => {
        this.loadData();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  /**
   * Loads data for the main app based on locale
   */
  private loadData(): void {
    if (!this.mainApp) {
      return;
    }

    const data = this.mainApp.data.find(
      (p) => p.locale === this.trans.getFullLocale
    );
    if (!data) {
      return;
    }

    this.mainAppData = data;
  }

  /**
   * Login user
   */
  onLogin(): void {
    this.authService.login();
    this.loginTriggered.emit();
  }
}

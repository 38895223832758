import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { AsolTenantSelectComponent } from './components/asol-tenant-select.component';

@NgModule({
  declarations: [AsolTenantSelectComponent],
  exports: [AsolTenantSelectComponent],
  imports: [DropDownListModule, CommonModule],
})
export class AsolTenantSelectModule {}

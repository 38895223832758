export const TRANS = {
  MAIN_MENU: 'app-main-menu',
  INPUT: 'app-form-controls-inputs',
  SELECT: 'app-form-controls-selects',
  CHECKBOX: 'app-form-controls-checkbox',
  SWITCH: 'app-form-controls-switch',
  RADIO: 'app-form-controls-radio',
  DATEPICKER: 'app-form-controls-date-picker',
  EDITOR: 'app-form-controls-editor',
  TEXT_AREA: 'app-form-controls-text-area',
};

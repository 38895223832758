import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AsolSpinnerComponent } from './components/asol-spinner.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AsolSpinnerComponent],
  exports: [AsolSpinnerComponent],
})
export class AsolSpinnerModule {}

<div
  class="asol-label-field"
  [ngClass]="{ 'mb-3': text || useTemplate || useDefaultText }"
  [ngStyle]="{ 'width.px': width }">
  <label [ngStyle]="labelTextStyle | unknownToObject" [ngClass]="labelTextClass"
    >{{ labelText }}
    <span
      *ngIf="!isDisabled"
      requiredAsterisk
      [isRequired]="isRequired"
      [control]="control"></span>
  </label>

  <label
    *ngIf="text || useTemplate || useDefaultText"
    [ngStyle]="textStyle | unknownToObject"
    [ngClass]="textClass"
    class="d-block label-text">
    {{
      !text
        ? useDefaultText
          ? CORE_CONSTANTS.DEFAULT_RETURN_VALUE
          : null
        : text
    }}
    <ng-content> </ng-content>
    <svg-icon
      *ngIf="editable"
      class="edit-icon ms-0"
      [src]="icon"
      (click)="edit.emit()"></svg-icon>
  </label>
</div>

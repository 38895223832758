import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  GENERAL_TRANSLATION,
  TranslationService,
  markForValidation,
} from '@asol/core';
import { Dialog, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Subscription, debounceTime } from 'rxjs';
import { AsolContentFormDirective } from '../../../../shared/directives/asol-content-form/asol-content-form.directive';
import { HasComponentId } from '../../../../shared/models/has-component-id.interface';
import { RequiredCheckService } from '../../../../shared/services/required-check.service';
import translation from '../asol-content-wrapper.translation.json';
import { CONTENT_WRAPPER_CONSTANT } from '../constants/content-wrapper.constant';

@Component({
  selector: 'asol-content-wrapper',
  templateUrl: './asol-content-wrapper.component.html',
})
export class AsolContentWrapperComponent
  implements OnChanges, OnDestroy, AfterContentInit, OnInit, HasComponentId
{
  /** Content title */
  @Input() title: string | null = null;

  /** Data that will be used as default after discard changes */
  @Input() data: unknown | null = null;

  /** Formgroup where changes will be checked */
  @Input() formGroup: FormGroup | null = null;

  /** Unique identifier of component */
  @Input() componentId: string;

  /**
   * Debounce time used in formgroup changes delay in ms
   * @default 200
   */
  @Input() debounceTime = 200;

  /** Emits when user clicks on Save button */
  @Output() saveChanges = new EventEmitter<void>();

  /** Emits when form was reseted by user */
  @Output() formReset = new EventEmitter<void>();

  /**
   * Emits when confirmation dialog is requested
   */
  @Output() showConfirmation = new EventEmitter<void>();

  /** Flag if form group contains changes */
  isDirty = false;

  /** Gets all data components where columns will be injected */
  @ContentChildren('contentForm')
  contentForms: QueryList<AsolContentFormDirective<unknown>> | null = null;

  private confirmDialog: Dialog | null = null;
  private sub = new Subscription();
  private requiredSub = new Subscription();
  private contentSub = new Subscription();

  constructor(
    private trans: TranslationService,
    private requiredService: RequiredCheckService
  ) {
    this.trans.initialize(CONTENT_WRAPPER_CONSTANT.TRANS, translation);
  }

  ngOnInit(): void {
    if (this.componentId) {
      this.requiredService.register(this.componentId);

      this.requiredSub = this.requiredService.requireCheck$.subscribe(
        (sessionId) => {
          const formValid = this.checkValidity() && !this.isDirty;
          this.requiredService.response(sessionId, this.componentId, formValid);
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.firstChange) {
      this.resetForm();
    }
  }

  ngAfterContentInit(): void {
    this.contentForms.changes.subscribe(() => {
      this.prepareContentForms();
    });
    this.prepareContentForms();
    this.listenToFormChanges();
  }

  ngOnDestroy(): void {
    if (this.componentId) {
      this.requiredService.unregister(this.componentId);
    }

    this.requiredSub?.unsubscribe();
    this.sub?.unsubscribe();
    this.contentSub?.unsubscribe();
  }

  /**
   * Starts listen to form value changes and emits state of form
   */
  protected listenToFormChanges(): void {
    // listens for value changes and marks form group as changed
    this.sub = this.formGroup?.valueChanges
      .pipe(debounceTime(this.debounceTime))
      .subscribe(() => {
        // check if any inner form is dirty
        const formDirty = this.contentForms.some((p) => p.isDirty);
        if (formDirty) {
          return;
        }
        // no inner form is dirty, set the flag
        this.isDirty = this.formGroup.dirty;

        if (!this.isDirty) {
          this.recheckValidity();
        }
      });
  }

  /**
   * Event handler to emit storing
   */
  public onSave(): void {
    const isValid = this.checkValidity();
    if (!isValid) {
      return;
    }

    // Check if confirmation dialog should be displayed
    // It cancels the save and shows confirmation dialog
    if (this.checkForDialogConfirmation()) {
      // confirmation dialog in content form has priority
      if (this.showConfirmation.observed) {
        this.showConfirmation.emit();
      } else {
        // Only one form should contain confirmation dialog
        this.contentForms?.forEach((form) => {
          form.showConfirmationDialog();
        });
      }
      return;
    }

    this.saveData();
  }

  /**
   * Calls save method on all children and main form
   */
  private saveData(): void {
    const isValid = this.checkValidity();
    if (!isValid) {
      return;
    }

    this.contentForms?.forEach((form) => {
      if (form.isDirty) {
        form.saveData();
      }
    });

    this.saveMainForm();
  }

  /**
   * Overridable method to check if confirmation dialog should be displayed
   * @returns True if confirmation dialog should be displayed
   */
  public shouldDisplayConfirmation(): boolean {
    return false;
  }

  /**
   * Checks all forms if they should display confirmation dialog
   * @returns True if any form should display confirmation dialog
   */
  private checkForDialogConfirmation(): boolean {
    let shouldDisplay = false;
    if (this.formGroup) {
      shouldDisplay = this.shouldDisplayConfirmation();
    }
    if (shouldDisplay) {
      return shouldDisplay;
    }

    for (const form of this.contentForms) {
      shouldDisplay = form.shouldDisplayConfirmation();
      if (shouldDisplay) {
        return shouldDisplay;
      }
    }

    return shouldDisplay;
  }

  /**
   * Save main form only when is dirty
   * or any child form is dirty and marked that it should be saved via parent
   */
  private saveMainForm(): void {
    const childDirty = this.contentForms.some(
      (p) => p.isDirty && p.includeInParentSave
    );
    if ((this.formGroup && this.formGroup.dirty) || childDirty) {
      this.saveChanges.emit();
    }
  }

  /**
   * Sends valid message to everyone who is listening to this component
   */
  private recheckValidity(): void {
    if (!this.componentId) {
      return;
    }

    this.requiredService.check(false);
  }

  /**
   * Checks validity of main form and content forms
   * @returns True if all valid
   */
  private checkValidity(): boolean {
    if (this.formGroup) {
      markForValidation(this.formGroup);
      if (!this.formGroup.valid && !this.formGroup.disabled) {
        return false;
      }
    }

    let valid = true;
    for (const form of this.contentForms) {
      valid = form.validate();
      if (!valid) {
        return false;
      }
    }

    return valid;
  }

  /**
   * Event handler for discarding changes of form group via confirm dialog
   */
  public onDiscard(): void {
    this.confirmDialog = DialogUtility.confirm({
      title: this.trans.get(
        CONTENT_WRAPPER_CONSTANT.TRANS,
        'confirmDiscardTitle'
      ),
      content: this.trans.get(
        CONTENT_WRAPPER_CONSTANT.TRANS,
        'confirmDiscardContent'
      ),
      okButton: {
        text: this.trans.get(GENERAL_TRANSLATION, 'Yes'),
        click: this.resetForm.bind(this),
      },
      cancelButton: {
        text: this.trans.get(GENERAL_TRANSLATION, 'No'),
      },
    });
  }

  /**
   * Prepares forms
   */
  private prepareContentForms(): void {
    this.contentSub?.unsubscribe();
    if (!this.contentForms || !this.contentForms.length) {
      return;
    }

    this.contentSub = new Subscription();
    this.contentForms.forEach((form) => {
      this.contentSub?.add(
        form.isDirty$.subscribe((isDirty) => {
          this.calculateDirty(isDirty, form.formId);

          if (!this.isDirty) {
            this.recheckValidity();
          }
        })
      );
      this.contentSub?.add(
        form.storeData$.subscribe((store) => {
          if (!store) {
            return;
          }

          this.saveData();
        })
      );
    });
  }

  /**
   * Reset form
   */
  private resetForm(): void {
    this.resetAllForms();
    this.resetMainForm();

    this.formReset.emit();

    this.isDirty = false;
    this.confirmDialog?.hide();
  }

  /**
   * Recalculates the dirty flag
   * @param isDirty dirty value
   */
  private calculateDirty(isDirty: boolean, id: string): void {
    // if inner form is dirty mark as dirty
    if (isDirty) {
      this.isDirty = true;
      return;
    }

    const formDirty = this.contentForms
      .filter((p) => p.formId !== id)
      .some((p) => p.isDirty);

    // if any other inner form is dirty return
    if (formDirty) {
      return;
    }

    // none of inner form is dirty, so check this one
    if (this.formGroup?.dirty) {
      return;
    }

    // if no form is dirty
    this.isDirty = false;
  }

  /**
   * Resets main form
   */
  private resetMainForm(): void {
    if (!this.formGroup?.dirty) {
      return;
    }

    const defaultData = this.data as Record<string, unknown> | null;

    this.formGroup?.reset(
      {
        ...defaultData,
      },
      {
        emitEvent: false,
        onlySelf: true,
      }
    );
    this.formGroup?.updateValueAndValidity();
  }

  /**
   * Resets all forms in wrapper
   */
  private resetAllForms(): void {
    this.contentForms?.forEach((form) => {
      form.resetForm();
    });
  }
}

import { Injectable } from '@angular/core';
import { Toast, ToastUtility } from '@syncfusion/ej2-angular-notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private timeout = 7000;

  private openToast(
    message: string,
    className: string,
    showCloseButton = true,
    timeout?: number
  ): Toast {
    return ToastUtility.show({
      content: message,
      cssClass: className,
      icon: `${className}-icon`,
      showCloseButton: showCloseButton,
      position: { X: 'Right', Y: 'Top' },
      timeOut: timeout ?? this.timeout,
    });
  }

  /**
   * Shows success message
   * @param message Message to show
   * @param showCloseButton if close button should be shown
   * @param timeout Optional timeout, if undefined default would be used, it message should be always visible insert 0
   */
  public success(
    message: string,
    showCloseButton = true,
    timeout?: number
  ): Toast {
    return this.openToast(message, 'e-toast-success', showCloseButton, timeout);
  }

  /**
   * Shows info message
   * @param message Message to show
   * @param showCloseButton if close button should be shown
   * @param timeout Optional timeout, if undefined default would be used, it message should be always visible insert 0
   */
  public info(
    message: string,
    showCloseButton = true,
    timeout?: number
  ): Toast {
    return this.openToast(message, 'e-toast-info', showCloseButton, timeout);
  }

  /**
   * Shows warning message
   * @param message Message to show
   * @param showCloseButton if close button should be shown
   * @param timeout Optional timeout, if undefined default would be used, it message should be always visible insert 0
   */
  public warning(
    message: string,
    showCloseButton = true,
    timeout?: number
  ): Toast {
    return this.openToast(message, 'e-toast-warning', showCloseButton, timeout);
  }

  /**
   * Shows danger message
   * @param message Message to show
   * @param showCloseButton if close button should be shown
   * @param timeout Optional timeout, if undefined default would be used, it message should be always visible insert 0
   */
  public danger(
    message: string,
    showCloseButton = true,
    timeout?: number
  ): Toast {
    return this.openToast(message, 'e-toast-danger', showCloseButton, timeout);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, HomeGuard } from '@asol/core';
import { ADDRESS_NAVIGATION } from './modules/composite-controls/address/constants/dev-address-navigation.constant';
import { COMMENTS_NAVIGATION } from './modules/composite-controls/comments/constants/dev-comments-navigation.constant';
import { ITEMS_LIST_NAVIGATION } from './modules/composite-controls/items-list/constants/dev-items-list-navigation.constant';
import { BREADCRUMB_NAVIGATION } from './modules/controls/breadcrumb/constants/breadcrumb-navigation.constant';
import { BUTTONS_NAVIGATION } from './modules/controls/buttons/constants/buttons-navigation.constant';
import { DEV_DIALOG_NAVIGATION } from './modules/controls/dialog/constants/dev-dialog-navigation.constant';
import { ENTITY_LINK_NAVIGATION } from './modules/controls/entity-link/constants/entity-link-navigation.constant';
import { FILTER_NAVIGATION } from './modules/controls/filter/constants/filter-navigation.constant';
import { GRID_NAVIGATION } from './modules/controls/grid/constants/grid-navigation.constant';
import { INFO_PANEL_NAVIGATION } from './modules/controls/info-panel/constants/info-panel.navigation.constant';
import { MESSAGE_PANEL_NAVIGATION } from './modules/controls/message-panel/constants/message-panel-navigation.constant';
import { PAGER_NAVIGATION } from './modules/controls/pager/constants/dev-pager-navigation.constant';
import { PRIORITY_NAVIGATION } from './modules/controls/priority/constants/dev-priority-navigation.constant';
import { PROGRESS_BAR_NAVIGATION } from './modules/controls/progress-bar/constants/progress-bar-navigation.constant';
import { STEPPER_NAVIGATION } from './modules/controls/stepper/constants/dev-stepper-navigation.constant';
import { TABS_NAVIGATION } from './modules/controls/tabs/constants/tabs-navigation.constant';
import { TOAST_NAVIGATION } from './modules/controls/toast/constants/toast-navigation.constant';
import { TOOLBAR_NAVIGATION } from './modules/controls/toolbar/constants/toolbar-navigation.constant';
import { WORKFLOW_NAVIGATION } from './modules/controls/workflow/constants/workflow-navigation.constant';
import { DASHBOARD_NAVIGATION } from './modules/dashboard/constants/dashboard-navigation.constant';
import { UPLOAD_NAVIGATION } from './modules/form-controls/components/upload/constants/upload-navigation.constant';
import { INPUTS_NAVIGATION } from './modules/form-controls/constants/inputs-navigation.constant';
import { CONTENT_WRAPPER_NAVIGATION } from './modules/layout-controls/content-wrapper/constants/dev-content-wrapper-navigation.constant';
import { ONBOARDING_NAVIGATION } from './modules/layout-controls/onboarding/constants/onboarding-navigation.constant';
import { SPINNER_NAVIGATION } from './modules/layout-controls/spinner/constants/dev-spinner-navigation.constant';
import { PROFILE_NAVIGATION } from './modules/profile/constants/profile-navigation.constant';
import { TEST_NAVIGATION } from './modules/test-page/constants/test-page-navigation.constant';

const routes: Routes = [
  {
    path: '',
    canActivate: [HomeGuard],
    loadChildren: () =>
      import('./modules/layout-controls/landing-page/landing-page.module').then(
        (module) => module.DevLandingPageModule
      ),
  },
  {
    path: PAGER_NAVIGATION.MAIN,
    loadChildren: () =>
      import('./modules/controls/pager/pager.module').then(
        (module) => module.DevPagerModule
      ),
  },
  {
    path: TEST_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/test-page/test-page.module').then(
        (module) => module.TestPageModule
      ),
  },
  {
    path: ONBOARDING_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/layout-controls/onboarding/dev-onboarding.module').then(
        (module) => module.DevOnboardingModule
      ),
  },
  {
    path: DASHBOARD_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (module) => module.DashboardModule
      ),
  },
  {
    path: PROFILE_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/profile/profile.module').then(
        (module) => module.ProfileModule
      ),
  },
  {
    path: DEV_DIALOG_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/dialog/dev-dialog.module').then(
        (module) => module.DevDialogModule
      ),
  },
  {
    path: ADDRESS_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/composite-controls/address/address.module').then(
        (module) => module.DevAddressModule
      ),
  },
  {
    path: BUTTONS_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/buttons/buttons.module').then(
        (module) => module.DevButtonsModule
      ),
  },
  {
    path: PRIORITY_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/priority/priority.module').then(
        (module) => module.DevPriorityModule
      ),
  },
  {
    path: STEPPER_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/stepper/stepper.module').then(
        (module) => module.DevStepperModule
      ),
  },
  {
    path: INPUTS_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/form-controls/inputs.module').then(
        (module) => module.InputsModule
      ),
  },
  {
    path: WORKFLOW_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/workflow/workflow.module').then(
        (module) => module.DevWorkflowModule
      ),
  },
  {
    path: TABS_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/tabs/tabs.module').then(
        (module) => module.DevTabsModule
      ),
  },
  {
    path: GRID_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/grid/grid.module').then(
        (module) => module.DevGridModule
      ),
  },
  {
    path: FILTER_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/filter/filter.module').then(
        (module) => module.DevFilterModule
      ),
  },
  {
    path: ENTITY_LINK_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/entity-link/entity-link.module').then(
        (module) => module.DevEntityLinkModule
      ),
  },
  {
    path: TOAST_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/toast/toast.module').then(
        (module) => module.DevToastModule
      ),
  },
  {
    path: TOOLBAR_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/toolbar/toolbar.module').then(
        (module) => module.DevToolbarModule
      ),
  },
  {
    path: BREADCRUMB_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/breadcrumb/breadcrumb.module').then(
        (module) => module.DevBreadcrumbModule
      ),
  },
  {
    path: PROGRESS_BAR_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/progress-bar/progress-bar.module').then(
        (module) => module.ProgressBarModule
      ),
  },
  {
    path: INFO_PANEL_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/info-panel/info-panel.module').then(
        (module) => module.InfoPanelModule
      ),
  },
  {
    path: MESSAGE_PANEL_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/controls/message-panel/message-panel.module').then(
        (module) => module.MessagePanelModule
      ),
  },
  {
    path: SPINNER_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/layout-controls/spinner/spinner.module').then(
        (module) => module.SpinnerModule
      ),
  },
  {
    path: ITEMS_LIST_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/composite-controls/items-list/dev-items-list.module'
      ).then((module) => module.DevItemsListModule),
  },
  {
    path: COMMENTS_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/composite-controls/comments/dev-comments.module').then(
        (module) => module.DevCommentsModule
      ),
  },
  {
    path: CONTENT_WRAPPER_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/layout-controls/content-wrapper/content-wrapper.module'
      ).then((module) => module.DevContentWrapperModule),
  },
  {
    path: UPLOAD_NAVIGATION.MAIN,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/form-controls/components/upload/upload.module').then(
        (module) => module.DevUploadModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { NgModule } from '@angular/core';
import { PipesModule } from '@asol/core';
import { RequiredAsteriskModule } from '../../../shared/directives/required-asterisk/required-asterisk.module';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolLabelFieldComponent } from './components/label-field.component';

@NgModule({
  declarations: [AsolLabelFieldComponent],
  exports: [AsolLabelFieldComponent],
  imports: [SharedModule, PipesModule, RequiredAsteriskModule],
})
export class AsolLabelFieldModule {}

<div class="ms-5">
  <h1>{{ name }}</h1>
  <h2 class="mt-3 mb-5">Version: {{ version }}</h2>

  <section>
    <h2>Dependencies:</h2>
    <ul>
      <li *ngFor="let item of info">{{ item.name }}: {{ item.version }}</li>
    </ul>
  </section>
</div>

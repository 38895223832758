import { NgModule } from '@angular/core';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AsolButtonModule } from '../../controls/button/asol-button.module';
import { AsolInputFieldModule } from '../../form-controls/input-field/asol-input-field.module';
import { AsolSelectFieldModule } from '../../form-controls/select-field/select-field.module';
import { AsolTextAreaModule } from '../../form-controls/text-area/asol-text-area.module';
import { AsolContentWrapperModule } from '../content-wrapper/asol-content-wrapper.module';
import { AsolOnboardingCreateComponent } from './components/onboarding-create/onboarding-create.component';
import { AsolOnboardingComponent } from './components/onboarding/onboarding.component';

@NgModule({
  declarations: [AsolOnboardingComponent, AsolOnboardingCreateComponent],
  exports: [AsolOnboardingComponent, AsolOnboardingCreateComponent],
  imports: [
    SharedModule,
    DialogModule,
    AsolContentWrapperModule,
    AsolInputFieldModule,
    AsolButtonModule,
    AsolTextAreaModule,
    AsolSelectFieldModule,
  ],
})
export class AsolOnboardingModule {}
